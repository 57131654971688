import { useMemo } from 'react';

import { useNotificationAdminControllerGetNotificationsForCurrentAdminQuery } from 'shared/api/rtk-api';

import { mapExternalAdminNotificationToInternalAdminNotification } from '../mappers/external-admin-notification-to-internal-admin-notification-mapper';

export const useAdminNotificationsQuery = () => {
  const { data, ...others } = useNotificationAdminControllerGetNotificationsForCurrentAdminQuery(undefined, {
    pollingInterval: 10000,
  });

  const notifications = useMemo(() => {
    return data?.map(mapExternalAdminNotificationToInternalAdminNotification);
  }, [data]);

  return {
    notifications,
    ...others,
  };
};
