import moment from 'moment';

import { GetProtocolResponseDto } from 'shared/api/rtk-api';
import { formatDateDMAndCallTime, getTimeFromDate } from 'shared/lib/format';

import type { InternalDoctorOnlineAppointmentProtocol } from '../types/internal-doctor-online-appointment-protocol';

export const externalDoctorOnlineAppointmentProtocolToInternalDoctorOnlineAppointmentProtocolMapper = (
  onlineAppointmentProtocol: GetProtocolResponseDto,
): InternalDoctorOnlineAppointmentProtocol => {
  const appointmentTime = `${getTimeFromDate(onlineAppointmentProtocol.startDateTimeIso)} - ${getTimeFromDate(onlineAppointmentProtocol.startDateTimeIso)}`;

  const dateAndTime = formatDateDMAndCallTime(onlineAppointmentProtocol.startDateTimeIso, appointmentTime);
  return {
    protocolId: onlineAppointmentProtocol.internalProtocolId,
    doctorName: onlineAppointmentProtocol.doctor,
    specialistName: onlineAppointmentProtocol.specialist,
    patientName: onlineAppointmentProtocol.patientName,
    startDateTimeIso: onlineAppointmentProtocol.startDateTimeIso,
    dateAndTime,
    patientBirthDate: onlineAppointmentProtocol.patientBirthDate
      ? moment(onlineAppointmentProtocol.patientBirthDate)
      : undefined,
    complaints: onlineAppointmentProtocol.complaints,
    anamnesis: onlineAppointmentProtocol.anamnesis,
    conclusion: onlineAppointmentProtocol.conclusion,
    recommendations: onlineAppointmentProtocol.recommendations,
    referral: onlineAppointmentProtocol.referral,
    services: onlineAppointmentProtocol.services,
    onlineVisitAppointmentId: onlineAppointmentProtocol.internalOnlineVisitAppointmentId.toString(),
  };
};
