import { type AdminNotificationResponseDto, HealthAssistanceType, NotificationCase } from 'shared/api/rtk-api';
import { formatDateTime } from 'shared/lib/format';
import { routePatches } from 'shared/routes';

const getOnlineAppointmentNotificationLink = (notification: AdminNotificationResponseDto) => {
  const { eventType, internalOnlineAppointmentId } = notification;
  if (!internalOnlineAppointmentId) return null;

  switch (eventType) {
    case NotificationCase.OnlineAppointmentCreateAppointmentByPatientToAdmin:
    case NotificationCase.OnlineAppointmentPaidByPatientToAdmin:
      return routePatches.AdminOnlineAppointmentDetails.path(internalOnlineAppointmentId);
    default:
      return routePatches.AdminRejectedByPatientOnlineAppointmentDetails.path(internalOnlineAppointmentId);
  }
};

function getLink(notification: AdminNotificationResponseDto) {
  const { healthAssistanceType, entityId } = notification;

  if (healthAssistanceType === HealthAssistanceType.HomeVisit && entityId) {
    return routePatches.AdminHomeVisitDetails.path(entityId);
  }

  if (healthAssistanceType === HealthAssistanceType.HomeVisitRefuse && entityId) {
    return routePatches.AdminRejectedByPatientHomeVisitDetails.path(entityId);
  }

  if (healthAssistanceType === HealthAssistanceType.ChatAssisting && entityId) {
    return routePatches.AdminChatSupportDetails.path(entityId);
  }

  if (healthAssistanceType === HealthAssistanceType.OnlineVisit) {
    return getOnlineAppointmentNotificationLink(notification);
  }

  return null;
}

export const mapExternalAdminNotificationToInternalAdminNotification = (notification: AdminNotificationResponseDto) => {
  const link = getLink(notification);
  return {
    id: notification.id,
    isViewed: notification.isViewed,
    message: notification.message,
    date: formatDateTime(notification.createdAtDateTimeIso),
    link,
  };
};
