import { type FC } from 'react';

import { HealthAssistanceDetailsAsideContainer, ShadowBox, StyledTitle } from 'entity/health-assistance/styles';
import { DoctorHealthAssistanceDetailsContainer } from 'entity/health-assistance/ui/health-assistance-details-container/doctor-health-assistance-details-container';
import type { InternalDoctorOnlineAppointmentDetails } from 'entity/online-appointment/types/internal-doctor-online-appointment-details';
import { ScheduleConnectToOnlineAppointmentByDoctorButton } from 'feature/schedule-connect-to-online-appointment-by-doctor/ui';
import { CustomButtonFooter } from 'shared/ui/custom-button-footer';
import { TextRow } from 'shared/ui/row/text-row';
import { StyledScrollableContainer } from 'shared/ui/scrollable/styles';

type Props = {
  onlineAppointment: InternalDoctorOnlineAppointmentDetails;
};

export const OnlineAppointmentDetailsForm: FC<Props> = ({ onlineAppointment }) => {
  const { patientFullName, doctorFullName, specialistFullName, canConnect, date, time } = onlineAppointment;

  return (
    <HealthAssistanceDetailsAsideContainer>
      <StyledScrollableContainer>
        <DoctorHealthAssistanceDetailsContainer>
          <StyledTitle level={5}>Онлайн-визит</StyledTitle>
          <TextRow title="ФИО пациента" text={patientFullName} />
          {doctorFullName && <TextRow title="ФИО доктора" text={doctorFullName} />}
          {specialistFullName && <TextRow title="ФИО специалиста" text={specialistFullName} />}
          <TextRow title="Дата" text={date} />
          <TextRow title="Время приема" text={time} />
        </DoctorHealthAssistanceDetailsContainer>
      </StyledScrollableContainer>
      {canConnect && (
        <CustomButtonFooter>
          {/* <ScheduleUseOnlineAppointmentChatByDoctorButton isDetailsPage /> */}
          <ScheduleConnectToOnlineAppointmentByDoctorButton isDetailsPage event={onlineAppointment} />
        </CustomButtonFooter>
      )}
      <ShadowBox />
    </HealthAssistanceDetailsAsideContainer>
  );
};
