import type { ProtocolResponseDto } from 'shared/api/rtk-api';

import type { InternalDoctorUserProtocol } from '../types/internal-doctor-user-protocol';

export const externalDoctorUserProtocolToInternalDoctorUserProtocolMapper = (
  protocol: ProtocolResponseDto,
): InternalDoctorUserProtocol => {
  return {
    id: protocol.aggregatedDocumentId,
    protocolId: protocol.internalProtocolId,
    fileName: protocol.fileName,
    createdAt: protocol.createdAt,
    extension: protocol.fileExtension,
    preview: protocol.preview,
  };
};
