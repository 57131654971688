import { api } from "shared/api";
export const addTagTypes = [
  "Notification(Doctor)",
  "Notification(Admin)",
  "Payment",
  "Doctors",
  "Schedules",
  "Users",
  "Online Visit Slot",
  "Online Visit Doctor",
  "Online Visit Appointment",
  "Online Visit Feedback",
  "Online Visit Payment",
  "Documents",
  "Protocols",
  "Conversation",
  "Chat",
  "Auth",
  "Auth (Internal)",
  "Calendar",
  "Home-Visit",
  "doctors(admin)",
  "Appointment (Admin)",
  "Doctor-Panel",
  "Options",
  "Marketing Mailing",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      doctorNotificationControllerGetNotification: build.query<
        DoctorNotificationControllerGetNotificationApiResponse,
        DoctorNotificationControllerGetNotificationApiArg
      >({
        query: () => ({ url: `/notification-doctor` }),
        providesTags: ["Notification(Doctor)"],
      }),
      doctorNotificationControllerGetUnreadNotificationCount: build.query<
        DoctorNotificationControllerGetUnreadNotificationCountApiResponse,
        DoctorNotificationControllerGetUnreadNotificationCountApiArg
      >({
        query: () => ({ url: `/notification-doctor/unread-count` }),
        providesTags: ["Notification(Doctor)"],
      }),
      doctorNotificationControllerViewNotifications: build.mutation<
        DoctorNotificationControllerViewNotificationsApiResponse,
        DoctorNotificationControllerViewNotificationsApiArg
      >({
        query: (queryArg) => ({
          url: `/notification-doctor/view`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Notification(Doctor)"],
      }),
      doctorNotificationControllerDeclineHomeVisit: build.mutation<
        DoctorNotificationControllerDeclineHomeVisitApiResponse,
        DoctorNotificationControllerDeclineHomeVisitApiArg
      >({
        query: (queryArg) => ({
          url: `/notification-doctor/reject-home-visit`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Notification(Doctor)"],
      }),
      doctorNotificationControllerDeclineHomeVisitByFormId: build.mutation<
        DoctorNotificationControllerDeclineHomeVisitByFormIdApiResponse,
        DoctorNotificationControllerDeclineHomeVisitByFormIdApiArg
      >({
        query: (queryArg) => ({
          url: `/notification-doctor/reject-home-visit-by-form-id`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Notification(Doctor)"],
      }),
      doctorNotificationControllerAcceptRequest: build.mutation<
        DoctorNotificationControllerAcceptRequestApiResponse,
        DoctorNotificationControllerAcceptRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/notification-doctor/accept-request`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Notification(Doctor)"],
      }),
      doctorNotificationControllerAcceptRequestByFormId: build.mutation<
        DoctorNotificationControllerAcceptRequestByFormIdApiResponse,
        DoctorNotificationControllerAcceptRequestByFormIdApiArg
      >({
        query: (queryArg) => ({
          url: `/notification-doctor/accept-request-by-form-id`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Notification(Doctor)"],
      }),
      notificationAdminControllerGetNotificationsForCurrentAdmin: build.query<
        NotificationAdminControllerGetNotificationsForCurrentAdminApiResponse,
        NotificationAdminControllerGetNotificationsForCurrentAdminApiArg
      >({
        query: () => ({ url: `/notification-admin` }),
        providesTags: ["Notification(Admin)"],
      }),
      notificationAdminControllerReadNotification: build.mutation<
        NotificationAdminControllerReadNotificationApiResponse,
        NotificationAdminControllerReadNotificationApiArg
      >({
        query: (queryArg) => ({
          url: `/notification-admin/view`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Notification(Admin)"],
      }),
      notificationAdminControllerHasUnreadMessages: build.query<
        NotificationAdminControllerHasUnreadMessagesApiResponse,
        NotificationAdminControllerHasUnreadMessagesApiArg
      >({
        query: () => ({ url: `/notification-admin/has-unread-messages` }),
        providesTags: ["Notification(Admin)"],
      }),
      paymentControllerCreateHomeVisitPayment: build.mutation<
        PaymentControllerCreateHomeVisitPaymentApiResponse,
        PaymentControllerCreateHomeVisitPaymentApiArg
      >({
        query: (queryArg) => ({
          url: `/payment/home-visit`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Payment"],
      }),
      paymentControllerCreateChatAssistingPayment: build.mutation<
        PaymentControllerCreateChatAssistingPaymentApiResponse,
        PaymentControllerCreateChatAssistingPaymentApiArg
      >({
        query: (queryArg) => ({
          url: `/payment/chat-assisting`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Payment"],
      }),
      doctorControllerDoctorsList: build.query<
        DoctorControllerDoctorsListApiResponse,
        DoctorControllerDoctorsListApiArg
      >({
        query: (queryArg) => ({
          url: `/doctors`,
          params: {
            limit: queryArg.limit,
            offset: queryArg.offset,
            cityId: queryArg.cityId,
            clinicId: queryArg.clinicId,
            specialty: queryArg.specialty,
            search: queryArg.search,
          },
        }),
        providesTags: ["Doctors"],
      }),
      doctorControllerDoctorsListByPatientId: build.query<
        DoctorControllerDoctorsListByPatientIdApiResponse,
        DoctorControllerDoctorsListByPatientIdApiArg
      >({
        query: (queryArg) => ({
          url: `/doctors/my`,
          params: { limit: queryArg.limit, offset: queryArg.offset },
        }),
        providesTags: ["Doctors"],
      }),
      doctorControllerSpecialtyList: build.query<
        DoctorControllerSpecialtyListApiResponse,
        DoctorControllerSpecialtyListApiArg
      >({
        query: (queryArg) => ({
          url: `/doctors/specializations`,
          params: { cityId: queryArg.cityId, clinicId: queryArg.clinicId },
        }),
        providesTags: ["Doctors"],
      }),
      doctorControllerDoctor: build.query<
        DoctorControllerDoctorApiResponse,
        DoctorControllerDoctorApiArg
      >({
        query: (queryArg) => ({ url: `/doctors/${queryArg}` }),
        providesTags: ["Doctors"],
      }),
      scheduleControllerHomeVisit: build.mutation<
        ScheduleControllerHomeVisitApiResponse,
        ScheduleControllerHomeVisitApiArg
      >({
        query: (queryArg) => ({
          url: `/schedule/home-visit-response`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Schedules"],
      }),
      scheduleControllerHomeVisitPaymentList: build.query<
        ScheduleControllerHomeVisitPaymentListApiResponse,
        ScheduleControllerHomeVisitPaymentListApiArg
      >({
        query: (queryArg) => ({
          url: `/schedule/home-visit/payment-list`,
          params: { limit: queryArg.limit, offset: queryArg.offset },
        }),
        providesTags: ["Schedules"],
      }),
      scheduleControllerUserUpcomingHomeVisit: build.query<
        ScheduleControllerUserUpcomingHomeVisitApiResponse,
        ScheduleControllerUserUpcomingHomeVisitApiArg
      >({
        query: (queryArg) => ({ url: `/schedule/home-visit/${queryArg}` }),
        providesTags: ["Schedules"],
      }),
      userControllerClearTaxRefundDate: build.mutation<
        UserControllerClearTaxRefundDateApiResponse,
        UserControllerClearTaxRefundDateApiArg
      >({
        query: () => ({ url: `/users/tax-refund`, method: "DELETE" }),
        invalidatesTags: ["Users"],
      }),
      userControllerTaxRefund: build.mutation<
        UserControllerTaxRefundApiResponse,
        UserControllerTaxRefundApiArg
      >({
        query: (queryArg) => ({
          url: `/users/tax-refund`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Users"],
      }),
      userControllerCheckTaxRefund: build.query<
        UserControllerCheckTaxRefundApiResponse,
        UserControllerCheckTaxRefundApiArg
      >({
        query: () => ({ url: `/users/check-tax-refund` }),
        providesTags: ["Users"],
      }),
      userControllerFindUserData: build.query<
        UserControllerFindUserDataApiResponse,
        UserControllerFindUserDataApiArg
      >({
        query: () => ({ url: `/users/me` }),
        providesTags: ["Users"],
      }),
      userControllerDeleteUser: build.mutation<
        UserControllerDeleteUserApiResponse,
        UserControllerDeleteUserApiArg
      >({
        query: () => ({ url: `/users/me`, method: "DELETE" }),
        invalidatesTags: ["Users"],
      }),
      userControllerFindAccounts: build.query<
        UserControllerFindAccountsApiResponse,
        UserControllerFindAccountsApiArg
      >({
        query: () => ({ url: `/users/accounts` }),
        providesTags: ["Users"],
      }),
      userControllerSetPushToken: build.mutation<
        UserControllerSetPushTokenApiResponse,
        UserControllerSetPushTokenApiArg
      >({
        query: (queryArg) => ({
          url: `/users/push-token`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Users"],
      }),
      userControllerCreateUserFeedback: build.mutation<
        UserControllerCreateUserFeedbackApiResponse,
        UserControllerCreateUserFeedbackApiArg
      >({
        query: (queryArg) => ({
          url: `/users/user-feedback`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Users"],
      }),
      onlineVisitSlotControllerAddSlot: build.mutation<
        OnlineVisitSlotControllerAddSlotApiResponse,
        OnlineVisitSlotControllerAddSlotApiArg
      >({
        query: (queryArg) => ({
          url: `/online-visit-slot/add-slot`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Online Visit Slot"],
      }),
      onlineVisitSlotControllerDeleteSlot: build.mutation<
        OnlineVisitSlotControllerDeleteSlotApiResponse,
        OnlineVisitSlotControllerDeleteSlotApiArg
      >({
        query: (queryArg) => ({
          url: `/online-visit-slot/delete-slot`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Online Visit Slot"],
      }),
      onlineVisitDoctorControllerGetOnlineVisitDoctorSlotList: build.query<
        OnlineVisitDoctorControllerGetOnlineVisitDoctorSlotListApiResponse,
        OnlineVisitDoctorControllerGetOnlineVisitDoctorSlotListApiArg
      >({
        query: (queryArg) => ({
          url: `/online-visit-doctor/online-visit-doctor-slot-list`,
          params: {
            limit: queryArg.limit,
            offset: queryArg.offset,
            specialization: queryArg.specialization,
            fromDateTimeIso: queryArg.fromDateTimeIso,
            toDateTimeIso: queryArg.toDateTimeIso,
          },
        }),
        providesTags: ["Online Visit Doctor"],
      }),
      onlineVisitDoctorControllerGetOnlineVisitDoctorsSpecialityList:
        build.query<
          OnlineVisitDoctorControllerGetOnlineVisitDoctorsSpecialityListApiResponse,
          OnlineVisitDoctorControllerGetOnlineVisitDoctorsSpecialityListApiArg
        >({
          query: (queryArg) => ({
            url: `/online-visit-doctor/online-visit-doctors-speciality-list`,
            params: { searchLine: queryArg },
          }),
          providesTags: ["Online Visit Doctor"],
        }),
      onlineVisitAppointmentControllerPatientCreateAppointment: build.mutation<
        OnlineVisitAppointmentControllerPatientCreateAppointmentApiResponse,
        OnlineVisitAppointmentControllerPatientCreateAppointmentApiArg
      >({
        query: (queryArg) => ({
          url: `/online-visit-appointment/patient-create-appointment`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Online Visit Appointment"],
      }),
      onlineVisitAppointmentControllerAdminRejectRequestToAppointment:
        build.mutation<
          OnlineVisitAppointmentControllerAdminRejectRequestToAppointmentApiResponse,
          OnlineVisitAppointmentControllerAdminRejectRequestToAppointmentApiArg
        >({
          query: (queryArg) => ({
            url: `/online-visit-appointment/admin-reject-appointment`,
            method: "POST",
            body: queryArg,
          }),
          invalidatesTags: ["Online Visit Appointment"],
        }),
      onlineVisitAppointmentControllerAdminAssignAppointment: build.mutation<
        OnlineVisitAppointmentControllerAdminAssignAppointmentApiResponse,
        OnlineVisitAppointmentControllerAdminAssignAppointmentApiArg
      >({
        query: (queryArg) => ({
          url: `/online-visit-appointment/admin-assign-appointment`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Online Visit Appointment"],
      }),
      onlineVisitAppointmentControllerAdminAcceptAppointment: build.mutation<
        OnlineVisitAppointmentControllerAdminAcceptAppointmentApiResponse,
        OnlineVisitAppointmentControllerAdminAcceptAppointmentApiArg
      >({
        query: (queryArg) => ({
          url: `/online-visit-appointment/admin-accept-appointment`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Online Visit Appointment"],
      }),
      onlineVisitAppointmentControllerAdminEditAppointment: build.mutation<
        OnlineVisitAppointmentControllerAdminEditAppointmentApiResponse,
        OnlineVisitAppointmentControllerAdminEditAppointmentApiArg
      >({
        query: (queryArg) => ({
          url: `/online-visit-appointment/admin-edit-appointment`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Online Visit Appointment"],
      }),
      onlineVisitAppointmentControllerPatientCancelAppointment: build.mutation<
        OnlineVisitAppointmentControllerPatientCancelAppointmentApiResponse,
        OnlineVisitAppointmentControllerPatientCancelAppointmentApiArg
      >({
        query: (queryArg) => ({
          url: `/online-visit-appointment/patient-create-cancellation-request`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Online Visit Appointment"],
      }),
      onlineVisitAppointmentControllerAdminCancelAppointment: build.mutation<
        OnlineVisitAppointmentControllerAdminCancelAppointmentApiResponse,
        OnlineVisitAppointmentControllerAdminCancelAppointmentApiArg
      >({
        query: (queryArg) => ({
          url: `/online-visit-appointment/admin-cancel-appointment`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Online Visit Appointment"],
      }),
      onlineVisitAppointmentControllerAdminAssignCancellation: build.mutation<
        OnlineVisitAppointmentControllerAdminAssignCancellationApiResponse,
        OnlineVisitAppointmentControllerAdminAssignCancellationApiArg
      >({
        query: (queryArg) => ({
          url: `/online-visit-appointment/admin-assign-cancellation`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Online Visit Appointment"],
      }),
      onlineVisitAppointmentControllerGetPatientEventList: build.query<
        OnlineVisitAppointmentControllerGetPatientEventListApiResponse,
        OnlineVisitAppointmentControllerGetPatientEventListApiArg
      >({
        query: (queryArg) => ({
          url: `/online-visit-appointment/patient-online-visit-appointment-list`,
          params: {
            limit: queryArg.limit,
            offset: queryArg.offset,
            fromStartDateTimeIso: queryArg.fromStartDateTimeIso,
            toStartDateTimeIso: queryArg.toStartDateTimeIso,
            isPaid: queryArg.isPaid,
            statuses: queryArg.statuses,
          },
        }),
        providesTags: ["Online Visit Appointment"],
      }),
      onlineVisitAppointmentControllerGetAdminOnlineVisitAppointmentList:
        build.query<
          OnlineVisitAppointmentControllerGetAdminOnlineVisitAppointmentListApiResponse,
          OnlineVisitAppointmentControllerGetAdminOnlineVisitAppointmentListApiArg
        >({
          query: (queryArg) => ({
            url: `/online-visit-appointment/admin-online-visit-appointment-list`,
            params: {
              limit: queryArg.limit,
              offset: queryArg.offset,
              fromStartDateTimeIso: queryArg.fromStartDateTimeIso,
              toStartDateTimeIso: queryArg.toStartDateTimeIso,
              statuses: queryArg.statuses,
              search: queryArg.search,
            },
          }),
          providesTags: ["Online Visit Appointment"],
        }),
      onlineVisitAppointmentControllerGetAdminOnlineVisitAppointment:
        build.query<
          OnlineVisitAppointmentControllerGetAdminOnlineVisitAppointmentApiResponse,
          OnlineVisitAppointmentControllerGetAdminOnlineVisitAppointmentApiArg
        >({
          query: (queryArg) => ({
            url: `/online-visit-appointment/admin-online-visit-new-appointment/${queryArg}`,
          }),
          providesTags: ["Online Visit Appointment"],
        }),
      onlineVisitAppointmentControllerDoctorAddSpecialistForAppointment:
        build.mutation<
          OnlineVisitAppointmentControllerDoctorAddSpecialistForAppointmentApiResponse,
          OnlineVisitAppointmentControllerDoctorAddSpecialistForAppointmentApiArg
        >({
          query: (queryArg) => ({
            url: `/online-visit-appointment/doctor-add-specialist`,
            method: "POST",
            body: queryArg,
          }),
          invalidatesTags: ["Online Visit Appointment"],
        }),
      onlineVisitAppointmentControllerDoctorRemoveSpecialistForAppointment:
        build.mutation<
          OnlineVisitAppointmentControllerDoctorRemoveSpecialistForAppointmentApiResponse,
          OnlineVisitAppointmentControllerDoctorRemoveSpecialistForAppointmentApiArg
        >({
          query: (queryArg) => ({
            url: `/online-visit-appointment/doctor-remove-specialist`,
            method: "POST",
            body: queryArg,
          }),
          invalidatesTags: ["Online Visit Appointment"],
        }),
      onlineVisitAppointmentControllerDoctorFinishAppointment: build.mutation<
        OnlineVisitAppointmentControllerDoctorFinishAppointmentApiResponse,
        OnlineVisitAppointmentControllerDoctorFinishAppointmentApiArg
      >({
        query: (queryArg) => ({
          url: `/online-visit-appointment/doctor-finish-appointment`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Online Visit Appointment"],
      }),
      onlineVisitAppointmentControllerDoctorConnectAppointment: build.query<
        OnlineVisitAppointmentControllerDoctorConnectAppointmentApiResponse,
        OnlineVisitAppointmentControllerDoctorConnectAppointmentApiArg
      >({
        query: (queryArg) => ({
          url: `/online-visit-appointment/doctor-connect-appointment`,
          params: { internalOnlineVisitAppointmentId: queryArg },
        }),
        providesTags: ["Online Visit Appointment"],
      }),
      onlineVisitAppointmentControllerPatientConnectAppointment: build.query<
        OnlineVisitAppointmentControllerPatientConnectAppointmentApiResponse,
        OnlineVisitAppointmentControllerPatientConnectAppointmentApiArg
      >({
        query: (queryArg) => ({
          url: `/online-visit-appointment/patient-connect-appointment`,
          params: { internalOnlineVisitAppointmentId: queryArg },
        }),
        providesTags: ["Online Visit Appointment"],
      }),
      onlineVisitAppointmentControllerDoctorGetAppointmentInfoForProtocol:
        build.query<
          OnlineVisitAppointmentControllerDoctorGetAppointmentInfoForProtocolApiResponse,
          OnlineVisitAppointmentControllerDoctorGetAppointmentInfoForProtocolApiArg
        >({
          query: (queryArg) => ({
            url: `/online-visit-appointment/doctor-get-appointment-info-for-protocol/${queryArg}`,
          }),
          providesTags: ["Online Visit Appointment"],
        }),
      onlineVisitAppointmentControllerDoctorGetAppointmentInfoForCalendar:
        build.query<
          OnlineVisitAppointmentControllerDoctorGetAppointmentInfoForCalendarApiResponse,
          OnlineVisitAppointmentControllerDoctorGetAppointmentInfoForCalendarApiArg
        >({
          query: (queryArg) => ({
            url: `/online-visit-appointment/doctor-get-appointment-info-for-calendar/${queryArg}`,
          }),
          providesTags: ["Online Visit Appointment"],
        }),
      onlineVisitFeedbackControllerAddFeedback: build.mutation<
        OnlineVisitFeedbackControllerAddFeedbackApiResponse,
        OnlineVisitFeedbackControllerAddFeedbackApiArg
      >({
        query: (queryArg) => ({
          url: `/online-visit-feedback/patient-add-feedback`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Online Visit Feedback"],
      }),
      onlineVisitPaymentControllerPatientInitiatePayment: build.mutation<
        OnlineVisitPaymentControllerPatientInitiatePaymentApiResponse,
        OnlineVisitPaymentControllerPatientInitiatePaymentApiArg
      >({
        query: (queryArg) => ({
          url: `/online-visit-payment/patient-initiate-payment`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Online Visit Payment"],
      }),
      onlineVisitPaymentControllerPatientCompletePayment: build.mutation<
        OnlineVisitPaymentControllerPatientCompletePaymentApiResponse,
        OnlineVisitPaymentControllerPatientCompletePaymentApiArg
      >({
        query: (queryArg) => ({
          url: `/online-visit-payment/patient-complete-payment`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Online Visit Payment"],
      }),
      documentControllerUploadDocument: build.mutation<
        DocumentControllerUploadDocumentApiResponse,
        DocumentControllerUploadDocumentApiArg
      >({
        query: (queryArg) => ({
          url: `/documents/upload`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Documents"],
      }),
      documentControllerUploadDocumentDoctor: build.mutation<
        DocumentControllerUploadDocumentDoctorApiResponse,
        DocumentControllerUploadDocumentDoctorApiArg
      >({
        query: (queryArg) => ({
          url: `/documents/upload-doctor`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Documents"],
      }),
      documentControllerUploadAndParseManagerDocument: build.mutation<
        DocumentControllerUploadAndParseManagerDocumentApiResponse,
        DocumentControllerUploadAndParseManagerDocumentApiArg
      >({
        query: (queryArg) => ({
          url: `/documents/upload-manager-document`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Documents"],
      }),
      documentControllerMedicalRecords: build.query<
        DocumentControllerMedicalRecordsApiResponse,
        DocumentControllerMedicalRecordsApiArg
      >({
        query: (queryArg) => ({
          url: `/documents/medical-records`,
          params: { limit: queryArg.limit, offset: queryArg.offset },
        }),
        providesTags: ["Documents"],
      }),
      documentControllerMedicalRecordsFiles: build.query<
        DocumentControllerMedicalRecordsFilesApiResponse,
        DocumentControllerMedicalRecordsFilesApiArg
      >({
        query: (queryArg) => ({
          url: `/documents/medical-records/files`,
          params: {
            limit: queryArg.limit,
            offset: queryArg.offset,
            medicalRecordId: queryArg.medicalRecordId,
          },
        }),
        providesTags: ["Documents"],
      }),
      documentControllerMedicalRecordFile: build.query<
        DocumentControllerMedicalRecordFileApiResponse,
        DocumentControllerMedicalRecordFileApiArg
      >({
        query: (queryArg) => ({
          url: `/documents/medical-records/files/${queryArg}`,
        }),
        providesTags: ["Documents"],
      }),
      documentControllerAnalysisResults: build.query<
        DocumentControllerAnalysisResultsApiResponse,
        DocumentControllerAnalysisResultsApiArg
      >({
        query: (queryArg) => ({
          url: `/documents/analysis-results`,
          params: { limit: queryArg.limit, offset: queryArg.offset },
        }),
        providesTags: ["Documents"],
      }),
      documentControllerAnalysisResultForDoctor: build.query<
        DocumentControllerAnalysisResultForDoctorApiResponse,
        DocumentControllerAnalysisResultForDoctorApiArg
      >({
        query: (queryArg) => ({
          url: `/documents/analysis-results-for-doctor`,
          params: {
            userId: queryArg.userId,
            limit: queryArg.limit,
            offset: queryArg.offset,
          },
        }),
        providesTags: ["Documents"],
      }),
      documentControllerAnalysisResultsFiles: build.query<
        DocumentControllerAnalysisResultsFilesApiResponse,
        DocumentControllerAnalysisResultsFilesApiArg
      >({
        query: (queryArg) => ({
          url: `/documents/analysis-results/files`,
          params: {
            limit: queryArg.limit,
            offset: queryArg.offset,
            analysisId: queryArg.analysisId,
          },
        }),
        providesTags: ["Documents"],
      }),
      documentControllerAnalysisResultsFilesRequest: build.mutation<
        DocumentControllerAnalysisResultsFilesRequestApiResponse,
        DocumentControllerAnalysisResultsFilesRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/documents/analysis-results/files/request`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Documents"],
      }),
      documentControllerAnalysisResult: build.query<
        DocumentControllerAnalysisResultApiResponse,
        DocumentControllerAnalysisResultApiArg
      >({
        query: (queryArg) => ({
          url: `/documents/analysis-results/files/${queryArg}`,
        }),
        providesTags: ["Documents"],
      }),
      documentControllerTaxRefundList: build.query<
        DocumentControllerTaxRefundListApiResponse,
        DocumentControllerTaxRefundListApiArg
      >({
        query: (queryArg) => ({
          url: `/documents/tax-refund`,
          params: { limit: queryArg.limit, offset: queryArg.offset },
        }),
        providesTags: ["Documents"],
      }),
      documentControllerTaxRefundFilesList: build.query<
        DocumentControllerTaxRefundFilesListApiResponse,
        DocumentControllerTaxRefundFilesListApiArg
      >({
        query: (queryArg) => ({
          url: `/documents/tax-refund/files`,
          params: {
            limit: queryArg.limit,
            offset: queryArg.offset,
            taxRefundId: queryArg.taxRefundId,
          },
        }),
        providesTags: ["Documents"],
      }),
      documentControllerTaxRefundFile: build.query<
        DocumentControllerTaxRefundFileApiResponse,
        DocumentControllerTaxRefundFileApiArg
      >({
        query: (queryArg) => ({
          url: `/documents/tax-refund/files/${queryArg}`,
        }),
        providesTags: ["Documents"],
      }),
      documentControllerGetUserChatFiles: build.query<
        DocumentControllerGetUserChatFilesApiResponse,
        DocumentControllerGetUserChatFilesApiArg
      >({
        query: (queryArg) => ({
          url: `/documents/user-chat-files`,
          params: {
            limit: queryArg.limit,
            offset: queryArg.offset,
            userId: queryArg.userId,
            ids: queryArg.ids,
          },
        }),
        providesTags: ["Documents"],
      }),
      documentControllerUserUploadedFiles: build.query<
        DocumentControllerUserUploadedFilesApiResponse,
        DocumentControllerUserUploadedFilesApiArg
      >({
        query: (queryArg) => ({
          url: `/documents/uploaded-files`,
          params: { limit: queryArg.limit, offset: queryArg.offset },
        }),
        providesTags: ["Documents"],
      }),
      documentControllerUserUploadedFilesWithProtocols: build.query<
        DocumentControllerUserUploadedFilesWithProtocolsApiResponse,
        DocumentControllerUserUploadedFilesWithProtocolsApiArg
      >({
        query: (queryArg) => ({
          url: `/documents/uploaded-files-with-protocols`,
          params: { limit: queryArg.limit, offset: queryArg.offset },
        }),
        providesTags: ["Documents"],
      }),
      documentControllerUserUploadedFilesByPatientId: build.query<
        DocumentControllerUserUploadedFilesByPatientIdApiResponse,
        DocumentControllerUserUploadedFilesByPatientIdApiArg
      >({
        query: (queryArg) => ({
          url: `/documents/uploaded-files/patient/${queryArg.patientId}`,
          params: { limit: queryArg.limit, offset: queryArg.offset },
        }),
        providesTags: ["Documents"],
      }),
      documentControllerUserUploadedFile: build.query<
        DocumentControllerUserUploadedFileApiResponse,
        DocumentControllerUserUploadedFileApiArg
      >({
        query: (queryArg) => ({ url: `/documents/uploaded-files/${queryArg}` }),
        providesTags: ["Documents"],
      }),
      documentControllerDeleteUploadedFile: build.mutation<
        DocumentControllerDeleteUploadedFileApiResponse,
        DocumentControllerDeleteUploadedFileApiArg
      >({
        query: (queryArg) => ({
          url: `/documents/uploaded-files/${queryArg}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Documents"],
      }),
      documentControllerUserUploadedFileForDoctor: build.query<
        DocumentControllerUserUploadedFileForDoctorApiResponse,
        DocumentControllerUserUploadedFileForDoctorApiArg
      >({
        query: (queryArg) => ({
          url: `/documents/uploaded-files-for-doctor`,
          params: { userId: queryArg.userId, documentId: queryArg.documentId },
        }),
        providesTags: ["Documents"],
      }),
      documentControllerUserUploadedFileById: build.query<
        DocumentControllerUserUploadedFileByIdApiResponse,
        DocumentControllerUserUploadedFileByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/documents/uploaded-files-for-chat/${queryArg}`,
        }),
        providesTags: ["Documents"],
      }),
      documentControllerDoctorGetPatientProtocols: build.query<
        DocumentControllerDoctorGetPatientProtocolsApiResponse,
        DocumentControllerDoctorGetPatientProtocolsApiArg
      >({
        query: (queryArg) => ({
          url: `/documents/doctor-get-patient-protocols`,
          params: {
            limit: queryArg.limit,
            offset: queryArg.offset,
            userId: queryArg.userId,
          },
        }),
        providesTags: ["Documents"],
      }),
      protocolsControllerCreateOnlineVisitProtocol: build.mutation<
        ProtocolsControllerCreateOnlineVisitProtocolApiResponse,
        ProtocolsControllerCreateOnlineVisitProtocolApiArg
      >({
        query: (queryArg) => ({
          url: `/protocols/doctor-create-online-visit-protocol`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Protocols"],
      }),
      protocolsControllerDeleteProtocol: build.mutation<
        ProtocolsControllerDeleteProtocolApiResponse,
        ProtocolsControllerDeleteProtocolApiArg
      >({
        query: (queryArg) => ({
          url: `/protocols/doctor-delete-online-visit-protocol`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Protocols"],
      }),
      protocolsControllerGetProtocol: build.query<
        ProtocolsControllerGetProtocolApiResponse,
        ProtocolsControllerGetProtocolApiArg
      >({
        query: (queryArg) => ({
          url: `/protocols/doctor-get-online-visit-protocol/${queryArg}`,
        }),
        providesTags: ["Protocols"],
      }),
      protocolsControllerUpdateProtocol: build.mutation<
        ProtocolsControllerUpdateProtocolApiResponse,
        ProtocolsControllerUpdateProtocolApiArg
      >({
        query: (queryArg) => ({
          url: `/protocols/doctor-update-online-visit-protocol/${queryArg.id}`,
          method: "PUT",
          body: queryArg.updateProtocolRequestDto,
        }),
        invalidatesTags: ["Protocols"],
      }),
      conversationControllerDoctorConversationList: build.query<
        ConversationControllerDoctorConversationListApiResponse,
        ConversationControllerDoctorConversationListApiArg
      >({
        query: () => ({ url: `/conversation/doctor-conversation-list` }),
        providesTags: ["Conversation"],
      }),
      conversationControllerPatientConversationList: build.query<
        ConversationControllerPatientConversationListApiResponse,
        ConversationControllerPatientConversationListApiArg
      >({
        query: () => ({ url: `/conversation/patient-conversation-list` }),
        providesTags: ["Conversation"],
      }),
      conversationControllerDoctorReadConversation: build.mutation<
        ConversationControllerDoctorReadConversationApiResponse,
        ConversationControllerDoctorReadConversationApiArg
      >({
        query: (queryArg) => ({
          url: `/conversation/doctor-read-conversation`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Conversation"],
      }),
      conversationControllerPatientReadConversation: build.mutation<
        ConversationControllerPatientReadConversationApiResponse,
        ConversationControllerPatientReadConversationApiArg
      >({
        query: (queryArg) => ({
          url: `/conversation/patient-read-conversation`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Conversation"],
      }),
      conversationControllerDoctorConnectToConversation: build.query<
        ConversationControllerDoctorConnectToConversationApiResponse,
        ConversationControllerDoctorConnectToConversationApiArg
      >({
        query: (queryArg) => ({
          url: `/conversation/doctor-connect-to-conversation`,
          params: { internalConversationId: queryArg },
        }),
        providesTags: ["Conversation"],
      }),
      conversationControllerPatientConnectToConversation: build.query<
        ConversationControllerPatientConnectToConversationApiResponse,
        ConversationControllerPatientConnectToConversationApiArg
      >({
        query: (queryArg) => ({
          url: `/conversation/patient-connect-to-conversation`,
          params: { internalConversationId: queryArg },
        }),
        providesTags: ["Conversation"],
      }),
      chatControllerGetUniqueSpecializations: build.query<
        ChatControllerGetUniqueSpecializationsApiResponse,
        ChatControllerGetUniqueSpecializationsApiArg
      >({
        query: () => ({ url: `/chat/specialization` }),
        providesTags: ["Chat"],
      }),
      chatControllerGetAssistingDoctor: build.query<
        ChatControllerGetAssistingDoctorApiResponse,
        ChatControllerGetAssistingDoctorApiArg
      >({
        query: (queryArg) => ({
          url: `/chat/assisting-doctors`,
          params: { specialization: queryArg },
        }),
        providesTags: ["Chat"],
      }),
      chatControllerGetChatDoctor: build.query<
        ChatControllerGetChatDoctorApiResponse,
        ChatControllerGetChatDoctorApiArg
      >({
        query: (queryArg) => ({
          url: `/chat/chat-support-doctors`,
          params: { specialization: queryArg },
        }),
        providesTags: ["Chat"],
      }),
      chatControllerMakeHelpAssistingChat: build.mutation<
        ChatControllerMakeHelpAssistingChatApiResponse,
        ChatControllerMakeHelpAssistingChatApiArg
      >({
        query: (queryArg) => ({
          url: `/chat/help-assisting-chat`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Chat"],
      }),
      chatControllerGetPatientChatList: build.query<
        ChatControllerGetPatientChatListApiResponse,
        ChatControllerGetPatientChatListApiArg
      >({
        query: () => ({ url: `/chat/patient-chat-list` }),
        providesTags: ["Chat"],
      }),
      authControllerIsUserExists: build.mutation<
        AuthControllerIsUserExistsApiResponse,
        AuthControllerIsUserExistsApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/validate-phone`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Auth"],
      }),
      authControllerLogin: build.mutation<
        AuthControllerLoginApiResponse,
        AuthControllerLoginApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/signin`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Auth"],
      }),
      authControllerChangeAccount: build.mutation<
        AuthControllerChangeAccountApiResponse,
        AuthControllerChangeAccountApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/account-change/${queryArg}`,
          method: "POST",
        }),
        invalidatesTags: ["Auth"],
      }),
      authControllerRefreshToken: build.mutation<
        AuthControllerRefreshTokenApiResponse,
        AuthControllerRefreshTokenApiArg
      >({
        query: () => ({ url: `/auth/refresh`, method: "POST" }),
        invalidatesTags: ["Auth"],
      }),
      authControllerLogout: build.mutation<
        AuthControllerLogoutApiResponse,
        AuthControllerLogoutApiArg
      >({
        query: () => ({ url: `/auth/logout`, method: "POST" }),
        invalidatesTags: ["Auth"],
      }),
      internalAuthControllerLogin: build.mutation<
        InternalAuthControllerLoginApiResponse,
        InternalAuthControllerLoginApiArg
      >({
        query: (queryArg) => ({
          url: `/internal/auth/login`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Auth (Internal)"],
      }),
      internalAuthControllerSetPushToken: build.mutation<
        InternalAuthControllerSetPushTokenApiResponse,
        InternalAuthControllerSetPushTokenApiArg
      >({
        query: (queryArg) => ({
          url: `/internal/auth/push-token`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Auth (Internal)"],
      }),
      internalAuthControllerGetDoctor: build.query<
        InternalAuthControllerGetDoctorApiResponse,
        InternalAuthControllerGetDoctorApiArg
      >({
        query: () => ({ url: `/internal/auth/me` }),
        providesTags: ["Auth (Internal)"],
      }),
      internalAuthControllerLogout: build.mutation<
        InternalAuthControllerLogoutApiResponse,
        InternalAuthControllerLogoutApiArg
      >({
        query: () => ({ url: `/internal/auth/logout`, method: "POST" }),
        invalidatesTags: ["Auth (Internal)"],
      }),
      internalAuthControllerSendValidateCode: build.mutation<
        InternalAuthControllerSendValidateCodeApiResponse,
        InternalAuthControllerSendValidateCodeApiArg
      >({
        query: (queryArg) => ({
          url: `/internal/auth/reset-password/code`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Auth (Internal)"],
      }),
      internalAuthControllerSendResetPasswordLink: build.mutation<
        InternalAuthControllerSendResetPasswordLinkApiResponse,
        InternalAuthControllerSendResetPasswordLinkApiArg
      >({
        query: (queryArg) => ({
          url: `/internal/auth/reset-password/link`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Auth (Internal)"],
      }),
      internalAuthControllerChangePasswordViaCode: build.mutation<
        InternalAuthControllerChangePasswordViaCodeApiResponse,
        InternalAuthControllerChangePasswordViaCodeApiArg
      >({
        query: (queryArg) => ({
          url: `/internal/auth/change-password-via-code`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Auth (Internal)"],
      }),
      internalAuthControllerVerifyCode: build.mutation<
        InternalAuthControllerVerifyCodeApiResponse,
        InternalAuthControllerVerifyCodeApiArg
      >({
        query: (queryArg) => ({
          url: `/internal/auth/verify-code`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Auth (Internal)"],
      }),
      internalAuthControllerResetPassword: build.mutation<
        InternalAuthControllerResetPasswordApiResponse,
        InternalAuthControllerResetPasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/internal/auth/reset-password`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Auth (Internal)"],
      }),
      internalAuthControllerRefreshToken: build.mutation<
        InternalAuthControllerRefreshTokenApiResponse,
        InternalAuthControllerRefreshTokenApiArg
      >({
        query: () => ({ url: `/internal/auth/refresh`, method: "POST" }),
        invalidatesTags: ["Auth (Internal)"],
      }),
      internalAuthControllerChangePassword: build.mutation<
        InternalAuthControllerChangePasswordApiResponse,
        InternalAuthControllerChangePasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/internal/auth/change-password`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Auth (Internal)"],
      }),
      calendarControllerGetEventList: build.query<
        CalendarControllerGetEventListApiResponse,
        CalendarControllerGetEventListApiArg
      >({
        query: (queryArg) => ({
          url: `/calendar/event-list`,
          params: {
            fromDateTimeIso: queryArg.fromDateTimeIso,
            toDateTimeIso: queryArg.toDateTimeIso,
          },
        }),
        providesTags: ["Calendar"],
      }),
      homeVisitControllerFindDoctors: build.query<
        HomeVisitControllerFindDoctorsApiResponse,
        HomeVisitControllerFindDoctorsApiArg
      >({
        query: (queryArg) => ({
          url: `/home-visit/doctors`,
          params: {
            limit: queryArg.limit,
            offset: queryArg.offset,
            cityId: queryArg.cityId,
            specialization: queryArg.specialization,
          },
        }),
        providesTags: ["Home-Visit"],
      }),
      homeVisitControllerCreateHomeVisitRequest: build.mutation<
        HomeVisitControllerCreateHomeVisitRequestApiResponse,
        HomeVisitControllerCreateHomeVisitRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/home-visit/create-home-visit-request`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Home-Visit"],
      }),
      homeVisitControllerCancelHomeVisitRequest: build.mutation<
        HomeVisitControllerCancelHomeVisitRequestApiResponse,
        HomeVisitControllerCancelHomeVisitRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/home-visit/cancel-home-visit-request`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Home-Visit"],
      }),
      homeVisitControllerUpcomingHomeVisit: build.query<
        HomeVisitControllerUpcomingHomeVisitApiResponse,
        HomeVisitControllerUpcomingHomeVisitApiArg
      >({
        query: () => ({ url: `/home-visit/upcoming-home-visit` }),
        providesTags: ["Home-Visit"],
      }),
      homeVisitControllerPastHomeVisit: build.query<
        HomeVisitControllerPastHomeVisitApiResponse,
        HomeVisitControllerPastHomeVisitApiArg
      >({
        query: () => ({ url: `/home-visit/past-home-visit` }),
        providesTags: ["Home-Visit"],
      }),
      homeVisitControllerGetDoctorUpcomingHomeVisits: build.query<
        HomeVisitControllerGetDoctorUpcomingHomeVisitsApiResponse,
        HomeVisitControllerGetDoctorUpcomingHomeVisitsApiArg
      >({
        query: (queryArg) => ({
          url: `/home-visit/doctor-upcoming-home-visits`,
          params: { callDate: queryArg },
        }),
        providesTags: ["Home-Visit"],
      }),
      homeVisitControllerFinishHomeVisit: build.mutation<
        HomeVisitControllerFinishHomeVisitApiResponse,
        HomeVisitControllerFinishHomeVisitApiArg
      >({
        query: (queryArg) => ({
          url: `/home-visit/finish`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Home-Visit"],
      }),
      homeVisitControllerGetDoctorPastHomeVisits: build.query<
        HomeVisitControllerGetDoctorPastHomeVisitsApiResponse,
        HomeVisitControllerGetDoctorPastHomeVisitsApiArg
      >({
        query: (queryArg) => ({
          url: `/home-visit/doctor-past-home-visits`,
          params: {
            limit: queryArg.limit,
            offset: queryArg.offset,
            patientName: queryArg.patientName,
            patientId: queryArg.patientId,
          },
        }),
        providesTags: ["Home-Visit"],
      }),
      homeVisitControllerGetHomeVisit: build.query<
        HomeVisitControllerGetHomeVisitApiResponse,
        HomeVisitControllerGetHomeVisitApiArg
      >({
        query: (queryArg) => ({ url: `/home-visit/form/${queryArg}` }),
        providesTags: ["Home-Visit"],
      }),
      adminDoctorsControllerDoctorsList: build.query<
        AdminDoctorsControllerDoctorsListApiResponse,
        AdminDoctorsControllerDoctorsListApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/doctors`,
          params: {
            limit: queryArg.limit,
            offset: queryArg.offset,
            searchLine: queryArg.searchLine,
          },
        }),
        providesTags: ["doctors(admin)"],
      }),
      adminDoctorsControllerDoctor: build.query<
        AdminDoctorsControllerDoctorApiResponse,
        AdminDoctorsControllerDoctorApiArg
      >({
        query: (queryArg) => ({ url: `/admin/doctors/${queryArg}` }),
        providesTags: ["doctors(admin)"],
      }),
      adminDoctorsControllerAddAccount: build.mutation<
        AdminDoctorsControllerAddAccountApiResponse,
        AdminDoctorsControllerAddAccountApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/doctors/${queryArg.id}/account`,
          method: "POST",
          body: queryArg.addDoctorAccountRequest,
        }),
        invalidatesTags: ["doctors(admin)"],
      }),
      adminDoctorsControllerUpdateAccount: build.mutation<
        AdminDoctorsControllerUpdateAccountApiResponse,
        AdminDoctorsControllerUpdateAccountApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/doctors/${queryArg.id}/account`,
          method: "PUT",
          body: queryArg.updateDoctorAccountRequest,
        }),
        invalidatesTags: ["doctors(admin)"],
      }),
      adminDoctorsControllerDeleteAccount: build.mutation<
        AdminDoctorsControllerDeleteAccountApiResponse,
        AdminDoctorsControllerDeleteAccountApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/doctors/${queryArg}/account`,
          method: "DELETE",
        }),
        invalidatesTags: ["doctors(admin)"],
      }),
      adminVisitsControllerGetHomeVisits: build.query<
        AdminVisitsControllerGetHomeVisitsApiResponse,
        AdminVisitsControllerGetHomeVisitsApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/visits/home-visits`,
          params: {
            limit: queryArg.limit,
            offset: queryArg.offset,
            name: queryArg.name,
            date: queryArg.date,
            statuses: queryArg.statuses,
          },
        }),
        providesTags: ["Appointment (Admin)"],
      }),
      adminVisitsControllerGetPastHomeVisits: build.query<
        AdminVisitsControllerGetPastHomeVisitsApiResponse,
        AdminVisitsControllerGetPastHomeVisitsApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/visits/past-home-visits`,
          params: { limit: queryArg.limit, offset: queryArg.offset },
        }),
        providesTags: ["Appointment (Admin)"],
      }),
      adminVisitsControllerGetPastHomeVisit: build.query<
        AdminVisitsControllerGetPastHomeVisitApiResponse,
        AdminVisitsControllerGetPastHomeVisitApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/visits/past-home-visit/${queryArg}`,
        }),
        providesTags: ["Appointment (Admin)"],
      }),
      adminVisitsControllerGetChatAssisting: build.query<
        AdminVisitsControllerGetChatAssistingApiResponse,
        AdminVisitsControllerGetChatAssistingApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/visits/chat-assisting`,
          params: {
            limit: queryArg.limit,
            offset: queryArg.offset,
            name: queryArg.name,
            date: queryArg.date,
            statuses: queryArg.statuses,
          },
        }),
        providesTags: ["Appointment (Admin)"],
      }),
      adminVisitsControllerGetOneChatAssistingVisit: build.query<
        AdminVisitsControllerGetOneChatAssistingVisitApiResponse,
        AdminVisitsControllerGetOneChatAssistingVisitApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/visits/chat-assisting-visit/${queryArg}`,
        }),
        providesTags: ["Appointment (Admin)"],
      }),
      adminVisitsControllerGetOneHomeVisit: build.query<
        AdminVisitsControllerGetOneHomeVisitApiResponse,
        AdminVisitsControllerGetOneHomeVisitApiArg
      >({
        query: (queryArg) => ({ url: `/admin/visits/home-visit/${queryArg}` }),
        providesTags: ["Appointment (Admin)"],
      }),
      adminVisitsControllerSetHomeVisitInProgress: build.mutation<
        AdminVisitsControllerSetHomeVisitInProgressApiResponse,
        AdminVisitsControllerSetHomeVisitInProgressApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/visits/home-visit-in-progress`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Appointment (Admin)"],
      }),
      adminVisitsControllerSetChatAssistingInProgress: build.mutation<
        AdminVisitsControllerSetChatAssistingInProgressApiResponse,
        AdminVisitsControllerSetChatAssistingInProgressApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/visits/chat-assisting-in-progress`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Appointment (Admin)"],
      }),
      adminVisitsControllerAcceptHomeVisit: build.mutation<
        AdminVisitsControllerAcceptHomeVisitApiResponse,
        AdminVisitsControllerAcceptHomeVisitApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/visits/accept-home-visit`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Appointment (Admin)"],
      }),
      adminVisitsControllerAcceptChatAssisting: build.mutation<
        AdminVisitsControllerAcceptChatAssistingApiResponse,
        AdminVisitsControllerAcceptChatAssistingApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/visits/accept-chat-assisting-visit`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Appointment (Admin)"],
      }),
      adminVisitsControllerRejectChatAssistingVisit: build.mutation<
        AdminVisitsControllerRejectChatAssistingVisitApiResponse,
        AdminVisitsControllerRejectChatAssistingVisitApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/visits/reject-chat-assisting-visit`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Appointment (Admin)"],
      }),
      adminVisitsControllerRejectHomeVisit: build.mutation<
        AdminVisitsControllerRejectHomeVisitApiResponse,
        AdminVisitsControllerRejectHomeVisitApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/visits/accept-refuse-home-visit`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Appointment (Admin)"],
      }),
      adminVisitsControllerViewRejectByPatient: build.query<
        AdminVisitsControllerViewRejectByPatientApiResponse,
        AdminVisitsControllerViewRejectByPatientApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/visits/home-visit-refuses`,
          params: {
            limit: queryArg.limit,
            offset: queryArg.offset,
            statuses: queryArg.statuses,
          },
        }),
        providesTags: ["Appointment (Admin)"],
      }),
      adminVisitsControllerViewRejectByPatientById: build.query<
        AdminVisitsControllerViewRejectByPatientByIdApiResponse,
        AdminVisitsControllerViewRejectByPatientByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/visits/home-visit-refuse/${queryArg}`,
        }),
        providesTags: ["Appointment (Admin)"],
      }),
      doctorPanelControllerAnalysisResultForDoctorWeb: build.query<
        DoctorPanelControllerAnalysisResultForDoctorWebApiResponse,
        DoctorPanelControllerAnalysisResultForDoctorWebApiArg
      >({
        query: (queryArg) => ({
          url: `/doctor-panel/analysis-result`,
          params: {
            userId: queryArg.userId,
            limit: queryArg.limit,
            offset: queryArg.offset,
          },
        }),
        providesTags: ["Doctor-Panel"],
      }),
      doctorPanelControllerDownloadUseFile: build.query<
        DoctorPanelControllerDownloadUseFileApiResponse,
        DoctorPanelControllerDownloadUseFileApiArg
      >({
        query: (queryArg) => ({
          url: `/doctor-panel/download-patient-file`,
          params: { userId: queryArg.userId, fileId: queryArg.fileId },
        }),
        providesTags: ["Doctor-Panel"],
      }),
      doctorPanelControllerAnalysisResult: build.query<
        DoctorPanelControllerAnalysisResultApiResponse,
        DoctorPanelControllerAnalysisResultApiArg
      >({
        query: (queryArg) => ({
          url: `/doctor-panel/download-analysis-files`,
          params: { userId: queryArg.userId, fileId: queryArg.fileId },
        }),
        providesTags: ["Doctor-Panel"],
      }),
      doctorPanelControllerUploadDocumentDoctor: build.mutation<
        DoctorPanelControllerUploadDocumentDoctorApiResponse,
        DoctorPanelControllerUploadDocumentDoctorApiArg
      >({
        query: (queryArg) => ({
          url: `/doctor-panel/upload`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Doctor-Panel"],
      }),
      doctorPanelControllerUploadConversationDocument: build.mutation<
        DoctorPanelControllerUploadConversationDocumentApiResponse,
        DoctorPanelControllerUploadConversationDocumentApiArg
      >({
        query: (queryArg) => ({
          url: `/doctor-panel/upload-conversation-document`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Doctor-Panel"],
      }),
      doctorPanelControllerPatient: build.query<
        DoctorPanelControllerPatientApiResponse,
        DoctorPanelControllerPatientApiArg
      >({
        query: (queryArg) => ({
          url: `/doctor-panel/current-patient`,
          params: {
            limit: queryArg.limit,
            offset: queryArg.offset,
            patientName: queryArg.patientName,
            patientId: queryArg.patientId,
          },
        }),
        providesTags: ["Doctor-Panel"],
      }),
      doctorPanelControllerPatients: build.query<
        DoctorPanelControllerPatientsApiResponse,
        DoctorPanelControllerPatientsApiArg
      >({
        query: (queryArg) => ({
          url: `/doctor-panel/patients`,
          params: {
            limit: queryArg.limit,
            offset: queryArg.offset,
            patientName: queryArg.patientName,
            patientId: queryArg.patientId,
          },
        }),
        providesTags: ["Doctor-Panel"],
      }),
      doctorPanelControllerPatientFiles: build.query<
        DoctorPanelControllerPatientFilesApiResponse,
        DoctorPanelControllerPatientFilesApiArg
      >({
        query: (queryArg) => ({
          url: `/doctor-panel/uploaded-patient-files`,
          params: {
            limit: queryArg.limit,
            offset: queryArg.offset,
            userId: queryArg.userId,
            ids: queryArg.ids,
          },
        }),
        providesTags: ["Doctor-Panel"],
      }),
      doctorPanelControllerChatMembers: build.query<
        DoctorPanelControllerChatMembersApiResponse,
        DoctorPanelControllerChatMembersApiArg
      >({
        query: () => ({ url: `/doctor-panel/chat-members` }),
        providesTags: ["Doctor-Panel"],
      }),
      doctorPanelControllerReadChat: build.mutation<
        DoctorPanelControllerReadChatApiResponse,
        DoctorPanelControllerReadChatApiArg
      >({
        query: (queryArg) => ({
          url: `/doctor-panel/read-chat`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Doctor-Panel"],
      }),
      optionsControllerGetOptions: build.query<
        OptionsControllerGetOptionsApiResponse,
        OptionsControllerGetOptionsApiArg
      >({
        query: (queryArg) => ({
          url: `/options`,
          params: { options: queryArg },
        }),
        providesTags: ["Options"],
      }),
      marketingMailingControllerCreateMarketingMailingWithIds: build.mutation<
        MarketingMailingControllerCreateMarketingMailingWithIdsApiResponse,
        MarketingMailingControllerCreateMarketingMailingWithIdsApiArg
      >({
        query: (queryArg) => ({
          url: `/marketing-mailing/create-marketing-mailing-by-ids`,
          method: "POST",
          body: queryArg,
        }),
        invalidatesTags: ["Marketing Mailing"],
      }),
      marketingMailingControllerCreateMarketingMailingWithFilters:
        build.mutation<
          MarketingMailingControllerCreateMarketingMailingWithFiltersApiResponse,
          MarketingMailingControllerCreateMarketingMailingWithFiltersApiArg
        >({
          query: (queryArg) => ({
            url: `/marketing-mailing/create-marketing-mailing-by-filters`,
            method: "POST",
            body: queryArg,
          }),
          invalidatesTags: ["Marketing Mailing"],
        }),
      marketingMailingControllerFindAll: build.query<
        MarketingMailingControllerFindAllApiResponse,
        MarketingMailingControllerFindAllApiArg
      >({
        query: (queryArg) => ({
          url: `/marketing-mailing`,
          params: {
            limit: queryArg.limit,
            offset: queryArg.offset,
            searchLine: queryArg.searchLine,
          },
        }),
        providesTags: ["Marketing Mailing"],
      }),
      marketingMailingControllerDeleteMarketingMailing: build.mutation<
        MarketingMailingControllerDeleteMarketingMailingApiResponse,
        MarketingMailingControllerDeleteMarketingMailingApiArg
      >({
        query: (queryArg) => ({
          url: `/marketing-mailing/${queryArg}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Marketing Mailing"],
      }),
      marketingMailingControllerFindOne: build.query<
        MarketingMailingControllerFindOneApiResponse,
        MarketingMailingControllerFindOneApiArg
      >({
        query: (queryArg) => ({ url: `/marketing-mailing/${queryArg}` }),
        providesTags: ["Marketing Mailing"],
      }),
      marketingMailingControllerUpdateMarketingMailingByFilters: build.mutation<
        MarketingMailingControllerUpdateMarketingMailingByFiltersApiResponse,
        MarketingMailingControllerUpdateMarketingMailingByFiltersApiArg
      >({
        query: (queryArg) => ({
          url: `/marketing-mailing/${queryArg.id}/update-marketing-mailing-by-filters`,
          method: "PUT",
          body: queryArg.updateMarketingMailingByFiltersRequestDto,
        }),
        invalidatesTags: ["Marketing Mailing"],
      }),
      marketingMailingControllerUpdateMarketingMailingByIds: build.mutation<
        MarketingMailingControllerUpdateMarketingMailingByIdsApiResponse,
        MarketingMailingControllerUpdateMarketingMailingByIdsApiArg
      >({
        query: (queryArg) => ({
          url: `/marketing-mailing/${queryArg.id}/update-marketing-mailing-by-ids`,
          method: "PUT",
          body: queryArg.updateMarketingMailingByIdsRequestDto,
        }),
        invalidatesTags: ["Marketing Mailing"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as rtkQueryApi };
export type DoctorNotificationControllerGetNotificationApiResponse =
  /** status 200 List of doctor notifications */ DoctorNotificationResponseDto[];
export type DoctorNotificationControllerGetNotificationApiArg = void;
export type DoctorNotificationControllerGetUnreadNotificationCountApiResponse =
  /** status 200 Количество непрочитанных уведомлений */ number;
export type DoctorNotificationControllerGetUnreadNotificationCountApiArg = void;
export type DoctorNotificationControllerViewNotificationsApiResponse =
  /** status 200  */ void;
export type DoctorNotificationControllerViewNotificationsApiArg =
  NotificationsViewRequest;
export type DoctorNotificationControllerDeclineHomeVisitApiResponse =
  /** status 200  */ void;
export type DoctorNotificationControllerDeclineHomeVisitApiArg =
  NotificationIdDto;
export type DoctorNotificationControllerDeclineHomeVisitByFormIdApiResponse =
  /** status 200  */ void;
export type DoctorNotificationControllerDeclineHomeVisitByFormIdApiArg =
  RejectByHomeVisitFormIdDto;
export type DoctorNotificationControllerAcceptRequestApiResponse =
  /** status 201  */ void;
export type DoctorNotificationControllerAcceptRequestApiArg = AcceptRequest;
export type DoctorNotificationControllerAcceptRequestByFormIdApiResponse =
  /** status 201  */ void;
export type DoctorNotificationControllerAcceptRequestByFormIdApiArg =
  AcceptByFormIdRequest;
export type NotificationAdminControllerGetNotificationsForCurrentAdminApiResponse =
  /** status 200 List of admin notifications */ AdminNotificationResponseDto[];
export type NotificationAdminControllerGetNotificationsForCurrentAdminApiArg =
  void;
export type NotificationAdminControllerReadNotificationApiResponse =
  /** status 201  */ void;
export type NotificationAdminControllerReadNotificationApiArg =
  NotificationsViewRequest;
export type NotificationAdminControllerHasUnreadMessagesApiResponse =
  /** status 200  */ boolean;
export type NotificationAdminControllerHasUnreadMessagesApiArg = void;
export type PaymentControllerCreateHomeVisitPaymentApiResponse =
  /** status 201  */ PaymentResponse;
export type PaymentControllerCreateHomeVisitPaymentApiArg =
  PaymentHomeVisitRequest;
export type PaymentControllerCreateChatAssistingPaymentApiResponse =
  /** status 201  */ PaymentResponse;
export type PaymentControllerCreateChatAssistingPaymentApiArg =
  PaymentChatAssistingRequest;
export type DoctorControllerDoctorsListApiResponse =
  /** status 200  */ DoctorListResponse;
export type DoctorControllerDoctorsListApiArg = {
  limit?: number;
  offset?: number;
  cityId: number;
  clinicId?: number;
  specialty?: string;
  search?: string;
};
export type DoctorControllerDoctorsListByPatientIdApiResponse =
  /** status 200  */ DoctorListResponse;
export type DoctorControllerDoctorsListByPatientIdApiArg = {
  limit?: number;
  offset?: number;
};
export type DoctorControllerSpecialtyListApiResponse =
  /** status 200  */ SpecializationsListResponse;
export type DoctorControllerSpecialtyListApiArg = {
  cityId: number;
  clinicId?: number;
};
export type DoctorControllerDoctorApiResponse =
  /** status 200  */ DoctorResponse;
export type DoctorControllerDoctorApiArg = number;
export type ScheduleControllerHomeVisitApiResponse =
  /** status 201  */ RecordResultResponse;
export type ScheduleControllerHomeVisitApiArg = HomeVisitRequest;
export type ScheduleControllerHomeVisitPaymentListApiResponse =
  /** status 200  */ HomeVisitListResponse;
export type ScheduleControllerHomeVisitPaymentListApiArg = {
  limit?: number;
  offset?: number;
};
export type ScheduleControllerUserUpcomingHomeVisitApiResponse =
  /** status 200  */ HomeVisitResponse;
export type ScheduleControllerUserUpcomingHomeVisitApiArg = number;
export type UserControllerClearTaxRefundDateApiResponse = unknown;
export type UserControllerClearTaxRefundDateApiArg = void;
export type UserControllerTaxRefundApiResponse = unknown;
export type UserControllerTaxRefundApiArg = TaxRefundRequest;
export type UserControllerCheckTaxRefundApiResponse = unknown;
export type UserControllerCheckTaxRefundApiArg = void;
export type UserControllerFindUserDataApiResponse =
  /** status 200  */ UserResponse;
export type UserControllerFindUserDataApiArg = void;
export type UserControllerDeleteUserApiResponse = /** status 200  */ void;
export type UserControllerDeleteUserApiArg = void;
export type UserControllerFindAccountsApiResponse =
  /** status 200  */ AccountResponse;
export type UserControllerFindAccountsApiArg = void;
export type UserControllerSetPushTokenApiResponse = /** status 201  */ void;
export type UserControllerSetPushTokenApiArg = SetPushTokenRequest;
export type UserControllerCreateUserFeedbackApiResponse =
  /** status 201  */ void;
export type UserControllerCreateUserFeedbackApiArg = UserFeedback;
export type OnlineVisitSlotControllerAddSlotApiResponse =
  /** status 201 Slot created */ OnlineVisitDoctorAddSlotResponseDto;
export type OnlineVisitSlotControllerAddSlotApiArg =
  OnlineVisitAddSlotRequestDto;
export type OnlineVisitSlotControllerDeleteSlotApiResponse =
  /** status 201  */
  void | /** status 204 Slot deleted */ void;
export type OnlineVisitSlotControllerDeleteSlotApiArg =
  OnlineVisitDoctorDeleteSlotRequestDto;
export type OnlineVisitDoctorControllerGetOnlineVisitDoctorSlotListApiResponse =
  /** status 200 Doctor slots list */ OnlineVisitGetSlotListResponseDto;
export type OnlineVisitDoctorControllerGetOnlineVisitDoctorSlotListApiArg = {
  limit?: number;
  offset?: number;
  /** Search by internal online doctor specialization */
  specialization?: string;
  /** Start date time in ISO format */
  fromDateTimeIso: string;
  /** End date time in ISO format */
  toDateTimeIso: string;
};
export type OnlineVisitDoctorControllerGetOnlineVisitDoctorsSpecialityListApiResponse =
  /** status 200 Doctors specialization list */ OnlineVisitSpecializationListResponseDto;
export type OnlineVisitDoctorControllerGetOnlineVisitDoctorsSpecialityListApiArg =
  string;
export type OnlineVisitAppointmentControllerPatientCreateAppointmentApiResponse =
  /** status 200 Appointment created */
  | OnlineVisitPatientCreateAppointmentResponseDto
  | /** status 201  */ OnlineVisitPatientCreateAppointmentResponseDto;
export type OnlineVisitAppointmentControllerPatientCreateAppointmentApiArg =
  OnlineVisitPatientCreateAppointmentRequestDto;
export type OnlineVisitAppointmentControllerAdminRejectRequestToAppointmentApiResponse =
  /** status 201  */ void | /** status 204 Appointment rejected */ void;
export type OnlineVisitAppointmentControllerAdminRejectRequestToAppointmentApiArg =
  OnlineVisitAdminCancelAppointmentRequestDto;
export type OnlineVisitAppointmentControllerAdminAssignAppointmentApiResponse =
  /** status 201  */ void | /** status 204 Appointment assigned */ void;
export type OnlineVisitAppointmentControllerAdminAssignAppointmentApiArg =
  OnlineVisitAdminAssignAppointmentRequestDto;
export type OnlineVisitAppointmentControllerAdminAcceptAppointmentApiResponse =
  /** status 201  */ void | /** status 204 Appointment accepted */ void;
export type OnlineVisitAppointmentControllerAdminAcceptAppointmentApiArg =
  OnlineVisitAdminAcceptAppointmentRequestDto;
export type OnlineVisitAppointmentControllerAdminEditAppointmentApiResponse =
  /** status 201  */ void | /** status 204 Appointment edited */ void;
export type OnlineVisitAppointmentControllerAdminEditAppointmentApiArg =
  OnlineVisitAdminEditAppointmentRequestDto;
export type OnlineVisitAppointmentControllerPatientCancelAppointmentApiResponse =
  /** status 201  */ void | /** status 204 Cancellation request sent */ void;
export type OnlineVisitAppointmentControllerPatientCancelAppointmentApiArg =
  OnlineVisitPatientCreateCancellationRequestDto;
export type OnlineVisitAppointmentControllerAdminCancelAppointmentApiResponse =
  /** status 201  */ void | /** status 204 Appointment cancelled */ void;
export type OnlineVisitAppointmentControllerAdminCancelAppointmentApiArg =
  OnlineVisitAdminCancelAppointmentRequestDto;
export type OnlineVisitAppointmentControllerAdminAssignCancellationApiResponse =
  /** status 201  */
  void | /** status 204 Cancellation request assigned */ void;
export type OnlineVisitAppointmentControllerAdminAssignCancellationApiArg =
  OnlineVisitAdminCancelAppointmentRequestDto;
export type OnlineVisitAppointmentControllerGetPatientEventListApiResponse =
  /** status 200 List of patient online visit appointments */ OnlineVisitPatientGetAppointmentListResponseDto;
export type OnlineVisitAppointmentControllerGetPatientEventListApiArg = {
  limit?: number;
  offset?: number;
  /** From start date time in ISO format */
  fromStartDateTimeIso?: string;
  /** To start date time in ISO format */
  toStartDateTimeIso?: string;
  /** Is paid */
  isPaid?: boolean;
  /** Statuses */
  statuses?: OnlineVisitAppointmentForPatientStatusEnum[];
};
export type OnlineVisitAppointmentControllerGetAdminOnlineVisitAppointmentListApiResponse =
  /** status 200 List of patient online visit appointments */ OnlineVisitAdminAppointmentListResponseDto;
export type OnlineVisitAppointmentControllerGetAdminOnlineVisitAppointmentListApiArg =
  {
    limit?: number;
    offset?: number;
    /** From start date time in ISO format */
    fromStartDateTimeIso?: string;
    /** To start date time in ISO format */
    toStartDateTimeIso?: string;
    /** Online visit statuses */
    statuses?: OnlineVisitForAdminStatusEnum[];
    /** Search */
    search?: string;
  };
export type OnlineVisitAppointmentControllerGetAdminOnlineVisitAppointmentApiResponse =
  /** status 200 Online visit appointment */ OnlineVisitAdminAppointmentResponseDto;
export type OnlineVisitAppointmentControllerGetAdminOnlineVisitAppointmentApiArg =
  number;
export type OnlineVisitAppointmentControllerDoctorAddSpecialistForAppointmentApiResponse =
  /** status 201  */ void | /** status 204 Specialist added */ void;
export type OnlineVisitAppointmentControllerDoctorAddSpecialistForAppointmentApiArg =
  OnlineVisitDoctorAddSpecialistToAppointmentRequestDto;
export type OnlineVisitAppointmentControllerDoctorRemoveSpecialistForAppointmentApiResponse =
  /** status 201  */ void | /** status 204 Specialist removed */ void;
export type OnlineVisitAppointmentControllerDoctorRemoveSpecialistForAppointmentApiArg =
  OnlineVisitDoctorRemoveSpecialistFromAppointmentRequestDto;
export type OnlineVisitAppointmentControllerDoctorFinishAppointmentApiResponse =
  /** status 201  */ void | /** status 204 Appointment finished */ void;
export type OnlineVisitAppointmentControllerDoctorFinishAppointmentApiArg =
  OnlineVisitDoctorFinishAppointmentRequestDto;
export type OnlineVisitAppointmentControllerDoctorConnectAppointmentApiResponse =
  /** status 200 List of patient online visit appointments */ OnlineVisitDoctorConnectResponseDto;
export type OnlineVisitAppointmentControllerDoctorConnectAppointmentApiArg =
  /** Internal online visit appointment id */ number;
export type OnlineVisitAppointmentControllerPatientConnectAppointmentApiResponse =
  /** status 200 List of patient online visit appointments */ OnlineVisitPatientConnectResponseDto;
export type OnlineVisitAppointmentControllerPatientConnectAppointmentApiArg =
  /** Internal online visit appointment id */ number;
export type OnlineVisitAppointmentControllerDoctorGetAppointmentInfoForProtocolApiResponse =
  /** status 200 Details of online visit appointment to create protocol */ OnlineVisitDoctorAppointmentDetailsForProtocolResponseDto;
export type OnlineVisitAppointmentControllerDoctorGetAppointmentInfoForProtocolApiArg =
  number;
export type OnlineVisitAppointmentControllerDoctorGetAppointmentInfoForCalendarApiResponse =
  /** status 200 Details of online visit appointment for calendar */ OnlineVisitDoctorAppointmentDetailsForCalendarResponseDto;
export type OnlineVisitAppointmentControllerDoctorGetAppointmentInfoForCalendarApiArg =
  number;
export type OnlineVisitFeedbackControllerAddFeedbackApiResponse =
  /** status 201  */ void | /** status 204 Feedback added */ void;
export type OnlineVisitFeedbackControllerAddFeedbackApiArg =
  OnlineVisitPatientAddFeedbackRequestDto;
export type OnlineVisitPaymentControllerPatientInitiatePaymentApiResponse =
  /** status 201 Init payment response */ OnlineVisitPatientInitiatePaymentResponseDto;
export type OnlineVisitPaymentControllerPatientInitiatePaymentApiArg =
  OnlineVisitPatientInitiatePaymentRequestDto;
export type OnlineVisitPaymentControllerPatientCompletePaymentApiResponse =
  /** status 200 Init payment response */
  | OnlineVisitPatientCompletePaymentResponseDto
  | /** status 201  */ OnlineVisitPatientCompletePaymentResponseDto;
export type OnlineVisitPaymentControllerPatientCompletePaymentApiArg =
  OnlineVisitPatientCompletePaymentRequestDto;
export type DocumentControllerUploadDocumentApiResponse =
  /** status 201  */ DocumentResponse;
export type DocumentControllerUploadDocumentApiArg = UploadDocumentRequest;
export type DocumentControllerUploadDocumentDoctorApiResponse =
  /** status 201  */ DocumentResponse;
export type DocumentControllerUploadDocumentDoctorApiArg = DoctorUploadRequest;
export type DocumentControllerUploadAndParseManagerDocumentApiResponse =
  /** status 201  */ ParsedDocumentResponse;
export type DocumentControllerUploadAndParseManagerDocumentApiArg =
  UploadXlsDocumentRequest;
export type DocumentControllerMedicalRecordsApiResponse =
  /** status 200  */ DocumentGroupListResponse;
export type DocumentControllerMedicalRecordsApiArg = {
  limit?: number;
  offset?: number;
};
export type DocumentControllerMedicalRecordsFilesApiResponse =
  /** status 200  */ DocumentListResponse;
export type DocumentControllerMedicalRecordsFilesApiArg = {
  limit?: number;
  offset?: number;
  medicalRecordId: number;
};
export type DocumentControllerMedicalRecordFileApiResponse =
  /** status 200  */ void;
export type DocumentControllerMedicalRecordFileApiArg = number;
export type DocumentControllerAnalysisResultsApiResponse =
  /** status 200  */ DocumentGroupListResponse;
export type DocumentControllerAnalysisResultsApiArg = {
  limit?: number;
  offset?: number;
};
export type DocumentControllerAnalysisResultForDoctorApiResponse =
  /** status 200  */ DocumentListResponse;
export type DocumentControllerAnalysisResultForDoctorApiArg = {
  userId: number;
  limit?: number;
  offset?: number;
};
export type DocumentControllerAnalysisResultsFilesApiResponse =
  /** status 200  */ DocumentListResponse;
export type DocumentControllerAnalysisResultsFilesApiArg = {
  limit?: number;
  offset?: number;
  analysisId: number;
};
export type DocumentControllerAnalysisResultsFilesRequestApiResponse =
  /** status 201  */ AnalysisFilesRequestResponse;
export type DocumentControllerAnalysisResultsFilesRequestApiArg =
  AnalysisFilesRequestDto;
export type DocumentControllerAnalysisResultApiResponse =
  /** status 200  */ void;
export type DocumentControllerAnalysisResultApiArg = number;
export type DocumentControllerTaxRefundListApiResponse =
  /** status 200  */ DocumentGroupListResponse;
export type DocumentControllerTaxRefundListApiArg = {
  limit?: number;
  offset?: number;
};
export type DocumentControllerTaxRefundFilesListApiResponse =
  /** status 200  */ DocumentListResponse;
export type DocumentControllerTaxRefundFilesListApiArg = {
  limit?: number;
  offset?: number;
  taxRefundId: number;
};
export type DocumentControllerTaxRefundFileApiResponse =
  /** status 200  */ void;
export type DocumentControllerTaxRefundFileApiArg = number;
export type DocumentControllerGetUserChatFilesApiResponse =
  /** status 200  */ DocumentListResponse;
export type DocumentControllerGetUserChatFilesApiArg = {
  limit?: number;
  offset?: number;
  userId: number;
  ids: string[];
};
export type DocumentControllerUserUploadedFilesApiResponse =
  /** status 200  */ DocumentListResponse;
export type DocumentControllerUserUploadedFilesApiArg = {
  limit?: number;
  offset?: number;
};
export type DocumentControllerUserUploadedFilesWithProtocolsApiResponse =
  /** status 200  */ DocumentWithDocumentTypeListResponseDto;
export type DocumentControllerUserUploadedFilesWithProtocolsApiArg = {
  limit?: number;
  offset?: number;
};
export type DocumentControllerUserUploadedFilesByPatientIdApiResponse =
  /** status 200  */ DocumentListResponse;
export type DocumentControllerUserUploadedFilesByPatientIdApiArg = {
  patientId: number;
  limit?: number;
  offset?: number;
};
export type DocumentControllerUserUploadedFileApiResponse =
  /** status 200  */ void;
export type DocumentControllerUserUploadedFileApiArg = number;
export type DocumentControllerDeleteUploadedFileApiResponse =
  /** status 200  */ void;
export type DocumentControllerDeleteUploadedFileApiArg = number;
export type DocumentControllerUserUploadedFileForDoctorApiResponse =
  /** status 200  */ void;
export type DocumentControllerUserUploadedFileForDoctorApiArg = {
  userId: number;
  documentId: number;
};
export type DocumentControllerUserUploadedFileByIdApiResponse =
  /** status 200  */ void;
export type DocumentControllerUserUploadedFileByIdApiArg = number;
export type DocumentControllerDoctorGetPatientProtocolsApiResponse =
  /** status 200  */ ProtocolListResponseDto;
export type DocumentControllerDoctorGetPatientProtocolsApiArg = {
  limit?: number;
  offset?: number;
  userId: number;
};
export type ProtocolsControllerCreateOnlineVisitProtocolApiResponse =
  /** status 200 Protocol created succesfully */
  CreateProtocolResponseDto | /** status 201  */ CreateProtocolResponseDto;
export type ProtocolsControllerCreateOnlineVisitProtocolApiArg =
  CreateProtocolRequestDto;
export type ProtocolsControllerDeleteProtocolApiResponse =
  /** status 201  */
  void | /** status 204 Protocol deleted */ void;
export type ProtocolsControllerDeleteProtocolApiArg =
  DoctorDeleteProtocolRequestDto;
export type ProtocolsControllerGetProtocolApiResponse =
  /** status 200 Online visit protocol */ GetProtocolResponseDto;
export type ProtocolsControllerGetProtocolApiArg = number;
export type ProtocolsControllerUpdateProtocolApiResponse =
  /** status 200 Online visit protocol updated successfully */ UpdateProtocolRequestDto;
export type ProtocolsControllerUpdateProtocolApiArg = {
  id: number;
  updateProtocolRequestDto: UpdateProtocolRequestDto;
};
export type ConversationControllerDoctorConversationListApiResponse =
  /** status 200  */ DoctorConversationListResponseDto;
export type ConversationControllerDoctorConversationListApiArg = void;
export type ConversationControllerPatientConversationListApiResponse =
  /** status 200  */ PatientConversationListResponseDto;
export type ConversationControllerPatientConversationListApiArg = void;
export type ConversationControllerDoctorReadConversationApiResponse =
  /** status 201  */ void | /** status 204 Doctor read conversation */ void;
export type ConversationControllerDoctorReadConversationApiArg =
  DoctorConnectToConversationRequestDto;
export type ConversationControllerPatientReadConversationApiResponse =
  /** status 201  */ void | /** status 204 Patient read conversation */ void;
export type ConversationControllerPatientReadConversationApiArg =
  PatientConnectToConversationRequestDto;
export type ConversationControllerDoctorConnectToConversationApiResponse =
  /** status 200  */ DoctorConnectToConversationResponseDto;
export type ConversationControllerDoctorConnectToConversationApiArg =
  /** Internal online visit appointment id */ number;
export type ConversationControllerPatientConnectToConversationApiResponse =
  /** status 200  */ PatientConnectToConversationResponseDto;
export type ConversationControllerPatientConnectToConversationApiArg =
  /** Internal online visit appointment id */ number;
export type ChatControllerGetUniqueSpecializationsApiResponse =
  /** status 200  */ string[];
export type ChatControllerGetUniqueSpecializationsApiArg = void;
export type ChatControllerGetAssistingDoctorApiResponse =
  /** status 200  */ AssistingDoctorsResponseDto[];
export type ChatControllerGetAssistingDoctorApiArg = any;
export type ChatControllerGetChatDoctorApiResponse =
  /** status 200  */ ChatSupportDoctorListResponseDto;
export type ChatControllerGetChatDoctorApiArg = any;
export type ChatControllerMakeHelpAssistingChatApiResponse =
  /** status 201  */ void;
export type ChatControllerMakeHelpAssistingChatApiArg = HelpAssistingDto;
export type ChatControllerGetPatientChatListApiResponse =
  /** status 200  */ PatientChatListResponseDto;
export type ChatControllerGetPatientChatListApiArg = void;
export type AuthControllerIsUserExistsApiResponse = /** status 200  */ void;
export type AuthControllerIsUserExistsApiArg = UserExistsDto;
export type AuthControllerLoginApiResponse = /** status 200  */ TokensDto;
export type AuthControllerLoginApiArg = LoginDto;
export type AuthControllerChangeAccountApiResponse =
  /** status 200  */ TokensDto;
export type AuthControllerChangeAccountApiArg = number;
export type AuthControllerRefreshTokenApiResponse =
  /** status 200  */ TokensDto;
export type AuthControllerRefreshTokenApiArg = void;
export type AuthControllerLogoutApiResponse = /** status 200  */ void;
export type AuthControllerLogoutApiArg = void;
export type InternalAuthControllerLoginApiResponse =
  /** status 200  */ TokensDto;
export type InternalAuthControllerLoginApiArg = InternalAccountLoginRequest;
export type InternalAuthControllerSetPushTokenApiResponse =
  /** status 201  */ void;
export type InternalAuthControllerSetPushTokenApiArg = SetPushTokenRequest;
export type InternalAuthControllerGetDoctorApiResponse =
  /** status 200  */ InternalAccountResponse;
export type InternalAuthControllerGetDoctorApiArg = void;
export type InternalAuthControllerLogoutApiResponse = /** status 200  */ void;
export type InternalAuthControllerLogoutApiArg = void;
export type InternalAuthControllerSendValidateCodeApiResponse =
  /** status 200  */ void;
export type InternalAuthControllerSendValidateCodeApiArg =
  AppEmailVerifyRequest;
export type InternalAuthControllerSendResetPasswordLinkApiResponse =
  /** status 200  */ void;
export type InternalAuthControllerSendResetPasswordLinkApiArg =
  AppEmailVerifyRequest;
export type InternalAuthControllerChangePasswordViaCodeApiResponse =
  /** status 201  */ void;
export type InternalAuthControllerChangePasswordViaCodeApiArg =
  ChangePasswordViaCodeRequest;
export type InternalAuthControllerVerifyCodeApiResponse =
  /** status 200  */ void;
export type InternalAuthControllerVerifyCodeApiArg = VerifyCodeRequest;
export type InternalAuthControllerResetPasswordApiResponse =
  /** status 201  */ void;
export type InternalAuthControllerResetPasswordApiArg =
  InternalAccountResetPassword;
export type InternalAuthControllerRefreshTokenApiResponse =
  /** status 200  */ TokensDto;
export type InternalAuthControllerRefreshTokenApiArg = void;
export type InternalAuthControllerChangePasswordApiResponse =
  /** status 200  */ void;
export type InternalAuthControllerChangePasswordApiArg =
  InternalAccountChangePasswordForDoctor;
export type CalendarControllerGetEventListApiResponse =
  /** status 200  */ CalendarEventListResponseDto;
export type CalendarControllerGetEventListApiArg = {
  /** ISO date time */
  fromDateTimeIso: string;
  /** ISO date time */
  toDateTimeIso: string;
};
export type HomeVisitControllerFindDoctorsApiResponse =
  /** status 200  */ HomeVisitDoctorsResponseDto;
export type HomeVisitControllerFindDoctorsApiArg = {
  limit?: number;
  offset?: number;
  cityId: number;
  specialization?: string;
};
export type HomeVisitControllerCreateHomeVisitRequestApiResponse =
  /** status 201  */ void;
export type HomeVisitControllerCreateHomeVisitRequestApiArg = CreateRequestDto;
export type HomeVisitControllerCancelHomeVisitRequestApiResponse =
  /** status 201  */ void;
export type HomeVisitControllerCancelHomeVisitRequestApiArg =
  CancelHomeVisitDto;
export type HomeVisitControllerUpcomingHomeVisitApiResponse =
  /** status 200  */ PatientVisitListResponse;
export type HomeVisitControllerUpcomingHomeVisitApiArg = void;
export type HomeVisitControllerPastHomeVisitApiResponse =
  /** status 200  */ PatientVisitListResponse;
export type HomeVisitControllerPastHomeVisitApiArg = void;
export type HomeVisitControllerGetDoctorUpcomingHomeVisitsApiResponse =
  /** status 200  */ DoctorUpcomingHomeVisitsResponse[];
export type HomeVisitControllerGetDoctorUpcomingHomeVisitsApiArg = string;
export type HomeVisitControllerFinishHomeVisitApiResponse =
  /** status 201  */ void;
export type HomeVisitControllerFinishHomeVisitApiArg = FinishHomeVisitDto;
export type HomeVisitControllerGetDoctorPastHomeVisitsApiResponse =
  /** status 200  */ void;
export type HomeVisitControllerGetDoctorPastHomeVisitsApiArg = {
  limit?: number;
  offset?: number;
  patientName?: string;
  patientId?: number;
};
export type HomeVisitControllerGetHomeVisitApiResponse =
  /** status 200  */ DoctorUpcomingHomeVisitsResponse;
export type HomeVisitControllerGetHomeVisitApiArg = number;
export type AdminDoctorsControllerDoctorsListApiResponse =
  /** status 200  */ AdminDoctorListResponse;
export type AdminDoctorsControllerDoctorsListApiArg = {
  limit?: number;
  offset?: number;
  searchLine: string;
};
export type AdminDoctorsControllerDoctorApiResponse =
  /** status 200  */ AdminDoctorResponse;
export type AdminDoctorsControllerDoctorApiArg = number;
export type AdminDoctorsControllerAddAccountApiResponse =
  /** status 201  */ void;
export type AdminDoctorsControllerAddAccountApiArg = {
  id: number;
  addDoctorAccountRequest: AddDoctorAccountRequest;
};
export type AdminDoctorsControllerUpdateAccountApiResponse =
  /** status 200  */ void;
export type AdminDoctorsControllerUpdateAccountApiArg = {
  id: number;
  updateDoctorAccountRequest: UpdateDoctorAccountRequest;
};
export type AdminDoctorsControllerDeleteAccountApiResponse =
  /** status 200  */ void;
export type AdminDoctorsControllerDeleteAccountApiArg = number;
export type AdminVisitsControllerGetHomeVisitsApiResponse =
  /** status 200  */ HomeVisitListResponse;
export type AdminVisitsControllerGetHomeVisitsApiArg = {
  limit?: number;
  offset?: number;
  name?: string;
  date?: string;
  statuses?: (
    | "New"
    | "InProgressByCurrentAdmin"
    | "AcceptByDoctor"
    | "AcceptByAdmin"
    | "InProgressByAnotherAdmin"
    | "Finished"
  )[];
};
export type AdminVisitsControllerGetPastHomeVisitsApiResponse =
  /** status 200  */ PastHomeVisitListResponse;
export type AdminVisitsControllerGetPastHomeVisitsApiArg = {
  limit?: number;
  offset?: number;
};
export type AdminVisitsControllerGetPastHomeVisitApiResponse =
  /** status 200  */ PastHomeVisitFormResponse;
export type AdminVisitsControllerGetPastHomeVisitApiArg = number;
export type AdminVisitsControllerGetChatAssistingApiResponse =
  /** status 200  */ ChatVisitListResponse;
export type AdminVisitsControllerGetChatAssistingApiArg = {
  limit?: number;
  offset?: number;
  name?: string;
  date?: string;
  statuses?: (
    | "New"
    | "InProgressByCurrentAdmin"
    | "AcceptByAdmin"
    | "InProgressByAnotherAdmin"
  )[];
};
export type AdminVisitsControllerGetOneChatAssistingVisitApiResponse =
  /** status 200  */ ChatVisitResponse;
export type AdminVisitsControllerGetOneChatAssistingVisitApiArg = string;
export type AdminVisitsControllerGetOneHomeVisitApiResponse =
  /** status 200  */ HomeVisitFormResponse;
export type AdminVisitsControllerGetOneHomeVisitApiArg = number;
export type AdminVisitsControllerSetHomeVisitInProgressApiResponse =
  /** status 201  */ void;
export type AdminVisitsControllerSetHomeVisitInProgressApiArg =
  HomeVisitIdRequest;
export type AdminVisitsControllerSetChatAssistingInProgressApiResponse =
  /** status 201  */ void;
export type AdminVisitsControllerSetChatAssistingInProgressApiArg =
  ChatAssistingRequest;
export type AdminVisitsControllerAcceptHomeVisitApiResponse =
  /** status 202  */ void;
export type AdminVisitsControllerAcceptHomeVisitApiArg =
  AcceptWithEditHomeVisitRequest;
export type AdminVisitsControllerAcceptChatAssistingApiResponse =
  /** status 202  */ void;
export type AdminVisitsControllerAcceptChatAssistingApiArg =
  AcceptChatAssistingWithEdit;
export type AdminVisitsControllerRejectChatAssistingVisitApiResponse =
  /** status 202  */ void;
export type AdminVisitsControllerRejectChatAssistingVisitApiArg =
  ChatAssistingRequest;
export type AdminVisitsControllerRejectHomeVisitApiResponse =
  /** status 202  */ void;
export type AdminVisitsControllerRejectHomeVisitApiArg = HomeVisitIdRequest;
export type AdminVisitsControllerViewRejectByPatientApiResponse =
  /** status 200  */ HomeVisitRefuseListResponse;
export type AdminVisitsControllerViewRejectByPatientApiArg = {
  limit?: number;
  offset?: number;
  statuses?: (
    | "New"
    | "InProgressByCurrentAdmin"
    | "InProgressByAnotherAdmin"
  )[];
};
export type AdminVisitsControllerViewRejectByPatientByIdApiResponse =
  /** status 200  */ HomeVisitRefuseResponse;
export type AdminVisitsControllerViewRejectByPatientByIdApiArg = number;
export type DoctorPanelControllerAnalysisResultForDoctorWebApiResponse =
  /** status 200  */ DocumentListResponse;
export type DoctorPanelControllerAnalysisResultForDoctorWebApiArg = {
  userId: number;
  limit?: number;
  offset?: number;
};
export type DoctorPanelControllerDownloadUseFileApiResponse =
  /** status 200  */ void;
export type DoctorPanelControllerDownloadUseFileApiArg = {
  userId: number;
  fileId: number;
};
export type DoctorPanelControllerAnalysisResultApiResponse =
  /** status 200  */ void;
export type DoctorPanelControllerAnalysisResultApiArg = {
  userId: number;
  fileId: number;
};
export type DoctorPanelControllerUploadDocumentDoctorApiResponse =
  /** status 201  */ DocumentResponse;
export type DoctorPanelControllerUploadDocumentDoctorApiArg =
  DoctorUploadRequest;
export type DoctorPanelControllerUploadConversationDocumentApiResponse =
  /** status 201  */ DocumentResponse;
export type DoctorPanelControllerUploadConversationDocumentApiArg =
  ConversationUploadDocumentRequest;
export type DoctorPanelControllerPatientApiResponse =
  /** status 200  */ DoctorPastHomeVisitRequestDto;
export type DoctorPanelControllerPatientApiArg = {
  limit?: number;
  offset?: number;
  patientName?: string;
  patientId?: number;
};
export type DoctorPanelControllerPatientsApiResponse =
  /** status 200  */ DoctorPastHomeVisitWithDocumentsRequestDto[];
export type DoctorPanelControllerPatientsApiArg = {
  limit?: number;
  offset?: number;
  patientName?: string;
  patientId?: number;
};
export type DoctorPanelControllerPatientFilesApiResponse =
  /** status 200  */ DocumentListResponse;
export type DoctorPanelControllerPatientFilesApiArg = {
  limit?: number;
  offset?: number;
  userId: number;
  ids: string[];
};
export type DoctorPanelControllerChatMembersApiResponse =
  /** status 200  */ ChatMemberResponse[];
export type DoctorPanelControllerChatMembersApiArg = void;
export type DoctorPanelControllerReadChatApiResponse = /** status 200  */ void;
export type DoctorPanelControllerReadChatApiArg = ChatReadRequest;
export type OptionsControllerGetOptionsApiResponse =
  /** status 200  */ OptionListResponse;
export type OptionsControllerGetOptionsApiArg = OptionKeyEnum[];
export type MarketingMailingControllerCreateMarketingMailingWithIdsApiResponse =
  /** status 201 Marketing mailing created */ CreateMarketingMailingByIdsRequestDto;
export type MarketingMailingControllerCreateMarketingMailingWithIdsApiArg =
  CreateMarketingMailingByIdsRequestDto;
export type MarketingMailingControllerCreateMarketingMailingWithFiltersApiResponse =
  /** status 201 Marketing mailing created */ CreateMarketingMailingByFiltersRequestDto;
export type MarketingMailingControllerCreateMarketingMailingWithFiltersApiArg =
  CreateMarketingMailingByFiltersRequestDto;
export type MarketingMailingControllerFindAllApiResponse =
  /** status 200 Marketing mailings list */ MarketingMailingListResponse;
export type MarketingMailingControllerFindAllApiArg = {
  limit?: number;
  offset?: number;
  searchLine?: string;
};
export type MarketingMailingControllerDeleteMarketingMailingApiResponse =
  /** status 200  */
  void | /** status 204 Marketing mailing deleted Successfully */ void;
export type MarketingMailingControllerDeleteMarketingMailingApiArg = number;
export type MarketingMailingControllerFindOneApiResponse =
  /** status 200 Marketing mailing */ MarketingMailingFindOneResponseDto;
export type MarketingMailingControllerFindOneApiArg = number;
export type MarketingMailingControllerUpdateMarketingMailingByFiltersApiResponse =
  /** status 200 Marketing mailing updated successfully */ UpdateMarketingMailingByFiltersRequestDto;
export type MarketingMailingControllerUpdateMarketingMailingByFiltersApiArg = {
  id: number;
  updateMarketingMailingByFiltersRequestDto: UpdateMarketingMailingByFiltersRequestDto;
};
export type MarketingMailingControllerUpdateMarketingMailingByIdsApiResponse =
  /** status 200 Marketing mailing updated successfully */ UpdateMarketingMailingByIdsRequestDto;
export type MarketingMailingControllerUpdateMarketingMailingByIdsApiArg = {
  id: number;
  updateMarketingMailingByIdsRequestDto: UpdateMarketingMailingByIdsRequestDto;
};
export type DoctorNotificationResponseDto = {
  id: number;
  dateISO: string;
  eventType: NotificationCase;
  isViewed: boolean;
  message: string;
  body: string | null;
  homeVisitId: number | null;
  internalChatId: string | null;
  internalExtraFacilitiesId: number | null;
  internalOnlineAppointmentId: number | null;
};
export type UnAuthExceptionResponse = {
  statusCode: number;
  message: string;
};
export type NotificationsViewRequest = {
  notificationsIds: string[];
};
export type NotificationIdDto = {
  notificationId: number;
};
export type RejectByHomeVisitFormIdDto = {
  homeVisitFormId: number;
};
export type AcceptRequest = {
  notificationId: number;
  callTime: string;
};
export type AcceptByFormIdRequest = {
  formId: number;
  callTime: string;
};
export type AdminNotificationResponseDto = {
  id: number;
  createdAtDateTimeIso: string;
  isViewed: boolean;
  adminId: number;
  entityId: number | null;
  healthAssistanceType: HealthAssistanceType;
  eventType: NotificationCase;
  message: string;
  internalOnlineAppointmentId: number | null;
};
export type PaymentResponse = {
  id: string;
  paymentMethodType: PaymentMethodType;
  confirmationLink: string | null;
};
export type PaymentHomeVisitRequest = {
  paymentToken: string;
  shopId: string;
  description: string;
  email: string;
  id: number;
};
export type PaymentChatAssistingRequest = {
  paymentToken: string;
  shopId: string;
  description: string;
  email: string;
  id: string;
};
export type DoctorInResponse = {
  id: number;
  image: string;
  name: string;
  showRecordButton: boolean;
  isMultiplePrices: boolean;
  cost: string | null;
  specialityPreview: string | null;
};
export type DoctorListResponse = {
  data: DoctorInResponse[];
  total: number;
  clinicId: number | null;
  specialityList: string[];
};
export type SpecializationsListResponse = {
  data: string[];
};
export type FieldWithDate = {
  name: string;
  date: string | null;
};
export type ClinicInResponse = {
  id: number;
  name: string;
  address: string | null;
};
export type DoctorResponse = {
  id: number;
  image: string | null;
  name: string;
  isMultiplePrices: boolean;
  showRecordButton: boolean;
  firstCost: string | null;
  secondCost: string | null;
  specialityPreview: string | null;
  speciality: string[] | null;
  education: FieldWithDate[] | null;
  experience: FieldWithDate[] | null;
  skills: string[] | null;
  clinics: ClinicInResponse[] | null;
};
export type NotFoundExceptionResponse = {
  statusCode: number;
  message: string;
  messageText: string;
  errors: string[];
};
export type RecordResultResponse = {
  id: number;
};
export type HomeVisitRequest = {
  name: string;
  phoneNumber: string;
  address: string;
  clinicId?: number;
  comment?: string;
  cityId: number;
};
export type HomeVisitFormResponse = {
  id: number;
  cityId: number;
  externalId: number;
  patientName: string;
  patientAddress: string;
  patientPhone: string;
  specialization: string;
  callDate: string;
  callTime: string;
  doctorName: string | null;
  cost: number;
  payMethod: string;
  isPaid: boolean;
  status: AdminHomeVisitStatusEnum;
  comment: string;
  tags: string[];
  isAccepted: boolean;
  doctorId: number;
};
export type HomeVisitListResponse = {
  data: HomeVisitFormResponse[];
  total: number;
};
export type HomeVisitResponse = {
  id: number;
  serviceName: string | null;
  dateDep: string | null;
  isPaid: boolean;
  paymentStatus: PaymentStatus;
  cost: number | null;
};
export type TaxRefundRequest = {
  patientName: string;
  taxpayerName: string;
  INN: number;
  cityId: number;
  years: string;
  phoneNumber: string;
  passportPhoto: Blob;
  comment?: string;
};
export type UserResponse = {
  id: number;
  firstName: string;
  phoneNumber: string;
  isChild: boolean;
};
export type AccountInResponse = {
  id: number;
  name: string;
  isChild: boolean;
  phoneNumber: string;
};
export type AccountResponse = {
  data: AccountInResponse[];
  total: number;
};
export type SetPushTokenRequest = {
  token: string;
};
export type UserFeedback = {
  userId: number;
  visitId: number;
  cityId: number;
  comment: string;
  stars: number;
};
export type OnlineVisitDoctorAddSlotResponseDto = {
  id: number;
  startDateTimeIso: string;
  endDateTimeIso: string;
  durationMinutes: number;
  internalDoctorId: number;
};
export type ValidationExceptionResponse = {
  statusCode: number;
  message: string;
  messageText: string;
  errors: string[];
};
export type OnlineVisitAddSlotRequestDto = {
  /** start date time of slot */
  startIsoDateTime: string;
};
export type OnlineVisitDoctorDeleteSlotRequestDto = {
  /** Internal online visit slot id */
  slotId: number;
};
export type SlotListResponseDtoSlot = {
  internalOnlineSlotId: number;
  startDateTimeIso: string;
  endDateTimeIso: string;
  durationMinutes: number;
  isAvailable: boolean;
};
export type OnlineVisitDoctorSlotListResponseDtoData = {
  /** Internal online visit doctor id */
  internalOnlineVisitDoctorId: number;
  /** shop id */
  shopId: string;
  /** Doctor full name */
  fullName: string;
  /** Internal online visit doctor specialization */
  onlineVisitSpecialization: string | null;
  /** Appointment price */
  price: number | null;
  /** Doctor image URL */
  imageUrl: string | null;
  /** Online visit slots */
  slots: SlotListResponseDtoSlot[];
};
export type OnlineVisitGetSlotListResponseDto = {
  data: OnlineVisitDoctorSlotListResponseDtoData[];
  total: number;
};
export type OnlineVisitSpecializationListResponseDto = {
  data: string[][];
  total: number;
};
export type OnlineVisitPatientCreateAppointmentResponseDto = {
  internalOnlineAppointmentId: number;
};
export type OnlineVisitPatientCreateAppointmentRequestDto = {
  /** Internal online visit slot id */
  internalOnlineVisitSlotId?: number;
  /** Internal online visit doctor id */
  internalOnlineVisitDoctorId: number;
  /** Patient name */
  patientName?: string;
  /** Patient phone number */
  patientPhoneNumber?: string;
  /** Start date time */
  startDateTimeIso: string;
  paymentMethod: OnlineAppointmentPaymentMethodEnum;
  /** Book appointment for someone else */
  appointmentForSomeoneElse?: object;
};
export type OnlineVisitAdminCancelAppointmentRequestDto = {
  /** Internal online visit appointment id */
  internalOnlineVisitAppointmentId: number;
};
export type OnlineVisitAdminAssignAppointmentRequestDto = {
  /** Internal online visit appointment id */
  internalOnlineVisitAppointmentId: number;
};
export type OnlineVisitAdminAcceptAppointmentRequestDto = {
  /** Internal online visit appointment id */
  internalOnlineVisitAppointmentId: number;
  /** Internal online visit doctor id */
  internalOnlineVisitDoctorId: number;
  /** Internal online visit slot id */
  internalOnlineVisitSlotId: number;
};
export type OnlineVisitAdminEditAppointmentRequestDto = {
  /** Internal online visit appointment id */
  internalOnlineVisitAppointmentId: number;
  /** Internal online visit doctor id */
  internalOnlineVisitDoctorId: number;
  /** Internal online visit slot id */
  internalOnlineVisitSlotId: number;
};
export type OnlineVisitPatientCreateCancellationRequestDto = {
  /** Internal online visit appointment id */
  internalOnlineVisitAppointmentId: number;
  /** Reason for canceling the appointment */
  patientCancelReason?: string | null;
};
export type OnlineVisitPatientAppointmentProtocolResponseDto = {
  /** Protocol id */
  internalProtocolId: number;
  /** Protocol date time in ISO format */
  dateTimeIso: string;
  /** Protocol file URL */
  fileUrl: string;
  /** Protocol file name */
  fileName: string;
};
export type OnlineVisitPatientAppointmentResponseDto = {
  /** Online visit appointment id */
  onlineVisitAppointmentId: number;
  /** Internal online visit doctor id */
  internalOnlineVisitDoctorId: number;
  /** Doctor full name */
  doctorFullName: string;
  /** Doctor avatar URL */
  doctorAvatarUrl: string;
  /** Doctor specialization for online appointment */
  doctorOnlineVisitSpecialization: string;
  /** Service name for online appointment */
  onlineAppointmentService: string;
  /** Appointment price */
  onlineAppointmentPrice: number;
  /** Doctor rating */
  startDateTimeIso: string;
  status: OnlineVisitAppointmentForPatientStatusEnum;
  /** Start date time in ISO format */
  isPaid: boolean;
  /** Shop id */
  shopId: string;
  /** Protocol list */
  protocols: OnlineVisitPatientAppointmentProtocolResponseDto[];
};
export type OnlineVisitPatientGetAppointmentListResponseDto = {
  data: OnlineVisitPatientAppointmentResponseDto[];
  total: number;
};
export type OnlineVisitAdminAppointmentResponseDto = {
  /** Internal online visit appointment id */
  internalOnlineVisitAppointmentId: number;
  /** Internal online visit doctor id */
  internalOnlineVisitDoctorId: number;
  /** Internal online visit slot id for main doctor */
  mainDoctorInternalOnlineVisitSlotId: number | null;
  /** Doctor full name */
  doctorFullName: string;
  /** Doctor specialization for online appointment */
  doctorOnlineVisitSpecialization: string;
  /** Patient full name */
  patientFullName: string;
  /** Patient phone */
  patientPhone: string;
  /** Appointment price */
  onlineAppointmentPrice: number;
  /** Appointment start date time in ISO format */
  startDateTimeIso: string;
  /** Appointment end date time in ISO format */
  endDateTimeIso: string;
  /** Appointment duration in minutes */
  durationInMinutes: number;
  /** User feedback */
  patientFeedbackReview: string | null;
  /** Doctor feedback */
  patientFeedbackRating: number | null;
  /** Patient cancel reason */
  patientCancelReason: string | null;
  status: OnlineVisitForAdminStatusEnum;
  /** Can be cancelled */
  canBeCancelled: boolean;
  /** Can be accepted */
  canBeAccepted: boolean;
  /** Can be rejected */
  canBeRejected: boolean;
  /** Can be assigned */
  canBeAssigned: boolean;
  /** Can be processed */
  canBeProcessed: boolean;
};
export type OnlineVisitAdminAppointmentListResponseDto = {
  data: OnlineVisitAdminAppointmentResponseDto[];
  total: number;
};
export type OnlineVisitDoctorAddSpecialistToAppointmentRequestDto = {
  /** Internal online visit appointment id */
  internalOnlineVisitAppointmentId: number;
  /** Internal online visit doctor id */
  internalOnlineVisitDoctorId: number;
};
export type OnlineVisitDoctorRemoveSpecialistFromAppointmentRequestDto = {
  /** Internal online visit appointment id */
  internalOnlineVisitAppointmentId: number;
  /** Internal online visit doctor id */
  internalOnlineVisitDoctorId: number;
};
export type OnlineVisitDoctorFinishAppointmentRequestDto = {
  /** Internal online visit appointment id */
  internalOnlineVisitAppointmentId: number;
};
export type OnlineVisitDoctorConnectResponseDto = {
  internalConversationId: number;
  dailyChannel: string;
  isMainDoctor: boolean;
  appointmentIsoStartTime: string | null;
  doctorName: string;
};
export type OnlineVisitPatientConnectResponseDto = {
  internalConversationId: number;
  dailyChannel: string;
};
export type OnlineVisitDoctorAppointmentDetailsForProtocolResponseDto = {
  /** Internal online visit appointment id */
  internalOnlineVisitAppointmentId: number;
  /** Internal online visit start date & time */
  startIsoDateTime: string;
  /** Doctor full name */
  doctorFullName: string | null;
  /** Specialist full name */
  specialistFullName: string | null;
  /** Patient full name */
  patientFullName: string;
  /** Patient birth date */
  patientBirthDate: string | null;
};
export type DocumentResponseDto = {
  /** Unique document identifier */
  id: number;
  /** Internal protocol id */
  internalProtocolId: number;
  /** The name of the document */
  fileName: string;
  /** Whether the document is created by the current doctor */
  isOwner: boolean;
};
export type OnlineVisitDoctorAppointmentDetailsForCalendarResponseDto = {
  /** Internal online visit appointment id */
  internalOnlineVisitAppointmentId: number;
  /** Internal online visit start date & time */
  startDateTimeIso: string;
  /** Internal online visit end date & time */
  endDateTimeIso: string;
  /** Doctor full name */
  doctorFullName: string | null;
  /** Specialist full name */
  specialistFullName: string | null;
  /** Patient full name */
  patientFullName: string;
  /** Internal patient id */
  internalPatientId: number;
  /** Online visit chat pubnub channel name */
  pubnubChannelName: string | null;
  /** Whether the current doctor can add protocol */
  canAddProtocol: boolean;
  /** Whether the current doctor can connect to visit */
  canConnect: boolean;
  /** Protocols for this appointment */
  documents: DocumentResponseDto[] | null;
};
export type OnlineVisitPatientAddFeedbackRequestDto = {
  /** Internal online visit appointment id */
  internalOnlineVisitAppointmentId: number;
  /** User feedback */
  patientFeedbackReview?: string;
  /** Doctor feedback */
  patientFeedbackRating: number | null;
};
export type OnlineVisitPatientInitiatePaymentResponseDto = {
  internalPaymentId: number;
  confirmationLink: string;
};
export type OnlineVisitPatientInitiatePaymentRequestDto = {
  internalOnlineVisitId: number;
  paymentToken: string;
  shopId: string;
  email: string;
};
export type OnlineVisitPatientCompletePaymentResponseDto = {
  internalPaymentId: number;
};
export type OnlineVisitPatientCompletePaymentRequestDto = {
  internalPaymentId: number;
};
export type DocumentResponse = {
  id: number;
  name: string;
  createdAt: string;
  extension: Extension;
  preview: string | null;
};
export type UploadDocumentRequest = {
  document: Blob;
  chatId?: string;
};
export type DoctorUploadRequest = {
  document: Blob;
  chatId?: string;
  userId: number;
};
export type ParsedDocumentResponse = {
  ids: number[];
  name: string;
};
export type UploadXlsDocumentRequest = FormData;
export type DocumentGroup = {
  id: number;
  name: string;
  createdAt: string;
};
export type DocumentGroupListResponse = {
  data: DocumentGroup[];
  total: number;
};
export type DocumentListResponse = {
  data: DocumentResponse[];
  status: Status;
  total: number;
};
export type AnalysisFilesRequestResponse = {
  status: Status;
};
export type AnalysisFilesRequestDto = {
  id: number;
};
export type DocumentWithDocumentTypeResponseDto = {
  aggregatedDocumentId: number;
  fileName: string;
  fileExtension: DocumentExtension;
  preview?: string | null;
  documentType: DocumentTypeEnum;
};
export type DocumentWithDocumentTypeListResponseDto = {
  data: DocumentWithDocumentTypeResponseDto[];
  total: number;
};
export type ProtocolResponseDto = {
  aggregatedDocumentId: number;
  fileName: string;
  createdAt: string;
  fileExtension: FileExtension;
  isOwnDocument: boolean;
  preview?: string | null;
  internalProtocolId: number;
};
export type ProtocolListResponseDto = {
  data: ProtocolResponseDto[];
  total: number;
};
export type CreateProtocolResponseDto = {
  /** Protocols id */
  id: number;
  type: ProtocolsTypeEnum;
  /** Aggregated pdf file id */
  aggregatedMotconsuFileId: number;
  /** Internal online_visit_appointments_online_visit_doctor_id */
  internalOnlineVisitAppointmentInternalOnlineVisitDoctorId: number;
};
export type CreateProtocolRequestDto = {
  /** Patient birth date in YYYY-MM-DD format */
  patientBirthDate: string;
  /** Patients complaints */
  complaints: string;
  /** Patient anamnesis */
  anamnesis: string;
  /** Doctors conclusion */
  conclusion: string;
  /** Doctors recommendations */
  recommendations: string;
  /** Doctors referral */
  referral: string;
  /** Services rendered by the doctor */
  services: string;
  /** Internal online_visit_appointment_id */
  internalOnlineVisitAppointmentId: number;
  document: Blob;
};
export type DoctorDeleteProtocolRequestDto = {
  /** Internal online visit protocol id */
  protocolId: number;
};
export type GetProtocolResponseDto = {
  /** Internal protocol id */
  internalProtocolId: number;
  /** Main doctor */
  doctor: string | null;
  /** Specialist */
  specialist: string | null;
  /** Patient name */
  patientName: string;
  /** Online visit start date time */
  startDateTimeIso: string;
  /** Patient birth date */
  patientBirthDate: string;
  /** Patients complaints */
  complaints: string;
  /** Patient anamnesis */
  anamnesis: string;
  /** Doctors conclusion */
  conclusion: string;
  /** Doctors recommendations */
  recommendations: string;
  /** Doctors referral */
  referral: string;
  /** Services rendered by the doctor */
  services: string;
  /** Internal online_visit_appointment_id */
  internalOnlineVisitAppointmentId: number;
};
export type UpdateProtocolRequestDto = {
  /** Patient birth date in YYYY-MM-DD format */
  patientBirthDate: string;
  /** Patients complaints */
  complaints: string;
  /** Patient anamnesis */
  anamnesis: string;
  /** Doctors conclusion */
  conclusion: string;
  /** Doctors recommendations */
  recommendations: string;
  /** Doctors referral */
  referral: string;
  /** Services rendered by the doctor */
  services: string;
  /** Internal online_visit_appointment_id */
  internalOnlineVisitAppointmentId: number;
  document: Blob;
};
export type DoctorConversationListResponseDtoData = {
  /** Internal conversation id */
  internalConversationId: number;
  /** PubNub channel */
  pubnubChannelName: string;
  /** Conversation name */
  conversationName: string;
  conversationType: ConversationTypeEnum;
  /** Last message date time */
  lastReadDateTimeIso: string | null;
};
export type DoctorConversationListResponseDto = {
  data: DoctorConversationListResponseDtoData[];
  total: number;
};
export type PatientConversationListResponseDtoData = {
  /** Internal conversation id */
  internalConversationId: number;
  /** Conversation name */
  conversationName: string;
  conversationType: ConversationTypeEnum;
  /** Last message date time */
  lastReadDateTimeIso: string | null;
};
export type PatientConversationListResponseDto = {
  data: PatientConversationListResponseDtoData[];
  total: number;
};
export type DoctorConnectToConversationRequestDto = {
  /** Internal online visit appointment id */
  internalConversationId: number;
};
export type PatientConnectToConversationRequestDto = {
  /** Internal online visit appointment id */
  internalConversationId: number;
};
export type DoctorConnectToConversationResponseParticipant = {
  /** Internal conversation id */
  internalParticipantId: number;
  /** Conversation name */
  participantPubnubUuid: string;
  /** Is you */
  isCurrentUser: boolean;
  /** Avatar */
  avatar: string | null;
};
export type DoctorConnectToConversationResponseDto = {
  /** Internal conversation id */
  internalConversationId: number;
  /** Conversation name */
  conversationName: string;
  /** PubNub channel name */
  pubnubChannelName: string;
  participants: DoctorConnectToConversationResponseParticipant[];
};
export type PatientConnectToConversationResponseParticipant = {
  /** Internal conversation id */
  internalParticipantId: number;
  /** Conversation name */
  participantPubnubUuid: string;
  /** Is you */
  isCurrentUser: boolean;
  /** Avatar */
  avatar: string | null;
};
export type PatientConnectToConversationResponseDto = {
  /** Internal conversation id */
  internalConversationId: number;
  /** Conversation name */
  conversationName: string;
  participants: PatientConnectToConversationResponseParticipant[];
};
export type AssistingDoctorsResponseDto = {
  specialization: string;
  name: string;
  gatewayId: string;
  doctorId: number;
  cost: number;
  image: string;
  shopId: string;
};
export type ChatSupportDoctorListResponseDtoData = {
  specialization: string;
  name: string;
  gatewayId: string;
  aggregatedDbDoctorId: number;
  internalDoctorId: number;
  internalChatSupportDoctorId: number;
  cost: number;
  image: string;
  shopId: string;
};
export type ChatSupportDoctorListResponseDto = {
  data: ChatSupportDoctorListResponseDtoData[];
  total: number;
};
export type HelpAssistingDto = {
  doctorId: number;
  cityId: number;
  duration: Duration;
  doctorSpeciality: string;
};
export type PatientChatResponseDtoData = {
  internalChatId: string;
  chatNamePatient: string;
  chatType: ChatTypeEnum;
  isActive: boolean;
  aggregatedDbDoctorGatewayId: string;
  doctorName: string;
  doctorImage: string;
  doctorSpeciality: string;
  approvedByAdmin: boolean;
  paymentDateTimeIso: string;
  shopId: string;
  cost: number;
  duration: PatientChatDurationEnum;
  isPayment: boolean;
};
export type PatientChatListResponseDto = {
  data: PatientChatResponseDtoData[];
  total: number;
};
export type UserExistsDto = {
  phoneNumber: string;
  cityId: number;
};
export type TokensDto = {
  accessToken: string;
  refreshToken: string;
};
export type LoginDto = {
  phoneNumber: string;
  code: string;
  cityId: number;
};
export type InternalAccountLoginRequest = {
  email: string;
  password: string;
};
export type InternalAccountDoctorResponse = {
  canCreateOnlineVisitSlot: boolean;
  gatewayId: string;
};
export type InternalAccountResponse = {
  id: number;
  email: string;
  doctorAccount: InternalAccountDoctorResponse | null;
  name: string;
  avatar?: string;
  description: string;
  role: UserRoleEnum;
  pushToken?: string;
};
export type AppEmailVerifyRequest = {
  email: string;
};
export type ChangePasswordViaCodeRequest = {
  code: string;
  email: string;
  password: string;
};
export type VerifyCodeRequest = {
  code: number;
  email: string;
};
export type InternalAccountResetPassword = {
  password: string;
  confirmPassword: string;
  email: string;
};
export type InternalAccountChangePasswordForDoctor = {
  oldPassword: string;
  password: string;
  confirmPassword: string;
};
export type SlotResponseDto = {
  id: number;
  startIsoDateTime: string;
  endIsoDateTime: string;
  durationMinutes: number;
};
export type HomeVisitAppointmentResponseDto = {
  id: number;
  callTime: string;
  callIsoDate: string;
  patientId: number;
  patientName: string;
  doctorId: number;
  doctorName: string;
  documents: DocumentResponseDto[];
  canAddProtocol: boolean;
};
export type OnlineVisitAppointmentResponseDto = {
  id: number;
  startIsoDateTime: string;
  endIsoDateTime: string;
  patientId: number;
  patientName: string;
  mainDoctorId: number | null;
  mainDoctorName: string | null;
  specialistId: number | null;
  specialistName: string | null;
  documents: DocumentResponseDto[];
  canAddProtocol: boolean;
  canAddSpecialist: boolean;
  canUseChat: boolean;
  canConnect: boolean;
};
export type ClinicDoctorAppointmentResponseDto = {
  id: number;
  recordDate: string;
};
export type CalendarEventListResponseDto = {
  onlineVisitSlots: SlotResponseDto[];
  homeVisits: HomeVisitAppointmentResponseDto[];
  onlineVisits: OnlineVisitAppointmentResponseDto[];
  clinicDoctorAppointments: ClinicDoctorAppointmentResponseDto[];
};
export type HomeVisitDoctorsResponseDtoDataPrice = {
  price: number;
  mediumPrice: number;
  largePrice: number;
};
export type HomeVisitDoctorsResponseDtoData = {
  cityId: number;
  clinicId: number;
  cost: HomeVisitDoctorsResponseDtoDataPrice;
  doctorName: string;
  gatewayId: string;
  homeVisitSpecialization: string;
  doctorId: number;
  dbExternalId: number;
  image: string;
  shopId: string;
};
export type HomeVisitDoctorsResponseDto = {
  data: HomeVisitDoctorsResponseDtoData[];
  total: number;
};
export type CreateRequestDto = {
  cityId: number;
  doctorSpeciality: string;
  patientName: string;
  patientPhone: string;
  gatewayId: string;
  patientAddress: string;
  callDate: string;
  payMethod: PayMethod;
  zone: number;
  sourceId: number;
  cost: number;
  patientId: number;
  /** Symptom tags */
  tags: string[];
  comment: string;
};
export type CancelHomeVisitDto = {
  id: number;
  cancelComment: string;
};
export type SharedHomeVisitResponse = {
  id: number;
  cityId: number;
  externalId: number;
  patientName: string;
  patientAddress: string;
  patientPhone: string;
  specialization: string;
  callDate: string;
  callTime: string;
  doctorName: string | null;
  cost: number;
};
export type HomeVisitPrice = {
  price: number;
  mediumPrice: number;
  largePrice: number;
};
export type HomeVisitDoctorResponse = {
  cityId: number;
  clinicId: number;
  cost: HomeVisitPrice;
  doctorName: string;
  gatewayId: string;
  homeVisitSpecialization: string;
  doctorId: number;
  dbExternalId: number;
  image: string;
};
export type PatientVisitResponse = {
  shopId: string;
  id: number;
  patientId: number;
  payment: Payment;
  orderPay: string;
  status: number;
  homeVisit: SharedHomeVisitResponse;
  homeVisitDoctor: HomeVisitDoctorResponse;
  chatId: object | null;
};
export type PatientVisitListResponse = {
  data: PatientVisitResponse[];
};
export type DoctorUpcomingHomeVisitsResponse = {
  homeVisitId: number;
  patientName: string;
  patientPhone: string;
  chatId?: string;
  patientId: number;
  callDate: string;
  id: number;
  patientAddress: string;
  callTime: string;
  tags: string[];
  comment: string;
  cost: number;
  externalId: number;
  cityId: number;
  availabilityForDoctor: AvailabilityForDoctor;
};
export type FinishHomeVisitDto = {
  homeVisitId: number;
};
export type DoctorAccount = {
  email: string;
};
export type AdminDoctorResponse = {
  id: number;
  image: string;
  name: string;
  specialization: string;
  account: DoctorAccount;
};
export type AdminDoctorListResponse = {
  data: AdminDoctorResponse[];
  total: number;
};
export type AddDoctorAccountRequest = {
  email: string;
  password: string;
};
export type UpdateDoctorAccountRequest = {
  email: string;
};
export type PastHomeVisitFormResponse = {
  id: number;
  cityId: number;
  externalId: number;
  patientName: string;
  patientAddress: string;
  patientPhone: string;
  specialization: string;
  callDate: string;
  callTime: string;
  doctorName: string | null;
  cost: number;
  payMethod: string;
  isPaid: boolean;
  status: AdminHomeVisitStatusEnum;
  comment: string;
  tags: string[];
  isAccepted: boolean;
  doctorId: number;
  extraFacilities: string[];
  rating: number | null;
  review: string | null;
};
export type PastHomeVisitListResponse = {
  data: PastHomeVisitFormResponse[];
  total: number;
};
export type ChatVisitResponse = {
  isPaid: boolean;
  id: string;
  patientPhone: string;
  patientName: string;
  doctorName: string;
  specialization: string | null;
  duration: number;
  cost: number | null;
  status: ChatSupportStatusEnum;
};
export type ChatVisitListResponse = {
  data: ChatVisitResponse[];
  total: number;
};
export type HomeVisitIdRequest = {
  id: number;
};
export type ChatAssistingRequest = {
  id: string;
};
export type AcceptWithEditHomeVisitRequest = {
  id: number;
  doctorId?: number;
  zone?: number;
  patientAddress?: string;
  callDate?: string;
  callTime?: string;
};
export type AcceptChatAssistingWithEdit = {
  chatId: string;
  specialization?: string;
  duration: Duration;
};
export type HomeVisitRefuseResponse = {
  id: number;
  cityId: number;
  externalId: number;
  patientName: string;
  patientAddress: string;
  patientPhone: string;
  specialization: string;
  callDate: string;
  callTime: string;
  doctorName: string | null;
  cost: number;
  status: RejectByPatientEnum;
  cancelComment: string;
};
export type HomeVisitRefuseListResponse = {
  data: HomeVisitRefuseResponse[];
  total: number;
};
export type ConversationUploadDocumentRequest = {
  document: Blob;
  internalConversationId: number;
};
export type DoctorPastHomeVisitRequestDto = {
  homeVisitId: number;
  patientName: string;
  patientPhone: string;
  chatId?: string;
  patientId: number;
  callDate: string;
  loadedFiles: number[];
};
export type DoctorPastHomeVisitWithDocumentsRequestDto = {
  homeVisitId: number;
  patientName: string;
  patientPhone: string;
  chatId?: string;
  patientId: number;
  callDate: string;
  loadedFiles: number;
};
export type ChatMemberResponse = {
  patientId: string;
  doctorId: string;
  chatName: string;
  chatId: string;
  updated: string;
  lastChatReadByDoctorTimestamp: string | null;
  create_at: number;
  chatType: string;
  isActive: boolean;
};
export type ChatReadRequest = {
  chatUUID: string;
};
export type OptionResponse = {
  key: OptionKeyEnum;
  value: string;
};
export type OptionListResponse = {
  data: OptionResponse[];
};
export type CreateMarketingMailingByIdsRequestDto = {
  /** Name of the marketing mailing */
  name: string;
  /** Description of the marketing mailing */
  description: string;
  /** Date and time when the mailing should be sent in ISO 8601 format */
  isoSendDateTime: string;
  /** External Ids of users the mailing should be sent */
  aggregatedPatientExternalIds: number[];
  /** Original file name if the mailing is based on a file upload */
  fileName: string;
};
export type CreateMarketingMailingByFiltersRequestDto = {
  /** Name of the marketing mailing */
  name: string;
  /** Description of the marketing mailing */
  description: string;
  /** Date and time when the mailing should be sent in ISO 8601 format */
  isoSendDateTime: string;
  /** Gender filter */
  genderFilter: ("MALE" | "FEMALE")[];
  /** Age filter */
  ageFilter: ("18-25" | "26-35" | "36-55" | "56-65" | "66+")[];
  /** Child filter */
  childFilter: ("yes" | "no")[];
};
export type MarketingMailingResponseDto = {
  id: number;
  name: string;
  description: string;
  isoSendDateTime: string;
  status: ResponseMarketingMailingStatusType;
  recipientsNumber: number;
};
export type MarketingMailingListResponse = {
  data: MarketingMailingResponseDto[];
  total: number;
};
export type MarketingMailingFindOneResponseDto = {
  id: number;
  name: string;
  description: string;
  sendDateTime: string;
  status: ResponseMarketingMailingStatusType;
  gatewayUserIds: number[];
  fileName: string | null;
  genderFilter: GenderApiType[] | null;
  ageFilter: AgeType[] | null;
  childFilter: ChildType[] | null;
};
export type UpdateMarketingMailingByFiltersRequestDto = {
  /** Name of the marketing mailing */
  name: string;
  /** Description of the marketing mailing */
  description: string;
  /** Date and time when the mailing should be sent in ISO 8601 format */
  isoSendDateTime: string;
  /** Gender filter */
  genderFilter: ("MALE" | "FEMALE")[];
  /** Age filter */
  ageFilter: ("18-25" | "26-35" | "36-55" | "56-65" | "66+")[];
  /** Child filter */
  childFilter: ("yes" | "no")[];
};
export type UpdateMarketingMailingByIdsRequestDto = {
  /** Name of the marketing mailing */
  name: string;
  /** Description of the marketing mailing */
  description: string;
  /** Date and time when the mailing should be sent in ISO 8601 format */
  isoSendDateTime: string;
  /** External Ids of users the mailing should be sent */
  aggregatedPatientExternalIds: number[];
  /** Original file name if the mailing is based on a file upload */
  fileName: string;
};
export enum NotificationCase {
  NotAccept = "not_accept",
  UpcomingRecording = "upcoming-recording",
  RecordConfirmation = "record-confirmation",
  CancelRecording = "cancel-recording",
  NewDocs = "new-docs",
  NewAnalysis = "new-analysis",
  HomeVisit = "home-visit",
  HomeVisitConfirmation = "home-visit-confirmation",
  TaxRefund = "tax-refund",
  TaxRefundComplete = "tax-refund-complete",
  PaymentSucceeded = "payment-succeeded",
  PaymentCanceled = "payment-canceled",
  AcceptRecord = "accept_record",
  HomeVisitCancel = "home_visit_cancel",
  HomeVisitRejected = "home-visit-rejected",
  HomeVisitEdit = "home_visit_edit",
  HomeVisitMove = "home_visit_move",
  HomeVisitCancelByAdmin = "home_visit_cancel_by_admin",
  UserFeedbackHome = "user_feedback_home",
  ChatConfirmation = "chat_confirmation",
  ChatVisitCancelByAdmin = "chat_visit_cancel_by_admin",
  UserFeedbackClinic = "user_feedback_clinic",
  UserFeedbackOnline = "user_feedback_online",
  HomeVisitPaymentSucceeded = "home-visit-payment-succeeded",
  AssistingChatPaymentSucceeded = "assisting-chat-payment-succeeded",
  MarketingNotification = "marketing-notification",
  AssistingChatRejectedByAdmin = "assisting-chat-rejected-by-admin",
  ExtraFacilitiesPaymentSucceeded = "extra-facilities-payment-succeeded",
  ExtraFacilitiesPaymentRequest = "extra-facilities-payment-request",
  OnlineAppointmentCreateAppointmentByPatient = "online-appointment-create-appointment-by-patient",
  OnlineAppointmentEditAppointmentByAdmin = "online-appointment-edit-appointment-by-admin",
  OnlineAppointmentRejectAppointmentByAdmin = "online-appointment-reject-appointment-by-admin",
  OnlineAppointmentCancelAppointmentByAdmin = "online-appointment-cancel-appointment-by-admin",
  OnlineAppointmentPaymentReminder = "online-appointment-payment-reminder",
  OnlineAppointmentCancelUnpaidAppointment = "online-appointment-cancel-unpaid-appointment",
  OnlineAppointmentAcceptAppointmentMainPaymentByAdmin = "online-appointment-accept-appointment-main-payment-by-admin",
  OnlineAppointmentAcceptAppointmentNotMainPaymentByAdmin = "online-appointment-accept-appointment-not-main-payment-by-admin",
  OnlineAppointmentStarsInHour = "online-appointment-stars-in-hour",
  OnlineAppointmentFinishedFiveMinutesAgo = "online-appointment-finished-five-minutes-ago",
  OnlineAppointmentStartsRightNow = "online-appointment-starts-right-now",
  OnlineAppointmentNewProtocolCreatedByDoctor = "online-appointment-new-protocol-created-by-doctor",
  OnlineAppointmentPaidSuccessfully = "online-appointment-paid-successfully",
  OnlineAppointmentPaidUnsuccessfully = "online-appointment-paid-unsuccessfully",
  OnlineAppointmentCreateAppointmentByPatientToAdmin = "online-appointment-create-appointment-by-patient-to-admin",
  OnlineAppointmentPaidByPatientToAdmin = "online-appointment-paid-by-patient-to-admin",
  OnlineAppointmentCancelledByPatientToAdmin = "online-appointment-cancelled-by-patient-to-admin",
  OnlineAppointmentDoctorIsAbsentToAdmin = "online-appointment-doctor-is-absent-to-admin",
  OnlineAppointmentPatientIsAbsentToAdmin = "online-appointment-patient-is-absent-to-admin",
  OnlineAppointmentAcceptedByAdminToDoctor = "online-appointment-accepted-by-admin-to-doctor",
  OnlineAppointmentCancelledByAdminToDoctor = "online-appointment-cancelled-by-admin-to-doctor",
  OnlineAppointmentCancelUnpaidAppointmentToDoctor = "online-appointment-cancel-unpaid-appointment-to-doctor",
  OnlineAppointmentPaidByPatientToDoctor = "online-appointment-paid-by-patient-to-doctor",
  OnlineAppointmentStarsInHourToDoctor = "online-appointment-stars-in-hour-to-doctor",
  OnlineAppointmentStartsRightNowToDoctor = "online-appointment-starts-right-now-to-doctor",
}
export enum HealthAssistanceType {
  HomeVisit = "home_visit",
  HomeVisitRefuse = "home-visit_refuse",
  ChatAssisting = "chat_assisting",
  OnlineVisit = "online-visit",
}
export enum PaymentMethodType {
  BankCard = "bankCard",
  YooMoney = "yooMoney",
  ApplePay = "applePay",
  GooglePay = "googlePay",
  Sberbank = "sberbank",
}
export enum AdminHomeVisitStatusEnum {
  New = "New",
  InProgressByCurrentAdmin = "InProgressByCurrentAdmin",
  AcceptByDoctor = "AcceptByDoctor",
  AcceptByAdmin = "AcceptByAdmin",
  InProgressByAnotherAdmin = "InProgressByAnotherAdmin",
  Finished = "Finished",
}
export enum PaymentStatus {
  WaitingForCapture = "waiting_for_capture",
  Pending = "pending",
  Succeeded = "succeeded",
  Canceled = "canceled",
  None = "none",
}
export enum OnlineAppointmentPaymentMethodEnum {
  Main = "Main",
  Dms = "Dms",
  Subscription = "Subscription",
}
export enum OnlineVisitAppointmentForPatientStatusEnum {
  New = "New",
  Assigned = "Assigned",
  Accepted = "Accepted",
  PendingCancellation = "PendingCancellation",
  Finished = "Finished",
  DoctorAbsent = "DoctorAbsent",
  Processing = "Processing",
}
export enum OnlineVisitForAdminStatusEnum {
  NewForAdmin = "NewForAdmin",
  AssignedByCurrentAdmin = "AssignedByCurrentAdmin",
  AssignedByAnotherAdmin = "AssignedByAnotherAdmin",
  PendingCancellation = "PendingCancellation",
  ProcessingByCurrentAdmin = "ProcessingByCurrentAdmin",
  ProcessingByAnotherAdmin = "ProcessingByAnotherAdmin",
  AcceptedByAdmin = "AcceptedByAdmin",
  FinishedForAdmin = "FinishedForAdmin",
  DoctorAbsent = "DoctorAbsent",
  Cancelled = "Cancelled",
  Rejected = "Rejected",
}
export enum Extension {
  ApplicationPdf = "application/pdf",
  ImageJpeg = "image/jpeg",
  ImagePng = "image/png",
  TextHtml = "text/html",
}
export enum Status {
  Ok = "ok",
  Waiting = "waiting",
}
export enum DocumentExtension {
  ApplicationPdf = "application/pdf",
  ImageJpeg = "image/jpeg",
  ImagePng = "image/png",
  TextHtml = "text/html",
}
export enum DocumentTypeEnum {
  Protocol = "Protocol",
  Others = "Others",
}
export enum FileExtension {
  ApplicationPdf = "application/pdf",
  ImageJpeg = "image/jpeg",
  ImagePng = "image/png",
  TextHtml = "text/html",
}
export enum ProtocolsTypeEnum {
  OnlineVisitProtocol = "onlineVisitProtocol",
  HomeVisitProtocol = "homeVisitProtocol",
}
export enum ConversationTypeEnum {
  OnlineVisit = "OnlineVisit",
  ChatAssistant = "ChatAssistant",
  HomeVisit = "HomeVisit",
}
export enum Duration {
  $0 = 0,
  $3 = 3,
  $5 = 5,
}
export enum ChatTypeEnum {
  HomeAssisting = "home-assisting",
  HelpAssisting = "help-assisting",
}
export enum PatientChatDurationEnum {
  TestOneMinute = "TestOneMinute",
  TestFiveMinute = "TestFiveMinute",
  ThreeDays = "ThreeDays",
  FiveDays = "FiveDays",
}
export enum UserRoleEnum {
  Admin = "admin",
  User = "user",
  Doctor = "doctor",
  Manager = "manager",
}
export enum PayMethod {
  $0 = 0,
  $1 = 1,
  $2 = 2,
}
export enum Payment {
  $0 = 0,
  $1 = 1,
}
export enum AvailabilityForDoctor {
  Available = "Available",
  NotAvailable = "NotAvailable",
}
export enum ChatSupportStatusEnum {
  New = "New",
  InProgressByCurrentAdmin = "InProgressByCurrentAdmin",
  AcceptByAdmin = "AcceptByAdmin",
  InProgressByAnotherAdmin = "InProgressByAnotherAdmin",
}
export enum RejectByPatientEnum {
  New = "New",
  InProgressByCurrentAdmin = "InProgressByCurrentAdmin",
  InProgressByAnotherAdmin = "InProgressByAnotherAdmin",
}
export enum OptionKeyEnum {
  PubnubSubscribeKey = "PUBNUB_SUBSCRIBE_KEY",
  PubnubPublishKey = "PUBNUB_PUBLISH_KEY",
  FeatureOnlineVisit = "FEATURE_ONLINE_VISIT",
  FeatureCalendar = "FEATURE_CALENDAR",
  FeatureProtocols = "FEATURE_PROTOCOLS",
}
export enum ResponseMarketingMailingStatusType {
  WaitingSending = "waiting_sending",
  Sended = "sended",
}
export enum GenderApiType {
  Male = "MALE",
  Female = "FEMALE",
}
export enum AgeType {
  $1825 = "18-25",
  $2635 = "26-35",
  $3655 = "36-55",
  $5665 = "56-65",
  $66 = "66+",
}
export enum ChildType {
  Yes = "yes",
  No = "no",
}
export const {
  useDoctorNotificationControllerGetNotificationQuery,
  useDoctorNotificationControllerGetUnreadNotificationCountQuery,
  useDoctorNotificationControllerViewNotificationsMutation,
  useDoctorNotificationControllerDeclineHomeVisitMutation,
  useDoctorNotificationControllerDeclineHomeVisitByFormIdMutation,
  useDoctorNotificationControllerAcceptRequestMutation,
  useDoctorNotificationControllerAcceptRequestByFormIdMutation,
  useNotificationAdminControllerGetNotificationsForCurrentAdminQuery,
  useNotificationAdminControllerReadNotificationMutation,
  useNotificationAdminControllerHasUnreadMessagesQuery,
  usePaymentControllerCreateHomeVisitPaymentMutation,
  usePaymentControllerCreateChatAssistingPaymentMutation,
  useDoctorControllerDoctorsListQuery,
  useDoctorControllerDoctorsListByPatientIdQuery,
  useDoctorControllerSpecialtyListQuery,
  useDoctorControllerDoctorQuery,
  useScheduleControllerHomeVisitMutation,
  useScheduleControllerHomeVisitPaymentListQuery,
  useScheduleControllerUserUpcomingHomeVisitQuery,
  useUserControllerClearTaxRefundDateMutation,
  useUserControllerTaxRefundMutation,
  useUserControllerCheckTaxRefundQuery,
  useUserControllerFindUserDataQuery,
  useUserControllerDeleteUserMutation,
  useUserControllerFindAccountsQuery,
  useUserControllerSetPushTokenMutation,
  useUserControllerCreateUserFeedbackMutation,
  useOnlineVisitSlotControllerAddSlotMutation,
  useOnlineVisitSlotControllerDeleteSlotMutation,
  useOnlineVisitDoctorControllerGetOnlineVisitDoctorSlotListQuery,
  useOnlineVisitDoctorControllerGetOnlineVisitDoctorsSpecialityListQuery,
  useOnlineVisitAppointmentControllerPatientCreateAppointmentMutation,
  useOnlineVisitAppointmentControllerAdminRejectRequestToAppointmentMutation,
  useOnlineVisitAppointmentControllerAdminAssignAppointmentMutation,
  useOnlineVisitAppointmentControllerAdminAcceptAppointmentMutation,
  useOnlineVisitAppointmentControllerAdminEditAppointmentMutation,
  useOnlineVisitAppointmentControllerPatientCancelAppointmentMutation,
  useOnlineVisitAppointmentControllerAdminCancelAppointmentMutation,
  useOnlineVisitAppointmentControllerAdminAssignCancellationMutation,
  useOnlineVisitAppointmentControllerGetPatientEventListQuery,
  useOnlineVisitAppointmentControllerGetAdminOnlineVisitAppointmentListQuery,
  useOnlineVisitAppointmentControllerGetAdminOnlineVisitAppointmentQuery,
  useOnlineVisitAppointmentControllerDoctorAddSpecialistForAppointmentMutation,
  useOnlineVisitAppointmentControllerDoctorRemoveSpecialistForAppointmentMutation,
  useOnlineVisitAppointmentControllerDoctorFinishAppointmentMutation,
  useOnlineVisitAppointmentControllerDoctorConnectAppointmentQuery,
  useOnlineVisitAppointmentControllerPatientConnectAppointmentQuery,
  useOnlineVisitAppointmentControllerDoctorGetAppointmentInfoForProtocolQuery,
  useOnlineVisitAppointmentControllerDoctorGetAppointmentInfoForCalendarQuery,
  useOnlineVisitFeedbackControllerAddFeedbackMutation,
  useOnlineVisitPaymentControllerPatientInitiatePaymentMutation,
  useOnlineVisitPaymentControllerPatientCompletePaymentMutation,
  useDocumentControllerUploadDocumentMutation,
  useDocumentControllerUploadDocumentDoctorMutation,
  useDocumentControllerUploadAndParseManagerDocumentMutation,
  useDocumentControllerMedicalRecordsQuery,
  useDocumentControllerMedicalRecordsFilesQuery,
  useDocumentControllerMedicalRecordFileQuery,
  useDocumentControllerAnalysisResultsQuery,
  useDocumentControllerAnalysisResultForDoctorQuery,
  useDocumentControllerAnalysisResultsFilesQuery,
  useDocumentControllerAnalysisResultsFilesRequestMutation,
  useDocumentControllerAnalysisResultQuery,
  useDocumentControllerTaxRefundListQuery,
  useDocumentControllerTaxRefundFilesListQuery,
  useDocumentControllerTaxRefundFileQuery,
  useDocumentControllerGetUserChatFilesQuery,
  useDocumentControllerUserUploadedFilesQuery,
  useDocumentControllerUserUploadedFilesWithProtocolsQuery,
  useDocumentControllerUserUploadedFilesByPatientIdQuery,
  useDocumentControllerUserUploadedFileQuery,
  useDocumentControllerDeleteUploadedFileMutation,
  useDocumentControllerUserUploadedFileForDoctorQuery,
  useDocumentControllerUserUploadedFileByIdQuery,
  useDocumentControllerDoctorGetPatientProtocolsQuery,
  useProtocolsControllerCreateOnlineVisitProtocolMutation,
  useProtocolsControllerDeleteProtocolMutation,
  useProtocolsControllerGetProtocolQuery,
  useProtocolsControllerUpdateProtocolMutation,
  useConversationControllerDoctorConversationListQuery,
  useConversationControllerPatientConversationListQuery,
  useConversationControllerDoctorReadConversationMutation,
  useConversationControllerPatientReadConversationMutation,
  useConversationControllerDoctorConnectToConversationQuery,
  useConversationControllerPatientConnectToConversationQuery,
  useChatControllerGetUniqueSpecializationsQuery,
  useChatControllerGetAssistingDoctorQuery,
  useChatControllerGetChatDoctorQuery,
  useChatControllerMakeHelpAssistingChatMutation,
  useChatControllerGetPatientChatListQuery,
  useAuthControllerIsUserExistsMutation,
  useAuthControllerLoginMutation,
  useAuthControllerChangeAccountMutation,
  useAuthControllerRefreshTokenMutation,
  useAuthControllerLogoutMutation,
  useInternalAuthControllerLoginMutation,
  useInternalAuthControllerSetPushTokenMutation,
  useInternalAuthControllerGetDoctorQuery,
  useInternalAuthControllerLogoutMutation,
  useInternalAuthControllerSendValidateCodeMutation,
  useInternalAuthControllerSendResetPasswordLinkMutation,
  useInternalAuthControllerChangePasswordViaCodeMutation,
  useInternalAuthControllerVerifyCodeMutation,
  useInternalAuthControllerResetPasswordMutation,
  useInternalAuthControllerRefreshTokenMutation,
  useInternalAuthControllerChangePasswordMutation,
  useCalendarControllerGetEventListQuery,
  useHomeVisitControllerFindDoctorsQuery,
  useHomeVisitControllerCreateHomeVisitRequestMutation,
  useHomeVisitControllerCancelHomeVisitRequestMutation,
  useHomeVisitControllerUpcomingHomeVisitQuery,
  useHomeVisitControllerPastHomeVisitQuery,
  useHomeVisitControllerGetDoctorUpcomingHomeVisitsQuery,
  useHomeVisitControllerFinishHomeVisitMutation,
  useHomeVisitControllerGetDoctorPastHomeVisitsQuery,
  useHomeVisitControllerGetHomeVisitQuery,
  useAdminDoctorsControllerDoctorsListQuery,
  useAdminDoctorsControllerDoctorQuery,
  useAdminDoctorsControllerAddAccountMutation,
  useAdminDoctorsControllerUpdateAccountMutation,
  useAdminDoctorsControllerDeleteAccountMutation,
  useAdminVisitsControllerGetHomeVisitsQuery,
  useAdminVisitsControllerGetPastHomeVisitsQuery,
  useAdminVisitsControllerGetPastHomeVisitQuery,
  useAdminVisitsControllerGetChatAssistingQuery,
  useAdminVisitsControllerGetOneChatAssistingVisitQuery,
  useAdminVisitsControllerGetOneHomeVisitQuery,
  useAdminVisitsControllerSetHomeVisitInProgressMutation,
  useAdminVisitsControllerSetChatAssistingInProgressMutation,
  useAdminVisitsControllerAcceptHomeVisitMutation,
  useAdminVisitsControllerAcceptChatAssistingMutation,
  useAdminVisitsControllerRejectChatAssistingVisitMutation,
  useAdminVisitsControllerRejectHomeVisitMutation,
  useAdminVisitsControllerViewRejectByPatientQuery,
  useAdminVisitsControllerViewRejectByPatientByIdQuery,
  useDoctorPanelControllerAnalysisResultForDoctorWebQuery,
  useDoctorPanelControllerDownloadUseFileQuery,
  useDoctorPanelControllerAnalysisResultQuery,
  useDoctorPanelControllerUploadDocumentDoctorMutation,
  useDoctorPanelControllerUploadConversationDocumentMutation,
  useDoctorPanelControllerPatientQuery,
  useDoctorPanelControllerPatientsQuery,
  useDoctorPanelControllerPatientFilesQuery,
  useDoctorPanelControllerChatMembersQuery,
  useDoctorPanelControllerReadChatMutation,
  useOptionsControllerGetOptionsQuery,
  useMarketingMailingControllerCreateMarketingMailingWithIdsMutation,
  useMarketingMailingControllerCreateMarketingMailingWithFiltersMutation,
  useMarketingMailingControllerFindAllQuery,
  useMarketingMailingControllerDeleteMarketingMailingMutation,
  useMarketingMailingControllerFindOneQuery,
  useMarketingMailingControllerUpdateMarketingMailingByFiltersMutation,
  useMarketingMailingControllerUpdateMarketingMailingByIdsMutation,
} = injectedRtkApi;
