import moment, { type Moment } from 'moment';

// Check is date has the same hours value and day value (hours are in HH format (00 - 23))
export const isTheSameTimeStamp = (ISO: string, day: Moment, hours: string) =>
  moment(ISO).isSame(day, 'day') && moment(ISO).format('HH') === hours;

// Get hours from ISOdate in format "HH" (00 - 23)
export const getHoursFromDate = (ISO: string) => moment(ISO).format('HH');

// Get date from ISOdate in format ""DD MMMM" (e.g. 20 июля)
export const getFormattedDateWithRusLocale = (ISO: string) => moment(ISO).locale('ru').format('DD MMMM');

// Check is the firstISOdate date bigger than the secondISOdate date
export const isDateBigger = (firstISO: string, secondISO: string) => moment(firstISO).isAfter(secondISO);
