/* eslint no-restricted-imports: 0 */
/* eslint @typescript-eslint/no-unsafe-assignment: 0 */
import { useState } from 'react';

import { ConfigProvider, Empty, List, Pagination, Typography } from 'antd';

import { useGetPatientAnalysisResultQuery } from 'entity/doctor/api';
import { useDoctorDownloadAnaliseFileQuery } from 'entity/files/files/use-doctor-download-analise-file-query';
import { AppExpandPanel } from 'shared/ui/expand-panel';
import { DocumentDownloadIcon, DocumentIcon, EmptyIcon } from 'shared/ui/icons';
import { IAnalysisInfo, IAnalysisResult } from 'widgets/doctor-analysis-results/analysis-result.interface';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PatientAnalysisResults = ({ patientId }: any) => {
  const [offset, setOffset] = useState(0);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const { data: allData } = useGetPatientAnalysisResultQuery(patientId);
  const limit = 3;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
  const currentData = allData?.data.slice(offset, offset + limit);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const total = allData?.total;
  const [downloadFile] = useDoctorDownloadAnaliseFileQuery();

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const downloadFileInfo = ({ fileId, fileName }: IAnalysisInfo) => {
    downloadFile({ fileId, patientId, fileName });
  };

  return (
    <AppExpandPanel name="analysis-results" title="Результаты анализов">
      <ConfigProvider
        renderEmpty={() => (
          <Empty
            description="На текущее время отсутствуют результаты анализов."
            image={<EmptyIcon height={90} width={90} />}
          />
        )}
      >
        <List
          itemLayout="horizontal"
          dataSource={currentData}
          footer={
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            currentData?.length ? (
              <Pagination
                pageSize={3}
                size="small"
                showSizeChanger={false}
                total={total}
                onChange={(page) => {
                  setOffset(page * limit - limit);
                }}
              />
            ) : null
          }
          renderItem={(item: IAnalysisResult) => {
            return (
              <List.Item
                onClick={() =>
                  downloadFileInfo({
                    userId: patientId,
                    fileId: item.id,
                    fileName: item.name,
                  })
                }
                actions={[
                  // eslint-disable-next-line react/jsx-key
                  <DocumentDownloadIcon style={{ cursor: 'pointer' }} />,
                ]}
              >
                <List.Item.Meta
                  avatar={<DocumentIcon />}
                  title={<Typography.Text style={{ fontWeight: 400 }}>{item.name}</Typography.Text>}
                />
              </List.Item>
            );
          }}
        />
      </ConfigProvider>
    </AppExpandPanel>
  );
};
