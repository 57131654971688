import { useMemo } from 'react';

import { useProtocolsControllerGetProtocolQuery } from 'shared/api/rtk-api';

import { externalDoctorOnlineAppointmentProtocolToInternalDoctorOnlineAppointmentProtocolMapper } from '../mappers/external-doctor-online-appointment-protocol-to-internal-doctor-online-appointment-protocol';

export const useDoctorOnlineAppointmentProtocolQuery = (id?: string) => {
  const protocolId = Number(id);

  const { data, ...others } = useProtocolsControllerGetProtocolQuery(protocolId, { skip: !id });

  const onlineAppointmentProtocol = useMemo(() => {
    return data
      ? externalDoctorOnlineAppointmentProtocolToInternalDoctorOnlineAppointmentProtocolMapper(data)
      : undefined;
  }, [data]);

  return {
    onlineAppointmentProtocol,
    ...others,
  };
};
