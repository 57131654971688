import { type ChangeEvent, type FC, type KeyboardEvent, useRef, useState } from 'react';

import { generateUUID } from 'pubnub';
import { usePubNub } from 'pubnub-react';
import { transliterate } from 'transliteration';

import { useCurrentUserQuery } from 'entity/auth/hooks/use-current-user-query';
import { useUploadConversationDocumentToPatientMutation } from 'entity/doctor/api';
import { MessageType } from 'entity/pubnub/lib/utils/message-helper-payload';
import { OpenFile, SendMessageIcon } from 'shared/ui/icons';

import { SendMessageButton, SendMessageContainer, SendMessageFileInputContainer, SendMessageTextarea } from './styles';

type TextMessageType = {
  type: MessageType;
  message?: string;
  filename?: string;
  fileId?: string;
};

type Props = {
  conversationId: string;
  pubnubChannel: string;
};

export const DoctorConversationSendMessageWidget: FC<Props> = ({ conversationId, pubnubChannel }: Props) => {
  const pubnub = usePubNub();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [textareaValue, setTextareaValue] = useState('');

  const [uploadDocument] = useUploadConversationDocumentToPatientMutation();

  const { currentUser } = useCurrentUserQuery();

  const pushToken = currentUser?.pushToken;
  const senderName = currentUser?.name;

  const handleIconClick = () => fileInputRef.current?.click();

  const handlePublishMessage = (text: TextMessageType) => {
    const currentDate = new Date();

    pubnub.publish(
      {
        channel: pubnubChannel,
        message: {
          createdAt: currentDate,
          id: generateUUID(),
          text,
          pn_fcm: {
            notification: {
              title: 'Медгард',
              body: `${senderName}: ${textareaValue || text.filename}`,
            },
            data: {
              title: 'Медгард',
              body: `${senderName}: ${textareaValue || text.filename}`,
              chatId: pubnubChannel,
            },
            android: { priority: 'high' },
            apns: {
              payload: {
                aps: {
                  content_available: true,
                  sound: 'default',
                },
              },
            },
            pn_exceptions: pushToken ? [pushToken] : [],
          },
          pn_debug: false,
          pn_dry_run: false,
        },
      },
      (status) => {
        if (!status.error) setTextareaValue('');
      },
    );
  };

  const handleEnterPress = () => {
    const messageText = { type: MessageType.TEXT, message: textareaValue };
    handlePublishMessage(messageText);
  };

  const handleTextareaKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey && textareaValue.trim()) {
      handleEnterPress();
    }
  };

  const handleSendClick = () => textareaValue.trim() && handleEnterPress();

  const doUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    if (textareaValue) {
      const messageText = { type: MessageType.TEXT, message: textareaValue };
      handlePublishMessage(messageText);
    }
    const file = event.target.files?.[0];

    if (!file) return;

    const transliterateName = transliterate(file.name);

    const bodyFormData = new FormData();
    bodyFormData.append('conversationId', conversationId);
    bodyFormData.append('document', file, transliterateName);

    const uploadedFile = await uploadDocument(bodyFormData).unwrap();

    if (uploadedFile) {
      const messageText = {
        type: MessageType.FILE,
        filename: uploadedFile.name,
        fileId: uploadedFile.id.toString(),
      };
      handlePublishMessage(messageText);
    }
  };

  return (
    <SendMessageContainer>
      <SendMessageFileInputContainer onClick={handleIconClick}>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
          onChange={doUpload}
        />
        <OpenFile />
      </SendMessageFileInputContainer>
      <SendMessageTextarea
        autoSize={{ maxRows: 5 }}
        value={textareaValue}
        onChange={(event) => setTextareaValue(event.target.value)}
        onKeyDown={handleTextareaKeyDown}
        placeholder="Сообщение"
      />
      <SendMessageButton onClick={handleSendClick}>
        <SendMessageIcon />
      </SendMessageButton>
    </SendMessageContainer>
  );
};
