import { type FC } from 'react';

import { Row, Typography } from 'antd';

import { ScheduleEventTypeTitle } from 'entity/schedule/constants';
import type { InternalScheduleOnlineAppointmentEvent } from 'entity/schedule/types/internal-schedule-online-appointment-event';
import { ScheduleAddSpecialistByDoctorButton } from 'feature/schedule-add-specialist-by-doctor/ui';
import { ScheduleConnectToOnlineAppointmentByDoctorButton } from 'feature/schedule-connect-to-online-appointment-by-doctor/ui';
import { ScheduleDoctorGoToOnlineAppointmentDetailsButton } from 'feature/schedule-doctor-go-to-online-appointment-details/ui';
import { ScheduleRemoveSpecialistByDoctorButton } from 'feature/schedule-remove-specialist-by-doctor/ui';
import { CloseIcon } from 'shared/ui/icons';

import { DoctorSchedulePatientDocuments } from '../documents';
import { ButtonsWrapper, CloseIconWrapper, ScheduleSlotPopoverContentContainer } from './styles';

const { Text, Title, Paragraph } = Typography;

type Props = {
  event: InternalScheduleOnlineAppointmentEvent;
  closeModal: () => void;
};

export const OnlineAppointmentEventDetails: FC<Props> = ({ event, closeModal }) => {
  const {
    type,
    patientName,
    mainDoctorName,
    mainDoctorId,
    specialistName,
    specialistId,
    canAddSpecialist,
    id,
    dateAndTime,
  } = event;

  return (
    <ScheduleSlotPopoverContentContainer>
      <Row justify="space-between">
        <Title level={4}>{ScheduleEventTypeTitle[type]}</Title>
        <CloseIconWrapper onClick={closeModal}>
          <CloseIcon />
        </CloseIconWrapper>
      </Row>

      <Paragraph>
        <Text type="secondary">Дата и время</Text>
        <br />
        <Text>{dateAndTime}</Text>
      </Paragraph>

      <Paragraph>
        <Text type="secondary">Пациент</Text>
        <br />
        <Text>{patientName}</Text>
      </Paragraph>

      {specialistName && specialistId && (
        <Paragraph>
          <Text type="secondary">Узкий специалист</Text>
          <br />
          <Text>{specialistName}</Text>
        </Paragraph>
      )}

      {canAddSpecialist && !specialistId && <ScheduleAddSpecialistByDoctorButton event={event} />}

      {canAddSpecialist && specialistId && <ScheduleRemoveSpecialistByDoctorButton event={event} />}

      {mainDoctorName && mainDoctorId && (
        <Paragraph>
          <Text type="secondary">Основной врач</Text>
          <br />
          <Text>{mainDoctorName}</Text>
        </Paragraph>
      )}

      <DoctorSchedulePatientDocuments appointmentId={id} />

      <ButtonsWrapper>
        {/* <ScheduleUseOnlineAppointmentChatByDoctorButton /> */}
        <ScheduleConnectToOnlineAppointmentByDoctorButton event={event} />
      </ButtonsWrapper>

      <ScheduleDoctorGoToOnlineAppointmentDetailsButton onlineAppointmentId={id} />
    </ScheduleSlotPopoverContentContainer>
  );
};
