import { useState } from 'react';

import { ConfigProvider, Empty, List, Pagination, Typography } from 'antd';
import styled from 'styled-components';

import { useDoctorChatFilesQuery } from 'entity/files/files/use-doctor-chat-files-query';
import { useDoctorDownloadPatientFileMutation } from 'entity/files/files/use-doctor-download-patient-file-mutation';
import { AppExpandPanel } from 'shared/ui/expand-panel';
import { DocumentDownloadIcon, DocumentIcon, EmptyIcon } from 'shared/ui/icons';

type Props = {
  patientId: number;
};

export const DownloadIconContainer = styled.div`
  cursor: pointer;
`;

export const PatientDocuments = ({ patientId }: Props) => {
  const [offset, setOffset] = useState(0);
  const { data } = useDoctorChatFilesQuery({ patientId });
  const [mutation] = useDoctorDownloadPatientFileMutation();

  const limit = 3;
  const currentData = data?.doctorChatFiles.slice(offset, offset + limit);
  const total = data?.total;

  const doDownloadPatientFile = (fileName: string, fileId: number) => {
    mutation({ fileId, patientId, fileName });
  };

  return (
    <AppExpandPanel name="documents" title="Загруженные документы">
      {currentData && (
        <ConfigProvider
          renderEmpty={() => (
            <Empty
              description="На текущее время загруженные документы отсутствуют."
              image={<EmptyIcon height={90} width={90} />}
            />
          )}
        >
          <List
            itemLayout="horizontal"
            dataSource={currentData}
            footer={
              total ? (
                <Pagination
                  pageSize={3}
                  size="small"
                  total={total}
                  onChange={(page) => {
                    setOffset(page * limit - limit);
                  }}
                />
              ) : null
            }
            renderItem={({ name, id }) => {
              return (
                <List.Item
                  actions={[
                    // eslint-disable-next-line react/jsx-key
                    <DownloadIconContainer onClick={() => doDownloadPatientFile(name, id)}>
                      <DocumentDownloadIcon />
                    </DownloadIconContainer>,
                  ]}
                >
                  <List.Item.Meta
                    avatar={<DocumentIcon />}
                    title={<Typography.Text style={{ fontWeight: 400 }}>{name}</Typography.Text>}
                  />
                </List.Item>
              );
            }}
          />
        </ConfigProvider>
      )}
    </AppExpandPanel>
  );
};
