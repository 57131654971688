import { useEffect } from 'react';

import { useDaily } from '@daily-co/daily-react';

type Props = {
  dailyChannel?: string;
  doctorName?: string;
};

export const useConnectToDailyMeeting = ({ dailyChannel, doctorName }: Props) => {
  const callFrame = useDaily();

  useEffect(() => {
    if (callFrame && dailyChannel) {
      callFrame.join({
        url: dailyChannel,
        startVideoOff: true,
        startAudioOff: true,
        userName: doctorName,
      });
    }

    return () => {
      callFrame?.leave();
    };
  }, [callFrame, dailyChannel, doctorName]);
};
