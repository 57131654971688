import { type FC, useEffect } from 'react';
import { Controller, type SubmitHandler, useForm } from 'react-hook-form';

import { Col, Form, Row, Typography } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker/generatePicker';
import moment, { type Moment } from 'moment';
import { useNavigate, useParams } from 'react-router-dom';

import { pdf } from '@react-pdf/renderer';
import { useCurrentUserQuery } from 'entity/auth/hooks/use-current-user-query';
import { useDoctorCreateOnlineAppointmentProtocolMutation } from 'entity/protocol/hooks/use-doctor-create-online-appointment-protocol';
import { useDoctorUpdateOnlineAppointmentProtocolMutation } from 'entity/protocol/hooks/use-doctor-update-online-appointment-protocol';
import type { InternalDoctorOnlineAppointmentProtocol } from 'entity/protocol/types/internal-doctor-online-appointment-protocol';
import { getFormattedDoctorNameFromFullName } from 'pages/protocol/utils';
import { getStartOfTheCurrentDate } from 'shared/lib/format';
import { routePatches } from 'shared/routes';
import { Button } from 'shared/ui/button';
import { CustomRow } from 'shared/ui/row/custom-row';

import { ProtocolPDFTemplate } from '../pdf-template';
import { FormLine, StyledDatePicker, StyledFormItem, StyledTextArea } from './styles';

const { Title, Paragraph } = Typography;

type Props = {
  defaultValues: InternalDoctorOnlineAppointmentProtocol;
};

export const DoctorOnlineAppointmentProtocolForm: FC<Props> = ({ defaultValues }) => {
  const { protocolId } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useCurrentUserQuery();
  const [createProtocol] = useDoctorCreateOnlineAppointmentProtocolMutation();
  const [updateProtocol] = useDoctorUpdateOnlineAppointmentProtocolMutation();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<InternalDoctorOnlineAppointmentProtocol>();

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onSubmit: SubmitHandler<InternalDoctorOnlineAppointmentProtocol> = async (data) => {
    if (!currentUser) return;

    const protocolData = new FormData();

    const blob = await pdf(<ProtocolPDFTemplate values={data} />).toBlob();

    protocolData.append(
      'document',
      blob,
      `${moment().format('DD.MM.YYYY')}, ${getFormattedDoctorNameFromFullName(currentUser.name)}.pdf`,
    );

    const protocolRequestDto: Record<string, string> = {
      patientBirthDate: data.patientBirthDate?.toISOString() ?? '',
      complaints: data.complaints,
      anamnesis: data.anamnesis,
      conclusion: data.conclusion,
      recommendations: data.recommendations,
      referral: data.referral,
      services: data.services,
      internalOnlineVisitAppointmentId: String(data.onlineVisitAppointmentId),
    };

    Object.keys(protocolRequestDto).forEach((key) => {
      if (protocolRequestDto[key]) {
        protocolData.append(key, protocolRequestDto[key]);
      }
    });

    if (!protocolId) {
      createProtocol({ protocolData });
    } else {
      updateProtocol({ protocolData, protocolId });
    }

    navigate(routePatches.DoctorSchedule.path());
  };

  const disabledDate: RangePickerProps<Moment>['disabledDate'] = (current) => current > getStartOfTheCurrentDate();

  return (
    <Row align="top">
      <Col span={20}>
        <Title level={4}>{protocolId ? 'Редактирование протокола' : 'Создание протокола'}</Title>
        <br />
        <Form name="protocol-form">
          <Row style={{ marginBottom: 25 }}>
            <Col span={7}>
              <Paragraph>
                <FormLine strong>Врач</FormLine>
              </Paragraph>

              <FormLine>{defaultValues.doctorName}</FormLine>
            </Col>
            {defaultValues.specialistName && (
              <Col span={7}>
                <Paragraph>
                  <FormLine strong>Узкий специалист</FormLine>
                </Paragraph>

                <FormLine>{defaultValues.specialistName}</FormLine>
              </Col>
            )}
            <Col span={7}>
              <Paragraph>
                <FormLine strong>Пациент</FormLine>
              </Paragraph>

              <FormLine>{defaultValues.patientName}</FormLine>
            </Col>
          </Row>
          <Row>
            <Col span={7}>
              <Paragraph>
                <FormLine strong>Дата и время</FormLine>
              </Paragraph>
              <FormLine>{defaultValues.dateAndTime}</FormLine>
            </Col>
            <Col span={7}>
              <Form.Item validateStatus={errors.patientBirthDate ? 'error' : ''}>
                <Controller
                  name="patientBirthDate"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CustomRow title="Дата рождения пациента">
                      <StyledDatePicker
                        {...field}
                        placeholder="Выберите дату"
                        format="DD.MM.YYYY"
                        disabledDate={disabledDate}
                      />
                    </CustomRow>
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={21}>
              <StyledFormItem validateStatus={errors.complaints ? 'error' : ''}>
                <Controller
                  name="complaints"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CustomRow title="Жалобы">
                      <StyledTextArea {...field} placeholder="Введите описание" autoSize />
                    </CustomRow>
                  )}
                />
              </StyledFormItem>
            </Col>
          </Row>
          <Row>
            <Col span={21}>
              <StyledFormItem validateStatus={errors.anamnesis ? 'error' : ''}>
                <Controller
                  name="anamnesis"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CustomRow title="Анамнез">
                      <StyledTextArea {...field} placeholder="Введите описание" autoSize />
                    </CustomRow>
                  )}
                />
              </StyledFormItem>
            </Col>
          </Row>

          <Row>
            <Col span={10}>
              <StyledFormItem validateStatus={errors.recommendations ? 'error' : ''}>
                <Controller
                  name="recommendations"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CustomRow title="Рекомендации">
                      <StyledTextArea {...field} placeholder="Введите описание" autoSize />
                    </CustomRow>
                  )}
                />
              </StyledFormItem>
            </Col>
            <Col span={10} offset={1}>
              <StyledFormItem validateStatus={errors.conclusion ? 'error' : ''}>
                <Controller
                  name="conclusion"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CustomRow title="Диагноз">
                      <StyledTextArea {...field} placeholder="Введите описание" autoSize />
                    </CustomRow>
                  )}
                />
              </StyledFormItem>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <StyledFormItem validateStatus={errors.referral ? 'error' : ''}>
                <Controller
                  name="referral"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CustomRow title="Направление">
                      <StyledTextArea {...field} placeholder="Введите описание" autoSize />
                    </CustomRow>
                  )}
                />
              </StyledFormItem>
            </Col>
            <Col span={10} offset={1}>
              <StyledFormItem validateStatus={errors.services ? 'error' : ''}>
                <Controller
                  name="services"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CustomRow title="Услуги">
                      <StyledTextArea {...field} placeholder="Выберите услуги" autoSize />
                    </CustomRow>
                  )}
                />
              </StyledFormItem>
              {/* <StyledFormItem validateStatus={errors.services ? "error" : ""}>
                  <Controller
                    name="services"
                    control={control}
                    // rules={{ required: true }}
                    render={({ field }) => (
                      <StyledSelectRow
                        {...field}
                        title="Услуги"
                        placeholder="Выберите услуги"
                        options={[]}
                      />
                    )}
                  />
                </StyledFormItem> */}
            </Col>
          </Row>
        </Form>
      </Col>
      <Col span={4}>
        <Button
          type="text"
          $buttonType="submit"
          htmlType="submit"
          loading={false}
          /* eslint-disable-next-line @typescript-eslint/no-misused-promises, deprecation/deprecation */
          onClick={handleSubmit(onSubmit)}
        >
          Сохранить
        </Button>
      </Col>
    </Row>
  );
};
