import { type FC } from 'react';

import { Col, Row, Typography } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

import { useDoctorDownloadPatientFileMutation } from 'entity/files/files/use-doctor-download-patient-file-mutation';
import { useDoctorOnlineAppointmentDetailsQuery } from 'entity/online-appointment/hooks/use-doctor-online-appointment-details';
import { routePatches } from 'shared/routes';
import { DocumentDownloadIcon, EditFileIcon, PlusIcon } from 'shared/ui/icons';

import { AddProtocolButtonWrapper, DocumentsWrapper, FileName, UploadIconWrapper } from './styles';

const { Title } = Typography;

type Props = {
  appointmentId: number;
};

export const DoctorSchedulePatientDocuments: FC<Props> = ({ appointmentId }) => {
  const navigate = useNavigate();
  const [downloadFile] = useDoctorDownloadPatientFileMutation();

  const { onlineAppointment } = useDoctorOnlineAppointmentDetailsQuery(appointmentId);

  if (!onlineAppointment) return null;

  const { documents, canAddProtocol, patientId } = onlineAppointment;

  const handleAppProtocolButtonClick = () => navigate(routePatches.DoctorProtocol.path(appointmentId));

  const handleClickProtocol = ({
    fileId,
    fileName,
    openNewTab,
  }: {
    fileId: number;
    fileName: string;
    openNewTab?: boolean;
  }) => downloadFile({ fileId, patientId, fileName, openNewTab });

  return (
    <DocumentsWrapper>
      <Title level={5}>Документы</Title>

      {documents.map(({ id, isOwner, fileName, protocolId }) => (
        <Row gutter={[15, 20]} key={id}>
          <Col onClick={() => handleClickProtocol({ fileName, fileId: id, openNewTab: true })}>
            <FileName>{fileName}</FileName>
          </Col>

          <Col onClick={() => handleClickProtocol({ fileName, fileId: id })}>
            <UploadIconWrapper>
              <DocumentDownloadIcon />
            </UploadIconWrapper>
          </Col>

          {isOwner && (
            <Col>
              <Link to={routePatches.DoctorProtocol.path(`${appointmentId}/${protocolId}`)}>
                <EditFileIcon />
              </Link>
            </Col>
          )}
        </Row>
      ))}
      {canAddProtocol && (
        <AddProtocolButtonWrapper onClick={handleAppProtocolButtonClick}>
          <PlusIcon /> <span>Добавить протокол</span>
        </AddProtocolButtonWrapper>
      )}
    </DocumentsWrapper>
  );
};
