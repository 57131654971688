import moment from 'moment';

import type { OnlineVisitDoctorAppointmentDetailsForProtocolResponseDto } from 'shared/api/rtk-api';
import { formatDateDMAndCallTime, getTimeFromDate } from 'shared/lib/format';

import type { InternalDoctorOnlineAppointmentProtocol } from '../types/internal-doctor-online-appointment-protocol';

export const externalDoctorProtocolInitialInfoToInternalDoctorProtocolInitialInfoMapper = (
  protocolInfo: OnlineVisitDoctorAppointmentDetailsForProtocolResponseDto,
): Partial<InternalDoctorOnlineAppointmentProtocol> => {
  const appointmentTime = `${getTimeFromDate(protocolInfo.startIsoDateTime)} - ${getTimeFromDate(protocolInfo.startIsoDateTime)}`;

  const dateAndTime = formatDateDMAndCallTime(protocolInfo.startIsoDateTime, appointmentTime);
  return {
    onlineVisitAppointmentId: protocolInfo.internalOnlineVisitAppointmentId.toString(),
    startDateTimeIso: protocolInfo.startIsoDateTime,
    dateAndTime,
    doctorName: protocolInfo.doctorFullName,
    specialistName: protocolInfo.specialistFullName,
    patientName: protocolInfo.patientFullName,
    patientBirthDate: protocolInfo.patientBirthDate ? moment(protocolInfo.patientBirthDate) : undefined,
  };
};
