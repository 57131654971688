import { type FC, useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { useDoctorOnlineAppointmentProtocolQuery } from 'entity/protocol/hooks/use-doctor-online-appointment-protocol-query';
import { useDoctorOnlineAppointmentProtocolInitialInfoQuery } from 'entity/protocol/hooks/use-doctor-protocol-initial-info';
import { BackButtonSubheader } from 'feature/navigation/ui/back-button-subheader';

import { initialDefaultProtocolValues } from './constants';
import { ContentWrapper } from './styles';
import { DoctorOnlineAppointmentProtocolForm } from './ui/protocol-form';

export const ProtocolPage: FC = () => {
  document.title = 'Добавить протокол';

  const { onlineAppointmentId, protocolId } = useParams();

  const { protocolInitialInfo } = useDoctorOnlineAppointmentProtocolInitialInfoQuery(onlineAppointmentId);

  const { onlineAppointmentProtocol } = useDoctorOnlineAppointmentProtocolQuery(protocolId);

  const protocolDefaultValues = useMemo(() => {
    if (protocolId && onlineAppointmentProtocol) {
      return onlineAppointmentProtocol;
    }

    if (onlineAppointmentId && protocolInitialInfo) {
      return { ...initialDefaultProtocolValues, ...protocolInitialInfo };
    }

    return initialDefaultProtocolValues;
  }, [protocolId, onlineAppointmentId, onlineAppointmentProtocol, protocolInitialInfo]);

  return (
    <ContentWrapper>
      <BackButtonSubheader title="Онлайн прием" />
      <DoctorOnlineAppointmentProtocolForm defaultValues={protocolDefaultValues} />
    </ContentWrapper>
  );
};
