/* eslint import/export: 0 */
import { rtkQueryApi } from 'shared/api/rtk-api';
import { config } from 'shared/config';

export const enhancedApi = rtkQueryApi
  .enhanceEndpoints({
    addTagTypes: ['User'],
    endpoints: {
      adminVisitsControllerGetHomeVisits: {
        keepUnusedDataFor: 120,
      },
    },
  })
  .enhanceEndpoints({
    endpoints: {
      doctorNotificationControllerDeclineHomeVisitByFormId: {
        invalidatesTags: ['Home-Visit'],
      },
    },
  })
  .enhanceEndpoints({
    endpoints: {
      doctorNotificationControllerAcceptRequestByFormId: {
        invalidatesTags: ['Home-Visit'],
      },
    },
  })
  .enhanceEndpoints({
    endpoints: {
      internalAuthControllerLogin: {
        invalidatesTags: [],
      },
    },
  })
  .enhanceEndpoints({
    endpoints: {
      internalAuthControllerSendResetPasswordLink: {
        invalidatesTags: [],
      },
    },
  })
  .enhanceEndpoints({
    addTagTypes: ['Calendar'],
    endpoints: {
      onlineVisitSlotControllerAddSlot: {
        invalidatesTags: ['Calendar'],
      },
      onlineVisitSlotControllerDeleteSlot: {
        invalidatesTags: ['Calendar'],
      },
    },
  })
  .enhanceEndpoints({
    endpoints: {
      onlineVisitAppointmentControllerDoctorAddSpecialistForAppointment: {
        invalidatesTags: ['Online Visit Doctor', 'Calendar'],
      },
      onlineVisitAppointmentControllerDoctorRemoveSpecialistForAppointment: {
        invalidatesTags: ['Online Visit Doctor', 'Calendar'],
      },
      protocolsControllerDeleteProtocol: {
        invalidatesTags: ['Documents'],
      },
      protocolsControllerCreateOnlineVisitProtocol: {
        invalidatesTags: ['Documents'],
      },
      protocolsControllerUpdateProtocol: {
        invalidatesTags: ['Documents'],
      },
    },
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      downloadPatientFiles: builder.mutation<Blob, { fileId: number; patientId: number }>({
        query({ fileId, patientId }) {
          return {
            url: `${config.apiBaseUrl}doctor-panel/download-patient-file?userId=${patientId}&fileId=${fileId}`,
            method: 'GET',
            responseHandler: (response) => response.blob(),
            cache: 'no-cache',
          };
        },
      }),
      downloadPatientAnalysis: builder.mutation<Blob, { fileId: number; patientId: number }>({
        query({ fileId, patientId }) {
          return {
            url: `${config.apiBaseUrl}doctor-panel/download-analysis-files?userId=${patientId}&fileId=${fileId}`,
            method: 'GET',
            responseHandler: (response) => response.blob(),
            cache: 'no-cache',
          };
        },
      }),
      doctorCreateOnlineVisitProtocol: builder.mutation<void, FormData>({
        query(body) {
          return {
            url: `${config.apiBaseUrl}/protocols/doctor-create-online-visit-protocol`,
            method: 'POST',
            body,
          };
        },
        invalidatesTags: ['Calendar'],
      }),
      doctorDownloadFileFromChat: builder.mutation<Blob, string>({
        query: (id) => ({
          url: `${config.apiBaseUrl}documents/uploaded-files-for-chat/${id}`,
          responseHandler: (response) => response.blob(),
          cache: 'no-cache',
        }),
      }),
      doctorUpdateOnlineVisitProtocol: builder.mutation<void, { protocolData: FormData; protocolId: string }>({
        query({ protocolData, protocolId }) {
          return {
            url: `${config.apiBaseUrl}/protocols/doctor-update-online-visit-protocol/${protocolId}`,
            method: 'PUT',
            body: protocolData,
          };
        },
      }),
    }),
    overrideExisting: false,
  });

const userApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: () => ({
        url: `/admin/visits/schedule-visits`,
        method: 'GET',
      }),
      invalidatesTags: ['User'],
    }),
  }),
});

export const {
  useInternalAuthControllerLoginMutation,
  useInternalAuthControllerSendResetPasswordLinkMutation,
  useDoctorNotificationControllerDeclineHomeVisitByFormIdMutation,
  useDoctorNotificationControllerAcceptRequestByFormIdMutation,
  useDownloadPatientFilesMutation,
  useDownloadPatientAnalysisMutation,
  useDoctorCreateOnlineVisitProtocolMutation,
  useDoctorUpdateOnlineVisitProtocolMutation,
  useDoctorDownloadFileFromChatMutation,
} = userApi;
