import { type FC } from 'react';

import { DailyVideo, useParticipantProperty } from '@daily-co/daily-react';
import { useParticipantAudioVideoTrack } from 'entity/daily/hooks/use-participant-audio-video-track';
import { MutedOnlineAppointmentMicrophoneIcon } from 'shared/ui/icons';

import { OnlineAppointmentBlackVideoScreen } from '../black-screen';
import { MicrophoneIconWrapper, ParticipantTitleWrapper, SecondaryVideoWrapper, StyledSpan } from './styles';

type Props = {
  participantId: string;
};

export const OnlineAppointmentSecondaryScreen: FC<Props> = ({ participantId }) => {
  const { mutedVideo, mutedAudio } = useParticipantAudioVideoTrack({
    participantId,
  });

  const participantName = useParticipantProperty(participantId, 'user_name');

  return (
    <SecondaryVideoWrapper>
      {mutedVideo && <OnlineAppointmentBlackVideoScreen isBlack />}
      {!mutedVideo && <DailyVideo sessionId={participantId} type="video" fit="cover" mirror />}

      {mutedAudio && (
        <MicrophoneIconWrapper>
          <MutedOnlineAppointmentMicrophoneIcon />
        </MicrophoneIconWrapper>
      )}

      <ParticipantTitleWrapper>
        <StyledSpan>{participantName}</StyledSpan>
      </ParticipantTitleWrapper>
    </SecondaryVideoWrapper>
  );
};
