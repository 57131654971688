import { Form, Input, Typography } from 'antd';
import styled from 'styled-components';

import { DatePicker } from 'shared/ui/data-picker';
import { SelectRow } from 'shared/ui/row/select-row';

const { Text } = Typography;
const { TextArea } = Input;

export const FormLine = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes.default};
`;

export const StyledSelectRow = styled(SelectRow)`
  &.ant-select {
    .ant-select-selector {
      min-height: 43px;
      padding: 0 15px;
      align-items: center;
    }
    .ant-select-arrow {
      right: 20px;
    }

    .ant-select-selection-placeholder {
      font-size: ${({ theme }) => theme.fontSizes.default};
    }
  }
`;

export const StyledDatePicker = styled(DatePicker)`
  &.ant-picker {
    min-height: 43px;
    width: 100%;
    border-radius: ${({ theme }) => theme.radii.small};

    &.ant-picker-status-error {
      border: 1px solid #ff4d4f;
    }

    .ant-picker-input > input {
      font-size: ${({ theme }) => theme.fontSizes.default};
    }
  }
`;

export const StyledTextArea = styled(TextArea)`
  &.ant-input {
    border-radius: ${({ theme }) => theme.radii.small};
    padding: 8px 16px;
    font-size: ${({ theme }) => theme.fontSizes.default};
  }
`;

export const StyledFormItem = styled(Form.Item)`
  .ant-typography {
    font-size: ${({ theme }) => theme.fontSizes.small};
    font-weight: ${({ theme }) => theme.fontWeights.regular};
  }

  .ant-typography:after {
    content: '*';
    margin-left: 10px;
    color: ${({ theme }) => theme.colors.c3};
  }
`;
