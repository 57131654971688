import { createGlobalStyle } from 'styled-components';

import 'shared/fonts/fonts.css';
import 'antd/dist/antd.min.css';

const styled = { createGlobalStyle };

export const AppGlobalStyles = styled.createGlobalStyle`
  body {
    font-family: 'Optima-Cyr-Regular', sans-serif;
  }

  body,
  html,
  #root {
    height: 100%;
  }

  :root {
    --msg-input__padding: 0;
  }

  a {
    color: ${({ theme }) => theme.colors.c1};

    :visited,
    :hover {
      color: ${({ theme }) => theme.colors.c1};
    }
  }

  .ant-input:focus {
    border-color: ${({ theme }) => theme.colors.c1};
    box-shadow: 0 0 0 2px rgb(81 161 98 / 20%);
  }

  .ant-input:hover {
    border-color: ${({ theme }) => theme.colors.c2};
  }

  .ant-btn-primary,
  .ant-btn-primary:focus {
    border: ${({ theme }) => theme.colors.palette.black};
    background-color: ${({ theme }) => theme.colors.c1};
  }

  .ant-btn-link,
  .ant-btn-link:hover {
    color: inherit;
  }

  .ant-btn-primary:hover {
    color: ${({ theme }) => theme.colors.palette.white};
    border-color: ${({ theme }) => theme.colors.c2};
    background: ${({ theme }) => theme.colors.c2};
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: ${({ theme }) => theme.colors.palette.whiteSmoke};
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }

  .ant-menu-item:hover {
    color: inherit;
  }

  .ant-segmented-item,
  .segmented-item-selected,
  .ant-segmented,
  .ant-segmented-item-selected,
  .ant-segmented-thumb {
    border-radius: 16px;
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: ${({ theme }) => theme.colors.c1};
  }

  .ant-input:hover {
    border-color: ${({ theme }) => theme.colors.c1};
  }

  .ant-pagination-item-active {
    border: none;
  }

  .ant-pagination-item a {
    color: ${({ theme }) => theme.colors.mainText};
  }

  .ant-pagination-item-active a {
    color: ${({ theme }) => theme.colors.c1};
  }

  .ant-popover-inner {
    border-radius: 16px;
  }

  .ant-btn-primary[disabled],
  .ant-btn-primary[disabled]:active,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary[disabled]:hover {
    color: ${({ theme }) => theme.colors.palette.white};
    border-color: ${({ theme }) => theme.colors.palette.dustblu};
    background: ${({ theme }) => theme.colors.palette.dustblu};
    text-shadow: none;
    box-shadow: none;
  }

  .ant-empty-description {
    color: ${({ theme }) => theme.colors.palette.deadPixel};
  }

  .ant-input {
    border-color: transparent;
    box-shadow: none;
  }

  .ant-input[disabled] {
    border-color: transparent;
  }

  .ant-input-affix-wrapper {
    border-color: transparent;
    box-shadow: none;
  }

  .ant-input-affix-wrapper-focused {
    border-color: ${({ theme }) => theme.colors.palette.columnOfOakGreen};
    box-shadow: none;
  }

  .ant-input:focus {
    border-color: ${({ theme }) => theme.colors.palette.columnOfOakGreen};
    box-shadow: none;
  }

  .slot-popover .ant-popover-inner-content {
    width: 460px;
    padding: 30px;
  }

  .custom-time-picker-popup {
    width: 112px !important;
    min-width: auto !important;

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: ${({ theme }) => theme.colors.palette.operaGlass};

      span {
        font-weight: ${({ theme }) => theme.fontWeights.regular};
      }
    }
  }

  .schedule-event-details-popover .ant-popover-inner-content {
    padding: 16px;
    width: 350px;
  }

  .ant-popover {
    z-index: 100;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.colors.palette.columnOfOakGreen};
  }
`;
