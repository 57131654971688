import { type FC } from 'react';

import { useParams } from 'react-router-dom';

import { useGetPatientQuery } from 'entity/doctor/api';
import { BackButtonSubheader } from 'feature/navigation/ui/back-button-subheader';
import { PatientPageContainer } from 'pages/doctor/patient/ui/styles';
import { PatientAnalysisResults } from 'widgets/doctor-analysis-results';
import { PatientDocuments } from 'widgets/doctor-documents';
import { PatientMedicalRecords } from 'widgets/doctor-medical-records';
import { PatientInfo } from 'widgets/doctor-patient-info';
import { DoctorPatientProtocolsWidget } from 'widgets/doctor-patient-protocols-widget';
import { PatientVisitHistory } from 'widgets/doctor-visit-history';

export const PatientPage: FC = () => {
  document.title = 'Пациенты';

  const { patientId } = useParams();
  const { data: currentPatient } = useGetPatientQuery(+patientId!);

  if (!currentPatient) return null;

  return (
    <PatientPageContainer>
      <BackButtonSubheader />
      <PatientInfo {...{ currentPatient }} />
      <PatientAnalysisResults patientId={patientId} />
      <DoctorPatientProtocolsWidget patientId={patientId} />
      <PatientMedicalRecords />
      <PatientVisitHistory />
      <PatientDocuments patientId={currentPatient.internalPatientId} />
    </PatientPageContainer>
  );
};
