import type { InternalDoctorOnlineAppointmentProtocol } from 'entity/protocol/types/internal-doctor-online-appointment-protocol';

export const initialDefaultProtocolValues: InternalDoctorOnlineAppointmentProtocol = {
  protocolId: null,
  doctorName: null,
  specialistName: null,
  patientName: null,
  startDateTimeIso: undefined,
  dateAndTime: undefined,
  patientBirthDate: undefined,
  complaints: '',
  anamnesis: '',
  conclusion: '',
  recommendations: '',
  referral: '',
  services: '',
  onlineVisitAppointmentId: null,
};
