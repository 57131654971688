import { type DoctorNotificationResponseDto, NotificationCase } from 'shared/api/rtk-api';
import { formatDateTime } from 'shared/lib/format';
import { routePatches } from 'shared/routes';

const getOnlineAppointmentNotificationLink = (notification: DoctorNotificationResponseDto) => {
  const { eventType, internalOnlineAppointmentId } = notification;
  if (!internalOnlineAppointmentId) return null;

  switch (eventType) {
    case NotificationCase.OnlineAppointmentAcceptedByAdminToDoctor:
    case NotificationCase.OnlineAppointmentPaidByPatientToDoctor:
    case NotificationCase.OnlineAppointmentStarsInHourToDoctor:
    case NotificationCase.OnlineAppointmentStartsRightNowToDoctor:
      return routePatches.DoctorScheduleOnlineAppointment.path(internalOnlineAppointmentId);
    default:
      return null;
  }
};

const getLink = (notification: DoctorNotificationResponseDto) => {
  const { homeVisitId, internalOnlineAppointmentId } = notification;
  if (homeVisitId) return routePatches.DoctorScheduleHomeVisit.path(homeVisitId);

  if (internalOnlineAppointmentId) return getOnlineAppointmentNotificationLink(notification);

  return null;
};

export const mapExternalDoctorNotificationToInternalDoctorNotification = (
  notification: DoctorNotificationResponseDto,
) => {
  return {
    id: notification.id,
    isViewed: notification.isViewed,
    message: notification.message,
    date: formatDateTime(notification.dateISO),
    link: getLink(notification),
  };
};
