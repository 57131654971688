import { Button, Space, Spin, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { MoreOutlined } from '@ant-design/icons';
import { IAdminDoctorInterface } from 'entity/admin/api/types/doctors';
import { CreateAccountModal } from 'pages/admin/doctors/ui/add-account-modal';
import { DeactivateAccountButton } from 'pages/admin/doctors/ui/deactivate-account-button';
import { AdminDoctorAvatar, AdminDoctorsTableHeaderText, AvatarLoader } from 'pages/admin/doctors/ui/styles';
import { AppDropDown } from 'shared/ui/dropdown2';

import { EditAccountButton } from '../edit-account-button';

export const AdminDoctorTableData: ColumnsType<IAdminDoctorInterface> = [
  {
    title: <AdminDoctorsTableHeaderText>ФИО</AdminDoctorsTableHeaderText>,
    dataIndex: 'name',
    key: 'name',
    width: '35%',
    render: (_, row) => (
      <>
        <AdminDoctorAvatar
          width={32}
          height={32}
          src={row.image}
          placeholder={<Spin size="large" style={{ background: '#FFF' }} indicator={<AvatarLoader />} />}
          fallback="https://samara.medguard.ru/local/templates/medguard/images/default.png"
        />

        <Typography.Text style={{ marginLeft: 10 }}>{row.name}</Typography.Text>
      </>
    ),
  },
  {
    title: <AdminDoctorsTableHeaderText>Email</AdminDoctorsTableHeaderText>,
    key: 'email',
    dataIndex: 'account',
    width: '35%',
    render: (_, { account }) => (account ? account.email : ''),
  },
  {
    title: <AdminDoctorsTableHeaderText>Личный кабинет</AdminDoctorsTableHeaderText>,
    width: 180,
    dataIndex: 'account',
    key: 'account',
    render: (_, { account, id }) =>
      account ? <Typography.Text disabled>Создан</Typography.Text> : <CreateAccountModal doctorId={id} />,
  },
  {
    key: 'action',
    width: 80,
    align: 'center',
    render: (_, record) => (
      <Space size="middle">
        <AppDropDown
          items={[
            <EditAccountButton disabled={!record.account} label="Редактировать" doctorId={record.id} />,
            <DeactivateAccountButton disabled={!record.account} doctorId={record.id} />,
          ]}
        >
          <Button type="text" icon={<MoreOutlined />} />
        </AppDropDown>
      </Space>
    ),
  },
];
