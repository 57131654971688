import { type FC, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { HealthAssistanceDetailsLayout } from 'entity/health-assistance/ui/health-assistance-details-layout';
import { useOnlineAppointmentDetailsQuery } from 'entity/online-appointment/hooks/use-online-appointment-details-query';
import { InternalOnlineAppointmentStatusEnum } from 'entity/online-appointment/types/internal-online-appointment-status-enum';
import { AdminReturnToOnlineAppointmentRejectedByClientListButton } from 'feature/admin-return-to-online-appointment-rejected-by-patient-list/ui/admin-return-to-online-appointment-rejected-by-patient-list-button';
import { OnlineAppointmentRejectedByPatientApproveByAdminButton } from 'feature/online-appointment-rejected-by-patient-approve-by-admin/ui/online-appointment-rejected-by-patient-approve-by-admin-button';
import { OnlineAppointmentRejectedByPatientToTakeOnByAdminButton } from 'feature/online-appointment-rejected-by-patient-to-take-on-by-admin/ui/online-appointment-rejected-by-patient-to-take-on-by-admin-button';
import { routePatches } from 'shared/routes';
import { errorNotification } from 'shared/utils/notifications';

import { AdminOnlineAppointmentRejectedByPatientDetailsForm } from './admin-online-appointment-rejected-by-patient-details-form';

const AVAILABLE_APPOINTMENT_STATUSES = [
  InternalOnlineAppointmentStatusEnum.PendingCancellation,
  InternalOnlineAppointmentStatusEnum.ProcessingByAnotherAdmin,
  InternalOnlineAppointmentStatusEnum.ProcessingByCurrentAdmin,
];

export const AdminOnlineAppointmentRejectedByPatientDetailsPage: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { onlineAppointment, isLoading, isError } = useOnlineAppointmentDetailsQuery(id);

  const isWrongAppointmentStatus =
    onlineAppointment && !AVAILABLE_APPOINTMENT_STATUSES.includes(onlineAppointment.status);

  useEffect(() => {
    if (isError || isWrongAppointmentStatus) {
      errorNotification('Ошибка при загрузке заявки!');
      navigate(routePatches.AdminRejectedByPatientOnlineAppointmentList.path());
    }
  }, [onlineAppointment, isError, navigate, isWrongAppointmentStatus]);

  if (isLoading) return null;

  if (!onlineAppointment) return <div>not found</div>;

  const { id: onlineAppointmentId, canBeCanceled, canBeProcessed } = onlineAppointment;

  return (
    <HealthAssistanceDetailsLayout
      ReturnButton={<AdminReturnToOnlineAppointmentRejectedByClientListButton />}
      ActionButtons={[
        canBeProcessed && (
          <OnlineAppointmentRejectedByPatientToTakeOnByAdminButton onlineAppointmentId={onlineAppointmentId} key={1} />
        ),
        canBeCanceled && (
          <OnlineAppointmentRejectedByPatientApproveByAdminButton onlineAppointmentId={onlineAppointmentId} key={2} />
        ),
      ]}
      LeftSide={<AdminOnlineAppointmentRejectedByPatientDetailsForm onlineAppointment={onlineAppointment} />}
    />
  );
};
