import { Typography } from 'antd';
import styled from 'styled-components';

const { Text } = Typography;

export const FileName = styled(Text)`
  font-weight: ${({ theme }) => theme.fontWeights.regular};
`;

export const IconWrapper = styled.div`
  cursor: pointer;

  svg {
    height: 20px;
    width: 20px;
    color: ${({ theme }) => theme.colors.palette.columnOfOakGreen};
  }
`;
