import { useDocumentControllerDoctorGetPatientProtocolsQuery } from 'shared/api/rtk-api';

import { externalDoctorUserProtocolToInternalDoctorUserProtocolMapper } from '../mappers/external-doctor-user-protocol-to-internal-doctor-user-protocol';

export const useDoctorUserProtocolListQuery = (id?: string) => {
  if (!id) throw new Error('userId is not defined');

  const userId = Number(id);

  const { data, ...others } = useDocumentControllerDoctorGetPatientProtocolsQuery({ userId });

  const userProtocols = (data?.data ?? []).map(externalDoctorUserProtocolToInternalDoctorUserProtocolMapper);

  return {
    userProtocols,
    total: data?.total ?? 0,
    ...others,
  };
};
