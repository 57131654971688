import { Typography } from 'antd';
import styled from 'styled-components';

import { LinkButton } from 'shared/ui/link-button';

export const DisabledText = styled(Typography.Text)`
  color: rgba(0, 0, 0, 0.25);
`;

export const StyledLinkButton = styled(LinkButton)`
  && {
    color: rgba(0, 0, 0, 0.85);
  }
`;
