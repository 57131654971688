import { useMemo } from 'react';

import { useOnlineVisitAppointmentControllerDoctorGetAppointmentInfoForCalendarQuery } from 'shared/api/rtk-api';

import { externalDoctorOnlineAppointmentDetailsToInternalDoctorOnlineAppointmentDetailsMapper } from '../mappers/external-doctor-online-appointment-details-to-internal-doctor-online-appointment-details-mapper';

export const useDoctorOnlineAppointmentDetailsQuery = (appointmentId?: string | number) => {
  const id = Number(appointmentId);
  if (!id) throw new Error('id is not defined');

  const { data, ...others } = useOnlineVisitAppointmentControllerDoctorGetAppointmentInfoForCalendarQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  const onlineAppointment = useMemo(() => {
    return data
      ? externalDoctorOnlineAppointmentDetailsToInternalDoctorOnlineAppointmentDetailsMapper(data)
      : undefined;
  }, [data]);

  return {
    onlineAppointment,
    ...others,
  };
};
