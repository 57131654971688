import { IFiles, IPatientInfo, IPatientList } from 'entity/doctor/api/types';
import { api } from 'shared/api';
import { DocumentResponse } from 'shared/api/rtk-api';

const doctorApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPastPatients: build.query<IPatientList, { patientName?: string; limit: number; offset?: number }>({
      query: ({ patientName, limit, offset }) => ({
        url: 'patient/doctor-patients',
        method: 'get',
        params: { patientName, limit, offset },
        enabled: !!patientName,
      }),
    }),

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getPatient: build.query<IPatientInfo, number>({
      query: (patientId) => ({
        url: `patient/doctor-patient/${patientId}`,
        method: 'get',
      }),
    }),

    getPatientAnalysisResult: build.query({
      query: (userId: number, limit?: number, offset?: number) => ({
        url: 'doctor-panel/analysis-result',
        method: 'get',
        params: {
          userId,
          limit,
          offset,
        },
        enabled: !!userId && !!limit && !!offset,
      }),
    }),

    getPatientChatFiles: build.query<IFiles, { userId: number; ids: number[]; limit?: number; offset?: number }>({
      query: ({ userId, ids }) => ({
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        url: `doctor-panel/uploaded-patient-files?userId=${userId}&ids=${ids}`,
        method: 'get',
        enabled: !!userId && ids?.length,
      }),
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    doDownloadPatientFile: build.query<any, { userId: number; id: number }>({
      query: ({ userId, id }) => ({
        url: 'doctor-panel/download-patient-file',
        method: 'get',
        params: {
          userId,
          id,
        },
        enabled: !!userId && !!id,
      }),
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    uploadDocumentToPatient: build.mutation<void, any>({
      query: (body) => {
        return {
          url: 'doctor-panel/upload',
          method: 'post',
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          body,
        };
      },
    }),
    uploadConversationDocumentToPatient: build.mutation<DocumentResponse, FormData>({
      query: (body) => {
        return {
          url: 'doctor-panel/upload-conversation-document',
          method: 'post',
          body,
        };
      },
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetPastPatientsQuery,
  useGetPatientQuery,
  useGetPatientAnalysisResultQuery,
  useGetPatientChatFilesQuery,
  useDoDownloadPatientFileQuery,
  useUploadDocumentToPatientMutation,
  useUploadConversationDocumentToPatientMutation,
} = doctorApi;
