import styled from 'styled-components';

export const ScheduleSlotPopoverContentContainer = styled.div`
  a,
  a:hover,
  a:visited {
    color: ${({ theme }) => theme.colors.c1};
  }
`;

export const CloseIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;

  svg path {
    stroke: ${({ theme }) => theme.colors.palette.deadPixel};
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 15px;
`;
