import { useEffect, useState } from 'react';

import moment from 'moment';

import { addMinutesToDate, getDurationBetweenDates } from 'shared/lib/format';

type Props = { startISODate?: string | null };

export const useCalculateOnlineAppointmentTimePassed = ({ startISODate }: Props) => {
  const [timeLeft, setTimeLeft] = useState<string | null>(null);

  useEffect(() => {
    if (!startISODate) return;

    const endTime = addMinutesToDate(startISODate, 30);

    const timerInterval = setInterval(() => {
      const currentTime = moment();
      const duration = getDurationBetweenDates(endTime, currentTime);

      if (duration.asMilliseconds() <= 0) {
        clearInterval(timerInterval);
        setTimeLeft('00:00');
      } else {
        const minutes = String(duration.minutes()).padStart(2, '0');
        const seconds = String(duration.seconds()).padStart(2, '0');
        setTimeLeft(`${minutes}:${seconds}`);
      }
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [startISODate]);

  return { timeLeft };
};
