import { type FC } from 'react';

import { Navigate, useParams } from 'react-router-dom';

import { useConnectToDailyMeeting } from 'entity/daily/hooks/use-connect-to-daily-meeting';
import { useDoctorConnectToOnlineAppointmentQuery } from 'entity/online-appointment/hooks/use-doctor-connect-to-online-appointment-query';
import { routePatches } from 'shared/routes';
import { DoctorOnlineAppointmentDashboardWidget } from 'widgets/doctor-online-appointment-dashboard-widget/ui';
import { DoctorOnlineAppointmentHeaderWidget } from 'widgets/doctor-online-appoitment-header-widget/ui';

import { DoctorOnlineAppointmentPageLayout, MainContentWrapper } from './styles';
import { OnlineAppointmentMainScreen } from './ui/main-screen';

export const DoctorOnlineAppointmentPage: FC = () => {
  document.title = 'Онлайн-прием';

  const { appointmentId } = useParams();

  const { dailyChannel, doctorName, isError } = useDoctorConnectToOnlineAppointmentQuery(appointmentId);

  useConnectToDailyMeeting({ dailyChannel, doctorName });

  if (isError) return <Navigate to={routePatches.DoctorSchedule.path()} replace />;

  return (
    <DoctorOnlineAppointmentPageLayout>
      <MainContentWrapper>
        <DoctorOnlineAppointmentHeaderWidget />
        <OnlineAppointmentMainScreen />
      </MainContentWrapper>

      <DoctorOnlineAppointmentDashboardWidget />
    </DoctorOnlineAppointmentPageLayout>
  );
};
