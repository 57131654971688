import { useEffect, useState } from 'react';

import { useGetPastPatientsQuery } from 'entity/doctor/api';
import type { IPatientInfo } from 'entity/doctor/api/types';

interface UsePatientsQuery {
  search?: string;
  page: number;
  pageSize: number;
}

export const usePatientListQuery = ({ search, page, pageSize }: UsePatientsQuery) => {
  const { data: patients, isLoading } = useGetPastPatientsQuery({
    patientName: search,
    limit: pageSize,
    offset: (page - 1) * pageSize || undefined,
  });
  const [patientList, setPatientList] = useState<IPatientInfo[]>([]);

  useEffect(() => {
    if (patients) {
      setPatientList(patients.data);
    }
  }, [patients]);

  return {
    patientList,
    isLoading,
    total: patients?.total ?? 0,
  };
};
