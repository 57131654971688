import { type FC } from 'react';

import { useParams } from 'react-router-dom';

import { useDoctorConnectToOnlineAppointmentQuery } from 'entity/online-appointment/hooks/use-doctor-connect-to-online-appointment-query';
import { CameraIcon } from 'shared/ui/icons';

import { useCalculateOnlineAppointmentTimePassed } from '../hooks/use-calculate-online-appointment-time-passed';
import { DurationBadge, PageTitle, PageTitleWrapper } from './styles';

export const DoctorOnlineAppointmentHeaderWidget: FC = () => {
  const { appointmentId } = useParams();
  const { appointmentIsoStartTime } = useDoctorConnectToOnlineAppointmentQuery(appointmentId);

  const { timeLeft } = useCalculateOnlineAppointmentTimePassed({
    startISODate: appointmentIsoStartTime,
  });

  return (
    <PageTitleWrapper direction="horizontal" size={20}>
      <CameraIcon />

      <PageTitle>Онлайн-прием</PageTitle>

      {timeLeft && (
        <DurationBadge>
          <span>{timeLeft}</span>
        </DurationBadge>
      )}
    </PageTitleWrapper>
  );
};
