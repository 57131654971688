import { type FC } from 'react';

import { useNavigate } from 'react-router-dom';

import { routePatches } from 'shared/routes';

import { DetailsButton } from './styles';

type Props = {
  onlineAppointmentId: number;
};

export const ScheduleDoctorGoToOnlineAppointmentDetailsButton: FC<Props> = ({ onlineAppointmentId }) => {
  const navigate = useNavigate();

  const handleClickButton = () => navigate(routePatches.DoctorScheduleOnlineAppointment.path(onlineAppointmentId));

  return <DetailsButton onClick={handleClickButton}>Подробнее</DetailsButton>;
};
