import styled from 'styled-components';

export const DocumentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 16px;
  border-radius: ${({ theme }) => theme.radii.small};
  background: ${({ theme }) => theme.colors.mainBackground};
`;

export const AddProtocolButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  span {
    color: ${({ theme }) => theme.colors.c1};
  }
`;

export const FileName = styled.p`
  margin-bottom: 0;
  color: ${({ theme }) => theme.colors.palette.columnOfOakGreen};
  cursor: pointer;
`;

export const UploadIconWrapper = styled.div`
  cursor: pointer;
`;
