import { type FC } from 'react';

import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import type { InternalDoctorOnlineAppointmentProtocol } from 'entity/protocol/types/internal-doctor-online-appointment-protocol';
import RegularOptima from 'shared/fonts/optima-cyr/optima-cyr.ttf';
import BoldOptima from 'shared/fonts/optima-cyr-bold/optima-cyr-bold.ttf';
import { formatDateDMY } from 'shared/lib/format';

const styles = StyleSheet.create({
  page: { fontFamily: 'Optima-Cyr-Regular', fontSize: 10, padding: 40 },
  image: { width: 100 },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: 15,
    marginBottom: 15,
  },
  header: {
    display: 'flex',
    marginBottom: 15,
  },
  colTitle: { fontSize: 12, fontWeight: 'bold' },
  pageTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginTop: 30,
    marginBottom: 10,
  },
  col: { width: 150 },
  col2: { width: 450 },
  col3: { width: 200 },
});

Font.register({
  family: 'Optima-Cyr-Regular',
  fonts: [
    {
      src: RegularOptima,
    },
    {
      src: BoldOptima,
      fontWeight: 'bold',
    },
  ],
});

type Props = {
  values: InternalDoctorOnlineAppointmentProtocol;
};

export const ProtocolPDFTemplate: FC<Props> = ({ values }) => (
  <Document>
    <Page style={styles.page}>
      <View style={styles.header}>
        <Image style={styles.image} src="/images/logo.png" />
        <Text style={styles.pageTitle}>Протокол онлайн приема</Text>
      </View>

      <View style={styles.row}>
        <View style={styles.col}>
          <Text style={styles.colTitle}>Врач</Text>
          <Text style={{ fontWeight: 300 }}>{values.doctorName}</Text>
        </View>

        <View style={styles.col}>
          <Text style={styles.colTitle}>Пациент</Text>
          <Text>{values.patientName}</Text>
        </View>

        <View style={styles.col}>
          <Text style={styles.colTitle}>Узкий специалист</Text>
          <Text>{values.specialistName}</Text>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.col}>
          <Text style={styles.colTitle}>Дата и время</Text>
          <Text>{values.dateAndTime}</Text>
        </View>

        <View style={styles.col}>
          <Text style={styles.colTitle}>Дата рождения пациента</Text>
          <Text>{values.patientBirthDate && formatDateDMY(values.patientBirthDate.toISOString())}</Text>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.col2}>
          <Text style={styles.colTitle}>Жалобы</Text>
          <Text>{values.complaints}</Text>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.col2}>
          <Text style={styles.colTitle}>Анамнез</Text>
          <Text>{values.anamnesis}</Text>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.col3}>
          <Text style={styles.colTitle}>Заключение онлайн приема</Text>
          <Text>{values.conclusion}</Text>
        </View>
        <View style={styles.col3}>
          <Text style={styles.colTitle}>Рекомендации</Text>
          <Text>{values.recommendations}</Text>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.col3}>
          <Text style={styles.colTitle}>Направление</Text>
          <Text>{values.referral}</Text>
        </View>

        <View style={styles.col3}>
          <Text style={styles.colTitle}>Услуги</Text>
          <Text>{values.services}</Text>
        </View>
      </View>
    </Page>
  </Document>
);
