import { useCallback } from 'react';

import { useDoctorUpdateOnlineVisitProtocolMutation } from 'shared/api/enchance-endpoints';
import { errorNotification, successNotification } from 'shared/utils/notifications';

interface MutationHookOptions {
  protocolId: string;
  protocolData: FormData;
  onSuccess?: () => void;
  onError?: () => void;
}

export const useDoctorUpdateOnlineAppointmentProtocolMutation = () => {
  const [mutation] = useDoctorUpdateOnlineVisitProtocolMutation();
  const action = useCallback(
    ({ protocolId, protocolData, onSuccess, onError }: MutationHookOptions) => {
      mutation({ protocolData, protocolId })
        .unwrap()
        .then(() => {
          successNotification('Протокол был успешно изменен');
          onSuccess?.();
        })
        .catch(() => {
          errorNotification('Ошибка при изменении протокола');
          onError?.();
        });
    },
    [mutation],
  );
  return [action];
};
