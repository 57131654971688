import {
  type DocumentResponseDto,
  type OnlineVisitDoctorAppointmentDetailsForCalendarResponseDto,
} from 'shared/api/rtk-api';
import { formatDate, formatDateDMAndCallTime, getTimeFromDate } from 'shared/lib/format';

import type {
  InternalAppointmentDocument,
  InternalDoctorOnlineAppointmentDetails,
} from '../types/internal-doctor-online-appointment-details';

const externalOnlineAppointmentDoctorDocumentToInternalOnlineAppointmentDoctorDocumentMapper = (
  document: DocumentResponseDto,
): InternalAppointmentDocument => {
  return {
    id: document.id,
    fileName: document.fileName,
    isOwner: document.isOwner,
    protocolId: document.internalProtocolId,
  };
};

export function externalDoctorOnlineAppointmentDetailsToInternalDoctorOnlineAppointmentDetailsMapper(
  onlineAppointment: OnlineVisitDoctorAppointmentDetailsForCalendarResponseDto,
): InternalDoctorOnlineAppointmentDetails {
  const appointmentTime = `${getTimeFromDate(onlineAppointment.startDateTimeIso)} - ${getTimeFromDate(onlineAppointment.endDateTimeIso)}`;

  return {
    id: onlineAppointment.internalOnlineVisitAppointmentId,
    start: onlineAppointment.startDateTimeIso,
    patientFullName: onlineAppointment.patientFullName,
    patientId: onlineAppointment.internalPatientId,
    doctorFullName: onlineAppointment.doctorFullName,
    specialistFullName: onlineAppointment.specialistFullName,
    dateAndTime: formatDateDMAndCallTime(onlineAppointment.startDateTimeIso, appointmentTime),
    time: appointmentTime,
    date: formatDate(onlineAppointment.startDateTimeIso),
    canAddProtocol: onlineAppointment.canAddProtocol,
    canConnect: onlineAppointment.canConnect,
    documents: (onlineAppointment.documents ?? []).map(
      externalOnlineAppointmentDoctorDocumentToInternalOnlineAppointmentDoctorDocumentMapper,
    ),
  };
}
