export interface Route {
  name: string;
  path: (param?: string | number) => string;
}

export enum RoutesEnum {
  Auth = 'Auth',
  DoctorSchedule = 'DoctorSchedule',
  DoctorScheduleOnlineAppointment = 'DoctorScheduleOnlineAppointment',
  DoctorScheduleHomeVisit = 'DoctorScheduleHomeVisit',

  DoctorChats = 'DoctorChats',
  DoctorProtocol = 'DoctorProtocol',
  DoctorOnlineAppointment = 'DoctorOnlineAppointment',

  AdminChatSupportList = 'AdminChatSupportList',
  AdminHomeVisitList = 'AdminHomeVisitList',
  AdminOnlineAppointmentList = 'AdminOnlineAppointmentList',
  AdminOnlineAppointmentDetails = 'AdminOnlineAppointmentDetails',

  AdminChooseSpecialistAndTime = 'AdminChooseSpecialistAndTime',
  AdminHomeVisitDetails = 'AdminHomeVisitDetails',
  AdminChatSupportDetails = 'AdminChatSupportDetails',
  AdminHomeVisitEdit = 'AdminHomeVisitEdit',
  AdminChatSupportEdit = 'AdminChatSupportEdit',
  AdminRejectedByPatientHomeVisitList = 'AdminRejectedByPatientHomeVisitList',
  AdminRejectedByPatientHomeVisitDetails = 'AdminRejectedByPatientHomeVisitDetails',

  AdminPastOnlineAppointmentsList = 'AdminPastOnlineAppointmentsList',
  AdminPastOnlineAppointmentDetails = 'AdminPastOnlineAppointmentDetails',
  AdminPastHomeVisitsList = 'AdminPastHomeVisitsList',
  AdminPastHomeVisitDetails = 'AdminPastHomeVisitDetails',
  AdminRejectedByPatientOnlineAppointmentList = 'AdminRejectedByPatientOnlineAppointmentList',
  AdminRejectedByPatientOnlineAppointmentDetails = 'AdminRejectedByPatientOnlineAppointmentDetails',

  AdminDoctorsPage = 'AdminDoctorsPage',

  ManagerPushNotificationList = 'ManagerPushNotificationList',
  ManagerPushNotificationForm = 'ManagerPushNotificationForm',
}

export const routes: Record<RoutesEnum, string> = {
  [RoutesEnum.AdminChatSupportList]: '/admin/appointment/chat-support-list',
  [RoutesEnum.AdminHomeVisitList]: '/admin/appointment/home-visit-list',
  [RoutesEnum.AdminHomeVisitDetails]: '/admin/appointment/home-visit-details',
  [RoutesEnum.AdminChatSupportDetails]: '/admin/appointment/chat-support-details',
  [RoutesEnum.AdminHomeVisitEdit]: '/admin/appointment/home-visit-edit',
  [RoutesEnum.AdminChatSupportEdit]: '/admin/appointment/chat-support-edit',

  [RoutesEnum.AdminOnlineAppointmentList]: '/admin/appointment/online-appointment-list',
  [RoutesEnum.AdminOnlineAppointmentDetails]: '/admin/appointment/online-appointment-details',

  [RoutesEnum.AdminPastOnlineAppointmentsList]: '/admin/recordings/online-appointment-list',
  [RoutesEnum.AdminPastHomeVisitsList]: '/admin/recordings/home-visit-list',
  [RoutesEnum.AdminPastOnlineAppointmentDetails]: '/admin/recordings/online-appointment-details',
  [RoutesEnum.AdminPastHomeVisitDetails]: '/admin/recordings/home-visit-details',

  [RoutesEnum.AdminChooseSpecialistAndTime]: '/admin/appointment/choose-specialist-and-time',
  [RoutesEnum.AdminRejectedByPatientHomeVisitList]: '/admin/reject-applications/home-visit-list',
  [RoutesEnum.AdminRejectedByPatientHomeVisitDetails]: '/admin/reject-applications/home-visit-details',

  [RoutesEnum.AdminRejectedByPatientOnlineAppointmentList]: '/admin/reject-applications/online-appointment-list',
  [RoutesEnum.AdminRejectedByPatientOnlineAppointmentDetails]: '/admin/reject-applications/online-appointment-details',

  [RoutesEnum.DoctorSchedule]: '/schedule',

  [RoutesEnum.DoctorScheduleOnlineAppointment]: '/schedule/online-appointment-details',
  [RoutesEnum.DoctorScheduleHomeVisit]: '/schedule/home-visit-details',

  [RoutesEnum.DoctorChats]: '/chat',
  [RoutesEnum.DoctorOnlineAppointment]: '/online-appointment',
  [RoutesEnum.DoctorProtocol]: '/protocol',

  [RoutesEnum.Auth]: '/auth',

  [RoutesEnum.AdminDoctorsPage]: '/admin/doctors',

  [RoutesEnum.ManagerPushNotificationList]: '/manager/marketing/push-notification-list',
  [RoutesEnum.ManagerPushNotificationForm]: '/manager/marketing/push-notification-form',
};

export const routePatches: Record<RoutesEnum, Route> = {
  [RoutesEnum.AdminChatSupportList]: {
    name: 'Вызовы на дом',
    path: (param) => `${routes.AdminChatSupportList}${param !== undefined ? `/${param}` : ''}`,
  },
  [RoutesEnum.AdminHomeVisitList]: {
    name: 'Чат-сопровождение',
    path: (param) => `${routes.AdminHomeVisitList}${param !== undefined ? `/${param}` : ''}`,
  },
  [RoutesEnum.AdminHomeVisitDetails]: {
    name: 'Вызов на дом',
    path: (param) => `${routes.AdminHomeVisitDetails}${param !== undefined ? `/${param}` : ''}`,
  },
  [RoutesEnum.AdminChatSupportDetails]: {
    name: 'Чат-сопровождение',
    path: (param) => `${routes.AdminChatSupportDetails}${param !== undefined ? `/${param}` : ''}`,
  },
  [RoutesEnum.AdminHomeVisitEdit]: {
    name: 'Вызов на дом',
    path: (param) => `${routes.AdminHomeVisitEdit}${param !== undefined ? `/${param}` : ''}`,
  },
  [RoutesEnum.AdminChatSupportEdit]: {
    name: 'Чат-сопровождение',
    path: (param) => `${routes.AdminChatSupportEdit}${param !== undefined ? `/${param}` : ''}`,
  },

  [RoutesEnum.AdminOnlineAppointmentList]: {
    name: 'Онлайн-визит',
    path: (param) => `${routes.AdminOnlineAppointmentList}${param !== undefined ? `/${param}` : ''}`,
  },

  [RoutesEnum.AdminOnlineAppointmentDetails]: {
    name: 'Онлайн-визит',
    path: (param) => `${routes.AdminOnlineAppointmentDetails}${param !== undefined ? `/${param}` : ''}`,
  },

  [RoutesEnum.AdminPastOnlineAppointmentsList]: {
    name: 'Онлайн-приемы',
    path: (param) => `${routes.AdminPastOnlineAppointmentsList}${param !== undefined ? `/${param}` : ''}`,
  },
  [RoutesEnum.AdminPastHomeVisitsList]: {
    name: 'Вызовы на дом',
    path: (param) => `${routes.AdminPastHomeVisitsList}${param !== undefined ? `/${param}` : ''}`,
  },
  [RoutesEnum.AdminPastOnlineAppointmentDetails]: {
    name: 'Онлайн-прием',
    path: (param) => `${routes.AdminPastOnlineAppointmentDetails}${param !== undefined ? `/${param}` : ''}`,
  },
  [RoutesEnum.AdminPastHomeVisitDetails]: {
    name: 'Вызов на дом',
    path: (param) => `${routes.AdminPastHomeVisitDetails}${param !== undefined ? `/${param}` : ''}`,
  },

  [RoutesEnum.AdminChooseSpecialistAndTime]: {
    name: 'Выбор специалиста и времени',
    path: (param) => `${routes.AdminChooseSpecialistAndTime}${param !== undefined ? `/${param}` : ''}`,
  },
  [RoutesEnum.AdminRejectedByPatientHomeVisitList]: {
    name: 'Заявки на отмену приема',
    path: (param) => `${routes.AdminRejectedByPatientHomeVisitList}${param !== undefined ? `/${param}` : ''}`,
  },
  [RoutesEnum.AdminRejectedByPatientHomeVisitDetails]: {
    name: 'Заявки на отмену приема',
    path: (param) => `${routes.AdminRejectedByPatientHomeVisitDetails}${param !== undefined ? `/${param}` : ''}`,
  },

  [RoutesEnum.AdminRejectedByPatientOnlineAppointmentList]: {
    name: 'Заявки на отмену приема',
    path: (param) => `${routes.AdminRejectedByPatientOnlineAppointmentList}${param !== undefined ? `/${param}` : ''}`,
  },
  [RoutesEnum.AdminRejectedByPatientOnlineAppointmentDetails]: {
    name: 'Заявки на отмену приема',
    path: (param) =>
      `${routes.AdminRejectedByPatientOnlineAppointmentDetails}${param !== undefined ? `/${param}` : ''}`,
  },

  [RoutesEnum.DoctorSchedule]: {
    name: 'График',
    path: () => `${routes.DoctorSchedule}`,
  },
  [RoutesEnum.DoctorScheduleHomeVisit]: {
    name: 'Детали домашнего визита',
    path: (param) => `${routes.DoctorScheduleHomeVisit}${param !== undefined ? `/${param}` : ''}`,
  },
  [RoutesEnum.DoctorScheduleOnlineAppointment]: {
    name: 'Детали онлайн-приема',
    path: (param) => `${routes.DoctorScheduleOnlineAppointment}${param !== undefined ? `/${param}` : ''}`,
  },

  [RoutesEnum.DoctorChats]: {
    name: 'Чаты',
    path: (param) => `${routes.DoctorChats}${param !== undefined ? `/${param}` : ''}`,
  },

  [RoutesEnum.DoctorProtocol]: {
    name: 'Протокол',
    path: (param) => `${routes.DoctorProtocol}${param !== undefined ? `/${param}` : ''}`,
  },

  [RoutesEnum.DoctorOnlineAppointment]: {
    name: 'Онлайн-прием',
    path: (param) => `${routes.DoctorOnlineAppointment}${param !== undefined ? `/${param}` : ''}`,
  },

  [RoutesEnum.Auth]: {
    name: 'Вход',
    path: () => `${routes.Auth}`,
  },

  [RoutesEnum.AdminDoctorsPage]: {
    name: 'Доктор',
    path: (param) => `${routes.AdminDoctorsPage}${param !== undefined ? `/${param}` : ''}`,
  },

  [RoutesEnum.ManagerPushNotificationList]: {
    name: 'Пуш-уведомления',
    path: (param) => `${routes.ManagerPushNotificationList}${param !== undefined ? `/${param}` : ''}`,
  },
  [RoutesEnum.ManagerPushNotificationForm]: {
    name: 'Пуш-уведомлениe',
    path: (param) => `${routes.ManagerPushNotificationForm}${param !== undefined ? `/${param}` : ''}`,
  },
};
