import { useCallback } from 'react';

import { useProtocolsControllerDeleteProtocolMutation } from 'shared/api/rtk-api';
import { errorNotification, successNotification } from 'shared/utils/notifications';

interface MutationHookOptions {
  protocolId: number;
  onSuccess?: () => void;
  onError?: () => void;
}

export const useDoctorDeleteOnlineAppointmentProtocolMutation = () => {
  const [mutation] = useProtocolsControllerDeleteProtocolMutation();
  const action = useCallback(
    ({ protocolId, onSuccess, onError }: MutationHookOptions) => {
      mutation({ protocolId })
        .unwrap()
        .then(() => {
          successNotification('Протокол был успешно удален');
          onSuccess?.();
        })
        .catch(() => {
          errorNotification('Ошибка при удалении протокола');
          onError?.();
        });
    },
    [mutation],
  );
  return [action];
};
