import { useMemo } from 'react';

import { useOnlineVisitAppointmentControllerDoctorGetAppointmentInfoForProtocolQuery } from 'shared/api/rtk-api';

import { externalDoctorProtocolInitialInfoToInternalDoctorProtocolInitialInfoMapper } from '../mappers/external-doctor-protocol-initial-info-to-internal-doctor-protocol-initial-info';

export const useDoctorOnlineAppointmentProtocolInitialInfoQuery = (id?: string) => {
  const onlineAppointmentId = Number(id);

  const { data, ...others } =
    useOnlineVisitAppointmentControllerDoctorGetAppointmentInfoForProtocolQuery(onlineAppointmentId);

  const protocolInitialInfo = useMemo(() => {
    return data ? externalDoctorProtocolInitialInfoToInternalDoctorProtocolInitialInfoMapper(data) : undefined;
  }, [data]);

  return {
    protocolInitialInfo,
    ...others,
  };
};
