import { FC } from 'react';

import { DisabledText, StyledLinkButton } from './styles';

interface Props {
  disabled: boolean;
  doctorId: number;
  label: string;
}

export const EditAccountButton: FC<Props> = ({ disabled, doctorId, label }) => {
  return disabled ? (
    <DisabledText disabled onClick={(e) => e.stopPropagation()}>
      {label}
    </DisabledText>
  ) : (
    <StyledLinkButton to={`${doctorId}`}>{label}</StyledLinkButton>
  );
};
