import { useCallback } from 'react';

import { saveAs } from 'file-saver';

import { useDownloadPatientFilesMutation } from 'shared/api/enchance-endpoints';
import { errorNotification, successNotification } from 'shared/utils/notifications';

interface MutationHookOptions {
  fileId?: number;
  patientId?: number;
  fileName?: string;
  openNewTab?: boolean;
  onSuccess?: () => void;
  onError?: () => void;
}

export const useDoctorDownloadPatientFileMutation = () => {
  const [mutation] = useDownloadPatientFilesMutation();
  const action = useCallback(
    ({ fileId, patientId, fileName, openNewTab, onSuccess, onError }: MutationHookOptions) => {
      if (!fileId || !patientId || !fileName) {
        errorNotification('Невозможно скачать файл');
        onError?.();
        return;
      }
      mutation({ fileId, patientId })
        .unwrap()
        .then((payload) => {
          if (openNewTab) {
            const blob = new Blob([payload], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const newTab = window.open(url, '_blank');
            if (newTab) newTab.focus();
            return;
          }

          saveAs(payload, fileName);
          successNotification('Файл успешно скачан');
          onSuccess?.();
        })
        .catch(() => {
          errorNotification('Ошибка при скачивании файла');
          onError?.();
        });
    },
    [mutation],
  );
  return [action];
};
