import { useMemo } from 'react';

import { useDoctorNotificationControllerGetNotificationQuery } from 'shared/api/rtk-api';

import { mapExternalDoctorNotificationToInternalDoctorNotification } from '../mappers/external-doctor-notification-to-internal-doctor-notification-mapper';

export const useDoctorNotificationsQuery = () => {
  const { data, ...others } = useDoctorNotificationControllerGetNotificationQuery(undefined, {
    pollingInterval: 10000,
  });

  const notifications = useMemo(() => {
    return (data ?? []).map(mapExternalDoctorNotificationToInternalDoctorNotification);
  }, [data]);

  return {
    notifications,
    ...others,
  };
};
