import { useCallback } from 'react';

import { useOnlineVisitAppointmentControllerDoctorFinishAppointmentMutation } from 'shared/api/rtk-api';

interface MutationHookOptions {
  appointmentId?: string;
  onSuccess?: () => void;
  onError?: () => void;
}
export const useDoctorFinishOnlineAppointmentMutation = () => {
  const [mutation] = useOnlineVisitAppointmentControllerDoctorFinishAppointmentMutation();
  const action = useCallback(
    ({ appointmentId, onSuccess, onError }: MutationHookOptions) => {
      if (!appointmentId) {
        return;
      }
      const id = Number(appointmentId);

      mutation({ internalOnlineVisitAppointmentId: id })
        .unwrap()
        .then(() => {
          onSuccess?.();
        })
        .catch(() => {
          onError?.();
        });
    },
    [mutation],
  );
  return [action];
};
