import { FC, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { SearchOutlined } from '@ant-design/icons';
import {
  PatientsPageContainer,
  PatientsPageTitle,
  PatientsSearchInput,
  PatientsTableContainer,
} from 'pages/doctor/patient-list/ui/styles';
import { PatientsTableData } from 'pages/doctor/patient-list/ui/table-data';
import { AppTable } from 'shared/ui/table2';

import { usePatientListQuery } from '../hooks/use-patient-list-query';

const DEFAULT_PAGE_SIZE = 7;
const DEFAULT_PAGE = 1;

export const PatientsPage: FC = () => {
  document.title = 'Пациенты';
  const [search, setSearch] = useState<string>('');
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(DEFAULT_PAGE);
  const { patientList, isLoading, total } = usePatientListQuery({
    search,
    page,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  return (
    <PatientsPageContainer>
      <PatientsSearchInput
        prefix={<SearchOutlined style={{ color: '#200E32', marginRight: 10 }} />}
        size="large"
        allowClear
        placeholder="Введите ФИО"
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
          setPage(DEFAULT_PAGE);
        }}
      />
      <PatientsPageTitle>Пациенты</PatientsPageTitle>

      <PatientsTableContainer>
        <AppTable
          loading={isLoading}
          columns={PatientsTableData}
          dataSource={patientList}
          rowKey={(row) => row.internalPatientId}
          pagination={{
            pageSize: DEFAULT_PAGE_SIZE,
            position: ['bottomLeft'],
            current: page ?? DEFAULT_PAGE,
            total,
            onChange: (newPage) => {
              setPage(newPage);
            },
            showSizeChanger: false,
            size: 'small',
          }}
          rowClassName="patient-table-row"
          onRow={({ internalPatientId }) => ({
            onClick: () => {
              navigate(`/patients/${internalPatientId}`);
            },
          })}
        />
      </PatientsTableContainer>
    </PatientsPageContainer>
  );
};
