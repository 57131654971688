import { type FC } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { useDaily } from '@daily-co/daily-react';
import { useDoctorConnectToOnlineAppointmentQuery } from 'entity/online-appointment/hooks/use-doctor-connect-to-online-appointment-query';
import { useDoctorFinishOnlineAppointmentMutation } from 'entity/online-appointment/hooks/use-doctor-finish-online-appointment';
import { routePatches } from 'shared/routes';
import { OnlineAppointmentEndCallIcon } from 'shared/ui/icons';

import { EndCallActionButton } from './styles';

export const DoctorOnlineAppointmentLeaveButton: FC = () => {
  const navigate = useNavigate();
  const { appointmentId } = useParams();
  const callFrame = useDaily();

  const [finishAppointment] = useDoctorFinishOnlineAppointmentMutation();
  const { isMainDoctor } = useDoctorConnectToOnlineAppointmentQuery(appointmentId);

  const handleLeaveCall = () => {
    callFrame?.leave();
    if (isMainDoctor) {
      finishAppointment({ appointmentId });
    }
    navigate(routePatches.DoctorSchedule.path());
  };

  return (
    <EndCallActionButton onClick={handleLeaveCall}>
      <OnlineAppointmentEndCallIcon />
    </EndCallActionButton>
  );
};
