import { useCallback } from 'react';

import { useDoctorCreateOnlineVisitProtocolMutation } from 'shared/api/enchance-endpoints';
import { errorNotification, successNotification } from 'shared/utils/notifications';

interface MutationHookOptions {
  protocolData: FormData;
  onSuccess?: () => void;
  onError?: () => void;
}

export const useDoctorCreateOnlineAppointmentProtocolMutation = () => {
  const [mutation] = useDoctorCreateOnlineVisitProtocolMutation();
  const action = useCallback(
    ({ protocolData, onSuccess, onError }: MutationHookOptions) => {
      mutation(protocolData)
        .unwrap()
        .then(() => {
          successNotification('Протокол был успешно создан');
          onSuccess?.();
        })
        .catch(() => {
          errorNotification('Ошибка при создании протокола');
          onError?.();
        });
    },
    [mutation],
  );
  return [action];
};
