import { type FC, useState } from 'react';

import { ConfigProvider, Empty, List, Pagination } from 'antd';

import { DeleteFilled } from '@ant-design/icons';
import { useDoctorDownloadPatientFileMutation } from 'entity/files/files/use-doctor-download-patient-file-mutation';
import { useDoctorDeleteOnlineAppointmentProtocolMutation } from 'entity/protocol/hooks/use-doctor-delete-online-appointment-protocol';
import { useDoctorUserProtocolListQuery } from 'entity/protocol/hooks/use-doctor-user-protocol-list-query';
import { AppExpandPanel } from 'shared/ui/expand-panel';
import { DocumentDownloadIcon, DocumentIcon, EmptyIcon } from 'shared/ui/icons';

import { FileName, IconWrapper } from './styles';

const pageSize = 3;

type Props = {
  patientId?: string;
};

export const DoctorPatientProtocolsWidget: FC<Props> = ({ patientId }) => {
  const [offset, setOffset] = useState(0);

  const [downloadFile] = useDoctorDownloadPatientFileMutation();
  const [deleteProtocol] = useDoctorDeleteOnlineAppointmentProtocolMutation();
  const { userProtocols, total } = useDoctorUserProtocolListQuery(patientId);

  const currentData = userProtocols.slice(offset, offset + pageSize);

  const handleChangePage = (page: number) => setOffset(page * pageSize - pageSize);

  const handleDownloadProtocol = ({ fileId, fileName }: { fileId: number; fileName: string }) =>
    downloadFile({ fileId, patientId: Number(patientId), fileName });

  const handleDeleteProtocol = (protocolId: number) => deleteProtocol({ protocolId });

  return (
    <AppExpandPanel name="chats" title="Протоколы бесед">
      <ConfigProvider
        renderEmpty={() => (
          <Empty
            description="На текущее время протоколы бесед отсутствуют."
            image={<EmptyIcon height={90} width={90} />}
          />
        )}
      >
        <List
          itemLayout="horizontal"
          dataSource={currentData}
          footer={
            total > 3 && (
              <Pagination pageSize={pageSize} size="small" total={total} onChange={(page) => handleChangePage(page)} />
            )
          }
          renderItem={(item) => (
            <List.Item
              actions={[
                <IconWrapper
                  key={1}
                  onClick={() =>
                    handleDownloadProtocol({
                      fileId: item.id,
                      fileName: item.fileName,
                    })
                  }
                >
                  <DocumentDownloadIcon />
                </IconWrapper>,
                <IconWrapper key={2} onClick={() => handleDeleteProtocol(item.protocolId)}>
                  <DeleteFilled />
                </IconWrapper>,
              ]}
            >
              <List.Item.Meta avatar={<DocumentIcon />} title={<FileName>{item.fileName}</FileName>} />
            </List.Item>
          )}
        />
      </ConfigProvider>
    </AppExpandPanel>
  );
};
