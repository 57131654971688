import { type FC } from 'react';

import { Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import { LoadingOutlined } from '@ant-design/icons';
import { HealthAssistanceDetailsContainer } from 'entity/health-assistance/styles';
import { useDoctorOnlineAppointmentDetailsQuery } from 'entity/online-appointment/hooks/use-doctor-online-appointment-details';
import { SplitScreenContainer } from 'pages/doctor/home-visit-details/ui/yandex-map/styles';
import { routePatches } from 'shared/routes';
import { SplitScreen } from 'shared/ui/split-screen';

import { OnlineAppointmentDetailsForm } from './online-appointment-datails-form';

export const DoctorOnlineAppointmentDetailsPage: FC = () => {
  document.title = 'Детали онлайн-приема';

  const navigate = useNavigate();
  const { onlineAppointmentId } = useParams();

  const { onlineAppointment, isLoading, error } = useDoctorOnlineAppointmentDetailsQuery(onlineAppointmentId);

  if (!onlineAppointment) return null;

  if (isLoading) {
    return (
      <SplitScreenContainer>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      </SplitScreenContainer>
    );
  }

  if (error) navigate(routePatches.DoctorSchedule.path());

  return (
    <HealthAssistanceDetailsContainer>
      <SplitScreen Left={<OnlineAppointmentDetailsForm onlineAppointment={onlineAppointment} />} />
    </HealthAssistanceDetailsContainer>
  );
};
