import { type FC, useState } from 'react';

import { Col, Popover, Row, Typography } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker/generatePicker';
import moment, { Moment } from 'moment';

import { useScheduleAddSlotByDoctorMutation } from 'entity/schedule/hooks/use-schedule-add-slot-by-doctor-mutation';
import { getStartOfTheCurrentDate, getStartOfTheDate, setHoursAndMinutes } from 'shared/lib/format';
import { AddButton } from 'shared/ui/add-button';
import { CustomButton } from 'shared/ui/custom-button';
import { CloseIcon } from 'shared/ui/icons';

import { CloseIconWrapper, Label, StyledCustomTimePicker, StyledDatePicker, StyledPickersRow } from './styles';

const { Title } = Typography;

export const ScheduleCreateNewSlotByDoctorButton: FC = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [date, setDate] = useState<null | string>(null);
  const [time, setTime] = useState<null | string>(null);

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setDate(null);
    setTime(null);
  };

  const handleOpenModal = () => setIsModalVisible(true);

  const [addSlot] = useScheduleAddSlotByDoctorMutation();

  const onSubmit = () => {
    if (date && time) {
      const [hh, mm] = time.split('.');

      const startIsoDateTime = setHoursAndMinutes(date, +hh, +mm).toISOString();

      addSlot({ startIsoDateTime });
      handleCloseModal();
    }
  };

  const handleSelectTime = (value: unknown) => {
    setTime(typeof value !== 'string' ? null : value);
  };

  const disabledDate: RangePickerProps<Moment>['disabledDate'] = (current) => current < getStartOfTheCurrentDate();

  return (
    <Popover
      overlayClassName="slot-popover"
      showArrow={false}
      open={isModalVisible}
      placement="bottomRight"
      trigger="click"
      content={
        <>
          <Row justify="space-between">
            <Title level={4}>Онлайн-прием</Title>
            <CloseIconWrapper onClick={handleCloseModal}>
              <CloseIcon />
            </CloseIconWrapper>
          </Row>

          <StyledPickersRow justify="space-between">
            <Col>
              <Label>Дата приема</Label>
              <StyledDatePicker
                value={date ? moment(date) : null}
                onChange={(value) => (value ? setDate(getStartOfTheDate(value)) : setDate(null))}
                disabledDate={disabledDate}
              />
            </Col>

            <Col>
              <Label>Время приема</Label>
              <StyledCustomTimePicker
                allowClear
                placeholder="Выберите время"
                onSelect={handleSelectTime}
                onClear={() => setTime(null)}
                value={time ?? null}
              />
            </Col>
          </StyledPickersRow>

          <Row justify="end" gutter={10}>
            <Col>
              <CustomButton key={1} onClick={handleCloseModal} $buttonType="declineWhite">
                Отменить
              </CustomButton>
            </Col>

            <Col>
              <CustomButton disabled={!time || !date} key={2} onClick={onSubmit} $buttonType="submit">
                Добавить
              </CustomButton>
            </Col>
          </Row>
        </>
      }
    >
      <AddButton onClick={handleOpenModal}>Добавить слот</AddButton>
    </Popover>
  );
};
